/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Row, Col, CardBody, Card, Input, Label, Form, FormFeedback, Spinner, InputGroup, NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import classnames from "classnames"

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// actions
import {
  addNewAdUnit as onADDAdUnit,
  updateAdUnit as onUpdateAdUnit,
  readAdUnit as onReadAdUnit,
  addAdUnitSuccess,
  addAdUnitFail,
  updateAdUnitSuccess,
  updateAdUnitFail,
} from "store/Supply/AdUnit/actions";

import {
  getPartnersList as onGetPartners,
} from "store/PartnerManagement/actions";

import {
  getPublisher as onGetPublishers,
} from "store/Supply/Publisher/actions";

import {
  getAdUnitGroup as onGetAdUnitGroups,
} from "store/Supply/AdUnitGroup/actions";

import {
  getCommonSizes as onGetSizes,
} from "store/CommonEvents/actions";
import Toaster from "pages/Toaster/Toaster";

import {
  getNetworkSetting as onGetNetworkSetting,
} from 'store/Supply/NetworkSettings/actions'

import ToasterWarning from "pages/Toaster/ToasterWarning";

let DataFeedData = {
  deviceID: false,
  ipv4: false,
  ipv6: false
}
var buyerList = []

const AdUnitDetails = props => {
    return (<p>Ad Unit related Details to be developed</p>)
}

export default AdUnitDetails