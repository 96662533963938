/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from 'prop-types'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import {
    Col, Container, Row, Input, Label, Form, Button, Card, FormFeedback, Spinner,
    CardBody,
} from "reactstrap"

import Toaster from "pages/Toaster/Toaster";
import { MultiSelect } from "react-multi-select-component"
//redux
import { useSelector, useDispatch } from "react-redux";

import {
    getPartners as onGetPartners,
    getUsers as onGetUsers,

} from "store/PartnerManagement/actions";

import {
    getRoles as onGetRoles,
} from "store/RoleManagement/actions";

import usersIcon from "assets/images/bidsxchange/icons/users.png"
import partnersIcon from "assets/images/bidsxchange/icons/partners.png"
import rolesIcon from "assets/images/bidsxchange/icons/roles.png"
import systemIcon from "assets/images/bidsxchange/icons/system_wide.png"

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import axios from "axios";


const New_Publisher_Notification = props => {
    const dispatch = useDispatch();
    const [forType, setForType] = useState({
        users: false,
        partners: false,
        roles: false,
        system: false
    })
    const [usersData, setUsersData] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [partnerData, setPartnerData] = useState([])
    const [selectedPartner, setSelectedPartner] = useState([]);
    const [rolesData, setRolesData] = useState([])
    const [selectedRole, setSelectedRole] = useState([]);
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [submitted, setSubmitted] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [loader, setLoader] = useState(false);

    console.log(props)

    const [finalData, setFinalData] = useState({
        notificationName: '',
        forType: '',
        forValue: [],
        message: '',
        notificationInfo: ''
    })

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const handleCheckType = (e) => {
        console.log(e.target.value, e.target.checked)
        const { name, value } = e.target;
        setForType(forType => ({
            ...forType, users: false,
            partners: false,
            roles: false,
            system: false
        }));
        setForType(forType => ({ ...forType, [name]: e.target.checked }));
        setFinalData(finalData => ({ ...finalData, forType: name, forValue: [] }));
    }

    useEffect(() => {
        if (props.selected_Notification !== '') {
            setFinalData(props.selected_Notification)
            setIsEdit(true)
            setForType(forType => ({ ...forType, [props.selected_Notification.forType]: true }));
            if (props.selected_Notification.forType === 'partners') {
                setSelectedPartner(objectDataModal(props.selected_Notification.forValue))
            } else if (props.selected_Notification.forType === 'roles') {
                setSelectedRole(objectDataModal(props.selected_Notification.forValue))
            } else if (props.selected_Notification.forType === 'users') {
                setSelectedUsers(objectDataModal(props.selected_Notification.forValue))
            }
        }
    }, []);

    const { users } = useSelector(state => ({
        users: state.partners.users,
    }));

    useEffect(() => {
        if (users !== null) {
            dispatch(onGetUsers({ filter: '', role: '',userRole: userInfo.userRole }));
        }
    }, []);

    useEffect(() => {
        if (users && users.length > 0) {
            setUsersData(objectModal(users))
            console.log(users)
        }
    }, [users]);

    const objectModal = (response) => {
        let dataList = []
        response.map((item, index) => {
            let obj = {}
            obj.value = item.userEmail
            obj.label = item.userEmail
            dataList.push(obj)
        })
        return dataList
    }

    const { partners } = useSelector(state => ({
        partners: state.partners.partners,
    }));

    console.log(partners)

    useEffect(() => {
        if (partners !== null) {
            dispatch(onGetPartners({ filter: 'Active',userRole: userInfo.userRole }));
        }
    }, []);

    useEffect(() => {
        if (partners && partners.length > 0) {
            setPartnerData(objectPartnerModal(partners))
            console.log(partners)
        }
    }, [partners]);

    const objectPartnerModal = (response) => {
        let dataList = []
        response.map((item, index) => {
            let obj = {}
            obj.value = item.userEmail
            obj.label = item.userEmail
            dataList.push(obj)
        })
        return dataList
    }

    const { roles } = useSelector(state => ({
        roles: state.roles.roles,
    }));

    useEffect(() => {
        if (roles !== null) {
            dispatch(onGetRoles({ filter: '',userRole: userInfo.userRole }));
        }
    }, []);

    useEffect(() => {
        if (roles && roles.length > 0) {
            setRolesData(objectRoleModal(roles))
            console.log(roles)
        }
    }, [roles]);

    const objectRoleModal = (response) => {
        let dataList = []
        response.map((item, index) => {
            let obj = {}
            obj.value = item.roleName
            obj.label = item.roleName
            dataList.push(obj)
        })
        // dataList.push({value:'Root',label:'Root'})
        return dataList
    }

    const objectDataModal = (response) => {
        let dataList = []
        response.map((item, index) => {
            let obj = {}
            obj.value = item
            obj.label = item
            dataList.push(obj)
        })
        // dataList.push({value:'Root',label:'Root'})
        return dataList
    }

    const handleUsersSelect = users => {
        console.log(users)
        setSelectedUsers(users)
        let UsersList = []
        users.map(item => {
            UsersList.push(item.label)
        })
        setFinalData(finalData => ({ ...finalData, forValue: UsersList }));
    }

    const handlePartnersSelect = partners => {
        setSelectedPartner(partners)
        let PartnetrsList = []
        partners.map(item => {
            PartnetrsList.push(item.label)
        })
        setFinalData(finalData => ({ ...finalData, forValue: PartnetrsList }));
    }

    const handleRolesSelect = roles => {
        setSelectedRole(roles)
        let RolesList = []
        roles.map(item => {
            RolesList.push(item.label)
        })
        setFinalData(finalData => ({ ...finalData, forValue: RolesList }));
    }

    const notificationDataChange = (e) => {
        const { name, value } = e.target;
        setFinalData(finalData => ({ ...finalData, [name]: value }));
    }



    const radioButtons = (props) => {
        return <span className={props}></span>
    }

    const handleSubmit = (e) => {
        setLoader(true)
        e.preventDefault();
        setSubmitted(true);
        if (finalData.forType === '') {
            toastr.error('Please select group')
        } else {
            if (finalData.notificationName && finalData.message) {
                let url = userInfo.userRole === 'SupplyPartner' ? "/v2.0/Notification/create/SupplyPartner" : "/v2.0/Notification/create"
                let bodyNotification = {
                        userID: userInfo.userID,
                        userRole: userInfo.userRole,
                        companyID: userInfo.companyID,
                        name: userInfo.name,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner,
                        ...finalData
                }
                if (props.selected_Notification !== '') {
                    url = userInfo.userRole === 'SupplyPartner' ? "/v2.0/Notification/update/SupplyPartner" : "/v2.0/Notification/update"
                }
                console.log(bodyNotification)
                axios.post(url, bodyNotification,
                    {
                        headers: headers
                    })
                    .then((res) => {
                        setLoader(false)
                        console.log("result is Notification Action ==>", res)
                        if (res.data.statusCode === 200) {
                            if (props.selected_Notification !== '') {
                                props.closeCanvas('Update')
                            } else {
                                props.closeCanvas('Create')
                            }
                        }
                    })
                    .catch((err) => {
                        setLoader(false)
                        console.log("e", err)
                    });
            }
        }
    }

    return (
        <React.Fragment>
            {loader &&
                <Spinner className="ms-2 loader" color="primary" />
            }
            <Col md="12">
                <Form onSubmit={handleSubmit}>
                <div style={{ padding: '1% 3%', height: '79vh', overflow: 'auto' }}>
                    <div className="mb-3">
                        <div className="row">
                            <Col md={3}>
                                <label className="form-check-label  m-2 mt-1" htmlFor="system"> System-Wide  </label>
                                <div className="form-check mt-2 mb-2">
                                    <input
                                        className="form-check-input mt-3"
                                        type="radio"
                                        name="system"
                                        id="system"
                                        checked={forType.system}
                                        onChange={handleCheckType}
                                    />
                                    <label className="form-check-label  m-2 mt-1" htmlFor="system"><img src={systemIcon} alt="" height="40" className="auth-logo-dark" /> </label>
                                </div>
                            </Col>
                            <Col md={3}>
                                <label className="form-check-label  m-2 mt-1" htmlFor="users"> Users</label>
                                <div className="form-check mt-2 mb-2">
                                    <input
                                        className="form-check-input mt-3"
                                        type="radio"
                                        name="users"
                                        id="users"
                                        checked={forType.users}
                                        onChange={handleCheckType}
                                    />
                                    <label className="form-check-label  m-2 mt-1" htmlFor="users"><img src={usersIcon} alt="" height="40" className="auth-logo-dark" /> </label>
                                </div>
                            </Col>
                            <Col md={3}>
                                <label className="form-check-label  m-2 mt-1" htmlFor="partners"> Partners </label>
                                <div className="form-check mt-2 mb-2">
                                    <input
                                        className="form-check-input mt-3"
                                        type="radio"
                                        name="partners"
                                        id="partners"
                                        checked={forType.partners}
                                        onChange={handleCheckType}
                                    />
                                    <label className="form-check-label  m-2 mt-1" htmlFor="partners"><img src={partnersIcon} alt="" height="40" className="auth-logo-dark" /> </label>
                                </div>
                            </Col>
                            <Col md={3}>
                                <label className="form-check-label  m-2 mt-1" htmlFor="roles">Roles </label>
                                <div className="form-check mt-2 mb-2">
                                    <input
                                        className="form-check-input mt-3"
                                        type="radio"
                                        name="roles"
                                        id="roles"
                                        checked={forType.roles}
                                        onChange={handleCheckType}
                                    />
                                    <label className="form-check-label  m-2 mt-1" htmlFor="roles"><img src={rolesIcon} alt="" height="40" className="auth-logo-dark" /> </label>
                                </div>
                            </Col>

                        </div>
                    </div>
                    <Col sm="9">
                    {forType.system &&
                        <MultiSelect
                            options={rolesData}
                            value={selectedRole}
                            onChange={handleRolesSelect}
                            overrideStrings={{ "Select Role": "Choose roles..." }}
                            disabled
                        />
                    }
                    {forType.users &&
                        <MultiSelect
                            options={usersData}
                            value={selectedUsers}
                            onChange={handleUsersSelect}
                            overrideStrings={{ "Select User": "Choose users..." }}
                        />
                    }
                    {forType.partners &&
                        <MultiSelect
                            options={partnerData}
                            value={selectedPartner}
                            onChange={handlePartnersSelect}
                            overrideStrings={{ "Select Partner": "Choose partners..." }}
                        />
                    }
                    {forType.roles &&
                        <MultiSelect
                            options={rolesData}
                            value={selectedRole}
                            onChange={handleRolesSelect}
                            overrideStrings={{ "Select Role": "Choose roles..." }}
                        />
                    }
                        <div className="mb-3 mt-3">
                            <Label>Notification Band Type  <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                            <Input type="select" className="form-select" name="notificationInfo" value={finalData.notificationInfo} onChange={notificationDataChange}>
                                <option value='info' > Low Impact </option>
                                <option value='warning'>Medium Impact </option>
                                <option value='error'> High Impact  </option>
                            </Input>
                        </div>
                        <div className="mb-3">
                            <Label>Notification Title  <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                            <Input type="text" name='notificationName'
                                value={finalData.notificationName} onChange={notificationDataChange}
                                className={"form-control" + (submitted && !finalData.notificationName ? ' is-invalid' : '')}>
                            </Input>
                            {submitted && !finalData.notificationName ? (
                                <FormFeedback type="invalid">{'Notification Title is Required'}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label>Notification Message  <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                            <Input
                                type="textarea"
                                id="textarea"
                                rows="3" maxLength={250}
                                name="message" value={finalData.message} onChange={notificationDataChange}
                                className={"form-control" + (submitted && !finalData.message ? ' is-invalid' : '')}
                            />
                            {submitted && !finalData.message ? (
                                <FormFeedback type="invalid">{'Notification Message is Required'}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    </div>
                    <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
                        <Col>
                            <div className="text-start">
                                &nbsp;&nbsp;
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    // eslint-disable-next-line react/prop-types
                                    onClick={props.closeCanvas}
                                >
                                    Back
                                </button>
                                &nbsp;&nbsp;
                                {!isEdit &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Save
                                    </button>
                                }

                                {isEdit &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Update
                                    </button>
                                }
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </React.Fragment >
    )
}


New_Publisher_Notification.propTypes = {
    selectedPub_Notification: PropTypes.any,
};
export default New_Publisher_Notification