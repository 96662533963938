/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback,  Collapse,Spinner } from "reactstrap";
import Select from "react-select";
import axios from "axios";
import classnames from "classnames";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";
import Creative_Selection from "./Creative_Selection";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// actions
import {
  addNewCreative as onADDCreative,
  updateCreative as onUpdateCreative,
  readCreative as onReadCreative,
  addCreativeSuccess,
  addCreativeFail,
  updateCreativeSuccess,
  updateCreativeFail,
} from "store/Demand/Creative/actions";
import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";

var creativeBody = {}
var templateName = ''
var creativeBodyInfo = []

const NewCustomCreative = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [col1, setcol1] = useState(false);
  const [loader, setLoader] = useState(false);
  // console.log(props)

  const t_col1 = () => {
    setcol1(!col1);
  }

  const [finalData, setFinalData] = useState({
    typeFor:props.creativeType,
    googleDemandOption: '',
    googleDemandTag: '',
    creativeType: props.creativeType,
    creativeWeight: 1,
    lineItemID:'',
  })

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 1000,
    extendedTimeOut: 1000
  };

  const [creativeData, setCreativeData] = useState([])
  const [creativeInfo, setCreativeInfo] = useState([])
  const [newCustomCreative, setNewCustomCreative] = useState(true)
  const linkRef = useRef();
  const lineItemRef = useRef();
  const [submitted, setSubmitted] = useState(false);
  const [googleState, setGoogleState] = useState(true);

  const { errorMsg, successMsg ,readCreativeData} = useSelector(state => ({
    errorMsg: state.creatives.errorMsg,
    successMsg: state.creatives.successMsg,
    readCreativeData: state.creatives.creative
  }));

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addCreativeSuccess(""));
        dispatch(addCreativeFail(""));
        dispatch(updateCreativeSuccess(""));
        dispatch(updateCreativeFail(""));
        setLoader(false)
        if (props.selectedCreativeData !== '') {
          props.creativeNewClose('Update')
        } else {
          props.creativeNewClose('Create')
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if(errorMsg.status && errorMsg.status === 500){
      }else{
      dispatch(addCreativeSuccess(""));
      dispatch(addCreativeFail(null));
      dispatch(updateCreativeSuccess(""));
      dispatch(updateCreativeFail(null));
    }
  }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addCreativeSuccess(""));
    dispatch(addCreativeFail(null));
    dispatch(updateCreativeSuccess(""));
    dispatch(updateCreativeFail(null));
    props.creativeNewClose('')
};

  const handleChange = e => {
    const { name, value } = e.target;
    setFinalData(creative => ({ ...creative, [name]: value }));
  }

  const blurChange = (e) => {
    const { name, value } = e.target;
    if (e.target.value < 1) {
      setFinalData(finalData => ({ ...finalData, [name]: 1 }));
    } else {
      setFinalData(finalData => ({ ...finalData, [name]: value }));
    }
  }

  const contentChange = index => e => {
    let newArr = [...creativeData];
    if(e.target.type === 'checkbox'){
      newArr[index].checked = e.target.checked;
      setCreativeData(newArr);
    }else{
      newArr[index].value = e.target.value;
      setCreativeData(newArr);
    }
    let countChecked = 0
    creativeData.map((item, index) => {
      const { label, value } = item;
      let numMul = isNaN(value)
      if(item.variableType === "NUMERIC" && numMul){
        if(value){
          creativeBody = { ...creativeBody, [label]: parseInt(value) }
        }
      }else if (item.variableType === "JSONArray") {
        if(typeof value === "string"){
            let urls = value.split(/[\s,]+/);
            creativeBody = { ...creativeBody, [label]: urls }
        }else{
            creativeBody = { ...creativeBody, [label]: value }
        }
    }
      else if(item.variableType === "CHECKBOX"){
        if(item.checked){
          countChecked = countChecked + 1
          creativeBody = { ...creativeBody, [label]: item.description }
        }else{
          creativeBody = { ...creativeBody, [label]: '' }
        }
      }else{
        if(value === 'YES'){
          creativeBody = { ...creativeBody, [label]: true }
        }else if(value === 'NO'){
          creativeBody = { ...creativeBody, [label]: false }
        }else{
          creativeBody = { ...creativeBody, [label]: value }
        }
      }
      // console.log(newArr)
      setFinalData({ ...finalData, ...creativeBody })
    })
    // console.log(countChecked)
    setCreativeData(modifyData(countChecked,creativeData))
  }

  const getCreativeData = async (selectedCreativeData) => {
    setFinalData({ ...finalData, ...selectedCreativeData })
  }

  useEffect(() => {
    if (props.selectedCreativeData !== '') {
        dispatch(onReadCreative({ ...props.selectedCreativeData,userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner}));
        setIsEdit(true)
    }
  }, [])

  useEffect(() => {
    creativeBodyInfo = []
    if (readCreativeData !== null && JSON.stringify(readCreativeData) !== '{}') {
      setCreativeData([])
      templateName = ''
        setFinalData({
          typeFor:props.creativeType,
          googleDemandOption: '',
          googleDemandTag: '',
          creativeType: props.creativeType,
          creativeWeight: 1,
          lineItemID:'',
        })
        creativeBody = {}
      creativeBodyInfo.push(readCreativeData)
      setFinalData(finalData => ({ ...finalData, ...readCreativeData}));
      setFinalData(finalData => ({ ...finalData, lineItemID:readCreativeData.lineItemID}));
      if (props.selectedCreativeData !== ''){
        handleData(readCreativeData.creativeTemplateName)
      }
    }
  }, [readCreativeData]);

  useEffect(() => {
    if (props.selectedTemplate.value) {
      setCreativeData([])
      templateName = ''
        setFinalData({
          typeFor:props.creativeType,
          googleDemandOption: '',
          googleDemandTag: '',
          creativeType: props.creativeType,
          creativeWeight: 1,
          lineItemID:'',
        })
        creativeBody = {}
        handleData(props.selectedTemplate.value)
    }

}, [props.selectedTemplate.value])

  useEffect(() => {
    if (props.selectedCreativeData && readCreativeData) {
     
      creativeBody = { ...creativeBody, creativeTemplateName: props.selectedCreativeData.creativeTemplateName }
   
    } else {
      creativeBody = { ...creativeBody, creativeTemplateName: props.selectedTemplate.value }
    }
   if(props.selectedLineItem !== ''){
    setFinalData({ ...finalData, lineItemID: props.selectedLineItem.lineItemID })
   }
   if(props.creativeType === 'highImpact' )
   {
    setFinalData({ ...finalData, creativeSize:'1x1' })
   }

  }, [])

  const handleData = async (template) => {
    const bodyData = {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        creativeTemplateName: template ,
        name:userInfo.name,
        parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
    }
    axios.post("/v2.0/operations/CreativeTemplates/getVariable", bodyData,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept": 'application/json',
          "Authorization": `Bearer ${userInfo.token}`
        }
      })
      .then((res) => {
        if (res.data) {
          templateName = res.data.creativeTemplateName
            creativeBody = { ...creativeBody, creativeType: props.creativeType }
          creativeBody = { ...creativeBody, url: res.data.url }
          setFinalData({ ...finalData, ...creativeBody })
          let countChecked = 0
          res.data.creativeTemplateVariables.map((item, index) => {
            let label = item.label
            if (props.selectedCreativeData !== '') {
              if(creativeBodyInfo[0][label] === true || creativeBodyInfo[0][label] === 'true'){
                item.value = 'YES'
                item.checked = true
              }else if(creativeBodyInfo[0][label] === false || creativeBodyInfo[0][label] === 'false'){
                item.value = 'NO'
                item.checked = false
              }else{
                item.value = creativeBodyInfo[0][label]
              }
              if (item.variableType === "CHECKBOX") {
                if(creativeBodyInfo[0][label] !== ''){
                  countChecked = countChecked + 1
                  item.checked = true
                  item.isDisabled = false
                }else if(creativeBodyInfo[0][label] === ''){
                  item.checked = false
                  item.isDisabled = false
                }
              }
            } else {
              item.value = item.defaultValue ? item.defaultValue : ''
              if (item.variableType === "CHECKBOX") {
                item.checked = false;
                item.isDisabled = false
              }
  
            }
            if (label.includes('url')){
              item.isUrlContains = false
            }
          })
          setCreativeData(modifyData(countChecked,res.data.creativeTemplateVariables));
          res.data.creativeTemplateVariables.map((item, index) => {
            if (item.variableType === "CHECKBOX") {
              const { label, checked } = item;
              if(checked){
                creativeBody = { ...creativeBody, [label]: item.description }
              }else{
                creativeBody = { ...creativeBody, [label]: '' }
              }
          
            } else {
              const { label, value } = item;
              if (value === 'YES') {
                  creativeBody = { ...creativeBody, [label]: true }
              } else if (value === 'NO') {
                  creativeBody = { ...creativeBody, [label]: false }
              } else {
                  creativeBody = { ...creativeBody, [label]: value }
              }
            }
            if(props.selectedLineItem !== ''){
              setFinalData({ ...finalData, lineItemID: props.selectedLineItem.lineItemID })
             }
            setFinalData({ ...finalData, ...creativeBody })
          })
          if (readCreativeData !== '' && props.selectedCreativeData !== '') {
            setNewCustomCreative(false)
            setFinalData({
              ...finalData,
              ...readCreativeData
            })
          }
          if(props.selectedLineItem !== ''){
            setFinalData({ ...finalData, lineItemID: props.selectedLineItem.lineItemID })
           }
           if(props.status === 'CreativeCreateByLineItem'){
            setFinalData({ ...finalData, lineItemID: props.selectedLineItem.lineItemID })
        }
           if(props.creativeType === 'highImpact' )
           {
            setFinalData(finalData => ({...finalData,...readCreativeData}));
            setFinalData({ ...finalData, creativeSize:'1x1' })
           }
        }
        if (readCreativeData !== '') {
          setFinalData({
            ...finalData,
            ...readCreativeData
          })
        }
      
      })
      .catch((err) => {
      });
  }

  const handleSubmit = async (e) => {
    let data = finalData
    // console.log(finalData,readCreativeData)
    if(props.creativeType === 'highImpact' )
    {
     data.creativeSize = '1x1'
    }
    if(props.selectedLineItem !== ''){
      data.lineItemID = props.selectedLineItem.lineItemID
     }
    data.creativeTemplateName = templateName
    e.preventDefault();
    var body = ''
    // console.log(data)
    setSubmitted(true);
    if (data.creativeName && data.lineItemID  && data.creativeWeight) {
      setLoader(true)
      if (props.selectedCreativeData !== ''){
        dispatch(onUpdateCreative({ ...data,creativeName:data.creativeName.trim(),userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner }));
      }else{
        dispatch(onADDCreative({ ...data,creativeName:data.creativeName.trim(),userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner }));
      }
    }
  }

  const modifyData = (count,Variabledata) => {
    // console.log(Variabledata)
   Variabledata.map((item, index) => {
      index = index + 1
      if(item.variableType === "CHECKBOX"){
        if(count === 5){
          if(item.checked){
             item.isDisabled = false
          }else{
            item.isDisabled = true
          }
        }else{
          item.isDisabled = false
        }
      }
      if(index > 4 && count === 5 && index < Variabledata.length){
        // console.log(index)
        // console.log(Variabledata[index])
        // console.log(Variabledata[index-1])
          if((Variabledata[index].description).includes(Variabledata[index-1].description)){
              if(Variabledata[index-1].variableType === "CHECKBOX" && !Variabledata[index-1].checked){
                Variabledata[index].isUrlContains = true
              }
          }
      }else{
        if(index < Variabledata.length && (Variabledata[index].description).includes(Variabledata[index-1].description)){
          Variabledata[index].isUrlContains = false
        }
      }
    })
    return Variabledata
  }

  function allowAlphaNumericSpace(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if ( !(code > 43 && code < 45) && !(code > 47 && code < 58)) { e.preventDefault();}
  };

  function allowOnlyNumbers(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if ( !(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault();}
  };

  function checkValues(e) {
    let mulNum = e.includes(',')
    return mulNum
  };

  const createUI = () => {
    return creativeData.map((item, i) =>
      <div key={i}>
        {item.variableType === "STRING" &&
          <div className="mt-2">
            <Label className="form-label">{item.description}</Label>
            {item.defaultValue &&
                <Input
                type="text" name={item.label} value={item.value} onChange={contentChange(i)} className='form-control' onKeyPress={allowAlphaNumericSpace} readOnly={props.selectedArchived || (item.isUrlContains ? item.isUrlContains : false)}  
              />
            }
            {!item.defaultValue &&
              <Input
              type="text" name={item.label} value={item.value} onChange={contentChange(i)} className='form-control' readOnly={props.selectedArchived || (item.isUrlContains ? item.isUrlContains : false)}
            />
  }
          
          </div>
        }
        {item.variableType === "NUMERIC" &&
          <div className="mt-2">
            <Label className="form-label">{item.description}</Label>
                <Input
                type="number" name={item.label} value={item.value ? parseInt(item.value) : ''} onChange={contentChange(i)} className='form-control' readOnly={props.selectedArchived}  
              />
          </div>
        }
        {item.variableType === "LONG TEXT" &&
          <div className="form-group mt-2">
            <Label className="form-label">{item.description}</Label>
            <Input type="textarea" id="textarea"
              rows="3" placeholder={item.label} className="form-control" style={{ width: '100%' }}
              name={item.label} value={item.value} onChange={contentChange(i)} readOnly={props.selectedArchived}
            />
          </div>
        }
        {item.variableType === "JSONArray" &&
                    <div className="form-group mt-4">
                        <Label className="form-label">{item.description}</Label>
                        <Input type="textarea" id="textarea" 
                            rows="3" placeholder={item.label} className="form-control" style={{ width: '100%' }}
                            name={item.label} value={item.value} onChange={contentChange(i)} readOnly={props.selectedArchived}
                        />
                    </div>
        }
        {item.variableType === "LIST" &&
          <div className="form-group mt-2">
            <Label className="form-label">{item.description}</Label>
            <Input
              type="select"
              className="form-select"
              onChange={contentChange(i)}
              name={item.label} value={item.value} disabled={props.selectedArchived}
            >
              {/* <option value=''> Select {item.label}</option> */}
              {item.choices.map((item) => (
                <option key={item} value={item}>{item}</option>
              ))}
            </Input>
            {/* <Select
                        value={selectedGroup}
                        onChange={(s) => {
                          contentChange(s,i)
                        }}
                        options={item.choices}
                        classNamePrefix="select2-selection"
                      />
                  <Select
                      labelId="demo-simple-select-placeholder-label-label"
                      id="demo-simple-select-placeholder-label"
                      onChange={contentChange(i)}
                      name={item.label} value={item.value}
                      displayEmpty
                      className='form-control'
                  >
                      <MenuItem value="" disabled >
                          Select {item.label}
                      </MenuItem>
                      {item.choices.map((item) => (
                          <MenuItem
                              key={item}
                              value={item}
                          >
                              {item}
                          </MenuItem>
                      ))}
                  </Select> */}
          </div>
        }
        {item.variableType === "CHECKBOX" &&
          <div className="form-check mt-2 mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              checked={item.checked}
              disabled={props.selectedArchived || item.isDisabled}
              onChange={contentChange(i)}
            />
            <label
              className="form-check-label  m-2 mt-1"
            >
              {item.description}
            </label>
          </div>
        }
      </div>
    )
  }

  return (
    <React.Fragment>
      {loader &&
              <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{pointerEvents:(loader) ? 'none' : 'unset'}}>
        <Form onSubmit={handleSubmit}>
          <Row form className="formBodyScroll" style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
            <Col xs={12}>
              <h6 className="text-muted">Custom Creative Setup</h6>
              <hr></hr>
              <Creative_Selection selectedArchived={props.selectedArchived} creativeStatus={isEdit} creativeType={props.creativeType} selectedItems={getCreativeData} submitted={submitted} selectedCreativeData={props.selectedCreativeData} selectedLineItem={props.selectedLineItem}/>
              <h5 className="mb-4">{templateName} Settings</h5>
              <hr></hr>
              <div className="mb-3">
                <div className="accordion" id="accordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                    </h2>

                    <Collapse isOpen={true} className="accordion-collapse">
                      <div className="accordion-body">
                        {createUI()}
                      </div>
                    </Collapse>
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="mb-3">
                <Label className="form-label">Creative Weightage</Label>
                <Input
                 type="text" name="creativeWeight" value={finalData.creativeWeight}   onKeyPress={allowOnlyNumbers} onBlur={blurChange}  readOnly={props.selectedArchived}
                  onChange={handleChange} className={'form-control' + (submitted && !finalData.creativeWeight ? ' is-invalid' : '')}
             />
             {submitted && !finalData.creativeWeight &&
                 <div className="invalid-feedback">Creative Weightage is required</div>
             }
              </div>
            </Col>
          </Row>
          <Row className="drawer-footer"  style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
                {isEdit &&  !props.selectedArchived &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.creativeTypeClose}
                >
                  Back
                </button>
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
            <Toaster status="error" msg={errorMsg.message}/>
        }
      </div>
        {errorMsg !== null && errorMsg !== '' &&  errorMsg.status && errorMsg.status === 500 && 
            <div className="p-0 justify-content-center d-flex">
            <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')}/>
          </div>
        }
    </React.Fragment>
  )
}

NewCustomCreative.propTypes = {
  history: PropTypes.object,
}

export default NewCustomCreative