import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import GoogleLoginApp from "../pages/Authentication/Login2"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

import Advertiser from "../pages/Demand/Advertiser/Advertiser"
import Insertion_Order_Group from "../pages/Demand/Insertion_Order_Group/Insertion_Order_Group"
import Insertion_Order from "../pages/Demand/Insertion_Order/Insertion_Order"
import Line_Items from "pages/Demand/Line_Item/Line_Items"
import Creative from "pages/Demand/Creative/Creative"

import Partner_Management from "pages/Partner_Management/Partner_Management"

import Supply_Partner from "pages/Supply/SupplyPartner/Supply_Partner"
import Publisher from "pages/Supply/Publisher/Publisher"
import AdUnitGroup from "pages/Supply/AdUnitGroup/AdUnitGroup"
import AdUnit from "pages/Supply/AdUnit/AdUnit"
import ChildAdUnit from "pages/Supply/ChildAdUnit/ChildAdUnit"
import Player_Settings from "pages/Supply/Player_Settings /Player_Settings"
import StbrSetting from "pages/Supply/StbrSettings/StbrSettings"


import CreativeWeightage from "pages/Demand/Creative/CreativeWeightage"
import VideoContent from "pages/Tools/VideoContent/VideoContent"
import ScreenshotTool from "pages/Tools/ScreenshotTool/ScreenshotTool"
import ReportUpload from "pages/ReportUpload/ReportUpload"
import Access_Management from "pages/Admin/Access_Management/Access_Management"
import Ads_Txt_Management from "pages/Admin/Ads_Txt_Management/Ads_Txt_Management"
import Custom_Creative_Template from "pages/Admin/Custom_Creative_Template"
import Global_Settings from "pages/Admin/Global_Settings"
import Master_Log from "pages/Admin/Master_Log"
import Product_Notification from "pages/Admin/ProductNotification/Product_Notification"
import Publisher_Notification from "pages/Admin/PublisherNotification/Publisher_Notification"
import Admin_Brand_Safety from "pages/Admin/Brand_Safety/Admin_Brand_Safety"
import Publisher_Log from "pages/Admin/Publisher_Log"
import Invoice_Approval from "pages/Billing/Invoice_Approval"
import Invoices from "pages/Billing/Invoices"
import Payments from "pages/Billing/Payments"
import Settings from "pages/House_Ads/Settings/Settings"
import Supply_Tag from "pages/House_Ads/House_AdUnits/Supply_Tag"
import Analytical_Report from "pages/Reporting/AnalyticalReports/Analytical_Report"
import PageUrlReport from "pages/Reporting/PageURLReport/PageUrlReport"
import QA_Report from "pages/Reporting/QA_Report"
import Revenue_Report from "pages/Reporting/Revenue_Report"
import Ad_TAg_Tester from "pages/Tools/Ad_TAg_Tester"
import Vast_Tag_Tester from "pages/Tools/Vast_Tag_Tester"
import Ads_Txt_Entries from "pages/Admin/Ads_Txt_Management/Ads_Txt_Entries"
import AdsTxtValidator from "pages/Tools/Ads.Txt.Validator/AdsTxtValidator"
import SSPDomainValidator from "pages/Tools/Ads.Txt.Validator/SSPDomainValidator"
import Publisher_Dashboard from "pages/Publisher_Console/Publisher_Dashboard"
import Video_Library from "pages/Publisher_Admin/Video_Library"
import Reporting_API from "pages/Publisher_Console/Settings/Reporting_API"
import Brand_Safety from "pages/Publisher_Console/Settings/Brand_Safety"
import Explore_Adformats from "pages/Publisher_Console/Revenue_Opportunities/Explore_Adformats"
import Category from "pages/House_Ads/Categories/Category"
import NativeWidget from "pages/House_Ads/Creatives/NativeAd/NativeWidget"
import Creatives from "pages/House_Ads/Creatives/Creatives"
import ProxyAdUnits from "pages/Proxy/ProxyAdUnits/ProxyAdUnits"
import QueryReport from "pages/Proxy/ReportProxy/QueryReport"
import CustomReport from "pages/House_Ads/Reports/CustomReport"
import TagController from "pages/Demand/TagController/TagController"
import HouseAdUnit from "pages/House_Ads/Ad_Content/HouseAdUnit"
import NativeAd from "pages/House_Ads/Creatives/NativeAd/NativeAd"
import HouseAdPub from "pages/House_Ads/HouseAdPub/HouseAdPub"
import Partner_Management_V2 from "pages/Partner_Management/Partner_Management_V2"
import ResetPassword from "pages/Authentication/ResetPassword"
import AnalyticsDashBoard from "pages/Dashboard/AnalyticsDashBoard"
import Display_Analytics from "pages/BSRV2021/Reporting/Display_Analytics"
import Page_URLs from "pages/BSRV2021/Reporting/Page_URL"
import CommonDashBoard from "pages/Dashboard/CommonDashBoard"
import MyAdtxt from "pages/Tools/MyAdtxt"
import HighImpactSettings from "pages/Supply/HighImpactSettings/HighImpactSettings"
import AdOpsDashBoard from "pages/Dashboard/AdOpsDashBoard"
import Publisher_AdUnitAdUnitGroup from "pages/Publisher_Console/Supply/AdUnitGroup/AdUnitGroup"
import Publisher_AdUnit from "pages/Publisher_Console/Supply/AdUnit/AdUnit"
import Publisher_Revenue_Report from "pages/Publisher_Console/Reporting/Revenue_Report"
import Publisher_Invoices from "pages/Publisher_Console/Billing/Invoices"
import Publisher_Payments from "pages/Publisher_Console/Billing/Payments"
import Publisher_Ads_Txt_Management from "pages/Publisher_Console/Settings/Ads_Txt_Management/Ads_Txt_Management"
import Publisher_Ads_Txt_Entries from "pages/Publisher_Console/Settings/Ads_Txt_Management/Ads_Txt_Entries"
import Publisher_Report from "pages/Publisher_Console/Reporting/Publisher_Reports"
import Publisher_Profile from "pages/Publisher_Console/Publisher_Profile/Publisher_Profile"
import ApprovePermission from "pages/Authentication/ApprovePermission"

//Media
import Video_Content_Library from "pages/MediaBidsxchange/Video_Content_Library/Video_Content_Library"
import Video_Palylists from "pages/MediaBidsxchange/Video_Playlists/Video_Playlists"
import Video_Import from "pages/MediaBidsxchange/Video_Import/Video_Import"
import Content_Market_Place from "pages/MediaBidsxchange/Content_Market_Place/Content_Market_Place"

// Admin Supply Tickets
import Support_Tickets from "pages/Admin/Support_Tickets/Support_Tickets"

import Partner_Ads_Txt_Entries from "pages/Admin/Ads_Txt_Management/Partner_Ads_Txt_Entries"
import Action_Notification from "pages/Admin/PublisherNotification/Action_Notification"
import PublisherConsole_Publisher from "pages/Publisher_Console/Supply/Publisher/Publisher"

// Publisher Support Tickets
import Publisher_Support_Tickets from "pages/Publisher_Console/Publisher_Support_Tickets/Publisher_Support_Tickets"
import Publisher_Access_Management from "pages/Admin/Publisher_Access_Management/Publisher_Access_Management"

//Thumnail Creator
import VideoConverter from "pages/Tools/VideoConverter/VideoConverter"
import LinkTester from "pages/Tools/LinkTester/linkTester"
import PlayerOptions from "pages/Tools/PlayerOptions"
//ai video generator
import Ai_Video_Generator from "pages/Tools/Ai_Video_Genarator"
import PublisherScheduleReport from "pages/Publisher_Console/NewReporting/ScheduleReport"
import LockScreen from "../pages/Authentication/LockScreen"
import SessionOut from "pages/Authentication/SessionOut"
import AdCodeTester from "pages/Tools/AdTesingCode"
import PagesMaintenance from "pages/Authentication/PagesMaintenance"
import ServerDashboard from "pages/Dashboard/ServerDashboard"
import Summerize from "pages/Tools/Summerization/Summerize"
import WelcomeComp from "pages/Dashboard/WelcomeComp"
import AdminSidebar from "pages/Admin/Admin_Sidebar/AdminSidebar"
import AdminPages from "pages/Admin/Admin_Sidebar/AdminPages"
import Ads_TXT_Manage from "pages/Admin/Ads_Txt_Management/Ads_TXT_Manage"
import NetworkSetting from "pages/Admin/NetworkSettings/NetworkSetting"
import IPMapping from "pages/Admin/NetworkSettings/ContextualSettings"
import DataFeed from "pages/Admin/DataFeed"
import NetworkBlocking from "pages/NetworkBlockings/NetworkBlocking"
import Videosuite_Config from "pages/MediaBidsxchange/Videosuite_Config/videosuite_config"
import ContextualSettings from "pages/ContextualSettings/ContextualSettings"
import CMS_Configuration from "pages/Publisher_Console/Settings/CMS_Configuration"

import HBBuyer_Management_V2 from "pages/Demand/HeaderBidding/HBBuyers/HBBuyer"
import AssociateAdUnitwithBuyer from "pages/Demand/HeaderBidding/HBBuyers/AssociateAdUnitwithBuyer"
import BuyerAdUnits from "pages/Demand/HeaderBidding/BuyerAdUnits/BuyerAdUnits"
import AssociateExistingBuyer from "pages/Demand/HeaderBidding/BuyerAdUnits/AssociateExistingBuyer"

import HBBuyer_Management_V2_old from "pages/HeaderBidding/HBBuyers/HBBuyer"
import AssociateAdUnitwithBuyer_old from "pages/HeaderBidding/HBBuyers/AssociateAdUnitwithBuyer"
import BuyerAdUnits_old from "pages/HeaderBidding/BuyerAdUnits/BuyerAdUnits"
import AssociateExistingBuyer_old from "pages/HeaderBidding/BuyerAdUnits/AssociateExistingBuyer"

//media publisher console
import Video_Content_Library_Pub from "pages/Publisher_Console/MediaBidsxchange/Video_Content_Library/Video_Content_Library"
import Video_Playlists_Pub from "pages/Publisher_Console/MediaBidsxchange/Video_Playlists/Video_Playlists"
import Video_Import_Pub from "pages/Publisher_Console/MediaBidsxchange/Video_Import/Video_Import"
import Content_Market_Place_Pub from "pages/Publisher_Console/MediaBidsxchange/Content_Market_Place/Content_Market_Place"
import ErrorPage from "pages/errorFile"
import TextTranslator from "pages/Tools/Summerization/TextTranslator"

import Bidder from "pages/HeaderBiddingSettings/Bidder/Bidder"

import Analytical_ReportMaster from "pages/Reporting/Master_Report/AnalyticalReports/Analytical_ReportMaster"
import PageUrlReportMaster from "pages/Reporting/Master_Report/PageURLReport/PageUrlReportMaster"
import YM_Team_Analytical from "pages/Reporting/YM_Team/YM_Team_Analytical"
import YM_Team_Page_URL from "pages/Reporting/YM_Team/YM_Team_Page_URL"
import AS_Team_Analytical from "pages/Reporting/YM_Team/AS_Team_Analytical"
import MIS_Team_Analytical from "pages/Reporting/YM_Team/MIS_Team_Analytical"

import HBGeoSettings from "pages/HeaderBiddingSettings/HBGeo/HBGeoSettings"
import Analytics_ReportMaster from "pages/Reporting/Master_Report/AnalyticalReports/Analytics_ReportMaster"
import Page_Url_ReportMaster from "pages/Reporting/Master_Report/PageURLReport/Page_Url_ReportMaster"
import Agency_Dashboard from "pages/Agency_Console/Agency_Dashboard"
import Agency_Report from "pages/Agency_Console/Agency_Reports/Agency_Report"
import AgencyReport from "pages/Reporting/AgencyReport"
import Agency_BrandSafety from "pages/Agency_Console/Agency_BrandSafety"
import Agency_ExploreAdFormats from "pages/Agency_Console/Agency_ExploreAdFormats"
import AgencyDashBoard from "pages/Dashboard/AgencyDashBoard"
import RUpload from "pages/Tools/RUpload"
import AgencyDashBoardRupee from "pages/Dashboard/AgencyDashBoardRupee"
import Supply_Partners_Mgmt from "pages/Admin/Supply_Partner_Management/Supply_Partners_Mgmt"
import Supply_Publisher_Access_Management from "pages/Admin/Supply_Publisher_Access_Management/Supply_Publisher_Access_Management"



const userInfo = JSON.parse(localStorage.getItem('authUser'));
const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));

const authProtectedRoutes = [
  { path: "/Dashboard", component: CommonDashBoard },
  { path: "/Marketplace_Dashboard", component: Dashboard },
  { path: "/Publisher_Marketplace_Dashboard", component: Publisher_Dashboard },
  { path: "/Agency_Dashboard_USD", component: AgencyDashBoard },
  { path: "/Agency_Dashboard_INR", component: AgencyDashBoardRupee },
  { path: "/AdOps_dashboard", component: AdOpsDashBoard },
  { path: "/Server_Dashboard", component: ServerDashboard },
  { path: "/AdPublishers", component: HouseAdPub },
  { path: "/Display_Analytics", component: Display_Analytics },
  { path: "/Advertiser", component: Advertiser },
  { path: "/Admin", component: AdminPages },
  { path: "/ErrorPage", component: ErrorPage },


  // //profile
  { path: "/profile", component: UserProfile },

  //vasu 

  { path: "/Insertion_Order_Group", component: Insertion_Order_Group },
  { path: "/Insertion_Order", component: Insertion_Order },
  { path: "/Line_Items", component: Line_Items },
  { path: "/Creative", component: Creative },
  { path: "/Creative_Weightage", component: CreativeWeightage },
  { path: "/Tag_Controller", component: TagController },

  { path: "/HB_Buyer", component: HBBuyer_Management_V2 },
  { path: "/AssociateAdUnit", component: AssociateAdUnitwithBuyer },
  { path: "/HB_AdUnits", component: BuyerAdUnits },
  { path: "/AssociateBuyer", component: AssociateExistingBuyer },

  { path: "/HB_Buyer_old", component: HBBuyer_Management_V2_old },
  { path: "/AssociateAdUnit_old", component: AssociateAdUnitwithBuyer_old },
  { path: "/HB_AdUnits_old", component: BuyerAdUnits_old },
  { path: "/AssociateBuyer_old", component: AssociateExistingBuyer_old },


  // { path: "/Partner_Management", component: Partner_Management },
  { path: "/Partner_Management", component: Partner_Management_V2 },
  { path: "/Supply_Partner_Management", component: Supply_Partners_Mgmt },
  { path: "/Supply_Partner_ACM", component: Supply_Publisher_Access_Management },


  { path: "/Supply_Partner", component: Supply_Partner },
  { path: "/Publisher", component: Publisher },
  { path: "/AdUnitGroup", component: AdUnitGroup },
  { path: "/AdUnit", component: AdUnit },
  { path: "/ChildAdUnit", component: ChildAdUnit },
  { path: "/Player_Settings", component: Player_Settings },
  { path: "/High_Impact", component: HighImpactSettings },
  { path: "/Stbr_Settings", component: StbrSetting },


  { path: "/Network_Blocking", component: NetworkBlocking },
  { path: "/Contextual_Settings", component: ContextualSettings },
  { path: "/HB_Geo_Settings", component: HBGeoSettings },
  { path: "/Bidder", component: Bidder },

  { path: "/VideoContent", component: VideoContent },
  { path: "/ScreenshotTool", component: ScreenshotTool },
  { path: "/playerDashboard", component: PlayerOptions },
  { path: "/VideoCompressor", component: VideoConverter },
  { path: "/LinkTester", component: LinkTester },
  { path: "/AiVideoGenerator", component: Ai_Video_Generator },
  { path: "/Ad_Tag_Tester", component: Ad_TAg_Tester },
  { path: "/Vast_Tag_Tester", component: Vast_Tag_Tester },
  { path: "/Ads_Txt_Validator", component: AdsTxtValidator },
  { path: "/SSP_Domain_Validator", component: SSPDomainValidator },
  { path: "/MyAdtxt", component: MyAdtxt },
  { path: "/Summerization", component: Summerize },
  { path: "/RUpload", component: RUpload },
  { path: "/TextTranslator", component: TextTranslator },

  { path: "/Report_Upload", component: ReportUpload },

  { path: "/Admin_Settings", component: AdminSidebar },
  { path: "/Access_Management", component: Access_Management },
  { path: "/Network_Settings", component: NetworkSetting },
  { path: "/Publisher_Access_Management", component: Publisher_Access_Management },
  { path: "/Ads_Txt_Queries", component: Ads_Txt_Management },
  { path: "/Ads_Txt_Entries", component: Ads_Txt_Entries },
  { path: "/Publisher_Ads_Txt_Entries", component: Partner_Ads_Txt_Entries },
  { path: "/Custom_Creative_Template", component: Custom_Creative_Template },
  { path: "/Global_Settings", component: Global_Settings },
  { path: "/Master_Log", component: Master_Log },
  { path: "/Product_Notification", component: Product_Notification },
  { path: "/Notification", component: Publisher_Notification },
  { path: "/Publisher_Approval", component: Action_Notification },
  { path: "/Publisher_Log", component: Publisher_Log },
  { path: "/Ads_Txt_Management", component: Ads_TXT_Manage },
  { path: "/Data_Feed", component: DataFeed },
  {path:'/Admin_Brand_Safety', component:Admin_Brand_Safety},

  { path: "/Invoice_Approval", component: Invoice_Approval },
  { path: "/Invoice_History", component: Invoices },
  { path: "/Payments", component: Payments },
  //Media
  { path: "/Video_Content_Library", component: Video_Content_Library },
  { path: "/Video_Playlists", component: Video_Palylists },
  { path: "/Video_Import", component: Video_Import },
  { path: "/Content_Market_Place", component: Content_Market_Place },
  { path: "/videosuite_config", component: Videosuite_Config },



  //

  //admin supply tickets
  { path: "/Support_Tickets", component: Support_Tickets },

  { path: "/House_AdUnit", component: HouseAdUnit },
  { path: "/Settings", component: Settings },
  { path: "/AdUnits", component: Supply_Tag },
  { path: "/Categories", component: Category },
  { path: "/NativeWidget", component: NativeAd },
  { path: "/Creatives", component: Creatives },
  { path: "/Custom_Report", component: CustomReport },


  { path: "/Proxy_AdUnits", component: ProxyAdUnits },
  { path: "/Query_Report", component: QueryReport },

  { path: "/Analytics_Report", component: Analytical_Report },
  { path: "/Page_Url_Report", component: PageUrlReport },
  { path: "/QA_Report", component: QA_Report },
  { path: "/Revenue_Report", component: Revenue_Report },
  { path: "/AgencyReport", component: AgencyReport },

  { path: "/Analytics_Report_Master", component: Analytics_ReportMaster },
  { path: "/Page_Url_Report_Master", component: Page_Url_ReportMaster },
  { path: "/Revenue_Report_Master", component: Revenue_Report },

  { path: "/Page_URL_Reports", component: Page_URLs },

  { path: "/YM_Analytics_Report", component: YM_Team_Analytical },
  { path: "/YM_Page_Url_Report", component: YM_Team_Page_URL },
  { path: "/AS_Analytics_Report", component: AS_Team_Analytical },
  { path: "/MIS_Analytics_Report", component: MIS_Team_Analytical },

  { path: "/Video_Library", component: Video_Library },

  { path: "/Explore_Adformats", component: Explore_Adformats },



  //Publisher Console

  { path: "/User_Profile", component: Publisher_Profile },
  { path: "/PublisherConsole_Publisher", component: PublisherConsole_Publisher },
  { path: "/Publisher_AdUnitGroup", component: Publisher_AdUnitAdUnitGroup },
  { path: "/Publisher_AdUnit", component: Publisher_AdUnit },

  { path: "/Publisher_Revenue_Report", component: Publisher_Revenue_Report },
  { path: "/Publisher_Report", component: Publisher_Report },
  { path: "/PublisherSchedule_Report", component: PublisherScheduleReport },

  { path: "/Publisher_Invoices", component: Publisher_Invoices },
  { path: "/Publisher_Payments", component: Publisher_Payments },

  { path: "/Publisher_Ads_Txt_Queries", component: Publisher_Ads_Txt_Management },
  { path: "/Publishers_Ads_Txt_Entries", component: Publisher_Ads_Txt_Entries },
  { path: "/BrandSafety", component: Brand_Safety },
  { path: "/CMS_Configuration", component: CMS_Configuration },
  { path: "/Reporting_API", component: Reporting_API },
  {path:"/Admin_Brand_Safety",component:Admin_Brand_Safety},

  { path: "/Publisher_Support_Tickets", component: Publisher_Support_Tickets },

  //Media
  { path: "/Publisher_Video_Content_Library", component: Video_Content_Library_Pub },
  { path: "/Publisher_Video_Playlists", component: Video_Playlists_Pub },
  { path: "/Publisher_Video_Import", component: Video_Import_Pub },
  { path: "/Publisher_Content_Market_Place", component: Content_Market_Place_Pub },

  { path: "/AdCodeTester", component: AdCodeTester },


  // //AgencyConsole
  { path: "/Agency_Marketplace_Dashboard", component: Agency_Dashboard },
  { path: "/Agency_Report", component:Agency_Report},
  {path:"/Agency_BrandSafety",component:Agency_BrandSafety},
  {path:'/Agency_ExploreAdFormats',component:Agency_ExploreAdFormats},
  // { path: "/User_Profile", component: Publisher_Profile },
  // { path: "/PublisherConsole_Publisher", component: PublisherConsole_Publisher },
  // { path: "/Publisher_AdUnitGroup", component: Publisher_AdUnitAdUnitGroup },
  // { path: "/Publisher_AdUnit", component: Publisher_AdUnit },

  // { path: "/Publisher_Revenue_Report", component: Publisher_Revenue_Report },
  // { path: "/Publisher_Report", component: Publisher_Report },
  // { path: "/PublisherSchedule_Report", component: PublisherScheduleReport },

  // { path: "/Publisher_Invoices", component: Publisher_Invoices },
  // { path: "/Publisher_Payments", component: Publisher_Payments },

  // { path: "/Publisher_Ads_Txt_Queries", component: Publisher_Ads_Txt_Management },
  // { path: "/Publishers_Ads_Txt_Entries", component: Publisher_Ads_Txt_Entries },
  // { path: "/BrandSafety", component: Brand_Safety },
  // { path: "/CMS_Configuration", component: CMS_Configuration },
  // { path: "/Reporting_API", component: Reporting_API },

  // { path: "/Publisher_Support_Tickets", component: Publisher_Support_Tickets },

  // //Media
  // { path: "/Publisher_Video_Content_Library", component: Video_Content_Library_Pub },
  // { path: "/Publisher_Video_Playlists", component: Video_Playlists_Pub },
  // { path: "/Publisher_Video_Import", component: Video_Import_Pub },
  // { path: "/Publisher_Content_Market_Place", component: Content_Market_Place_Pub },

  // { path: "/AdCodeTester", component: AdCodeTester },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/", exact: true, component: () => {
      return userInfo ? <Redirect to="/Dashboard" /> : <Redirect to="/login" />
    }
  },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/Google_login", component: GoogleLoginApp },
  { path: "/passwordHandler", component: ResetPassword },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/session-out", component: SessionOut },
  { path: "/locked-screen", component: LockScreen },
  { path: "/page-maintenance", component: PagesMaintenance },
  { path: "/Approve", component: ApprovePermission },
]

export { authProtectedRoutes, publicRoutes }
