import React, { useState, useEffect, useRef } from "react"
import PropTypes from 'prop-types'
import {
  Button,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Modal,
  Input,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import Select from "react-select";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
  getPartners as onGetPartners,
} from "store/PartnerManagement/actions";

import axios from "axios";

const switchMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const dispatch = useDispatch();
  const nextRef = useRef();
  const agencyRef = useRef();
  const pubRef = useRef();
  const adRef = useRef();
  const sessionRef = useRef();
  const reportRef = useRef();
  const [jsTagModalState, setJsTagModalState] = useState(false);
  const [userModalState, setUserModalState] = useState(false);
  const [agencyModalState, setAgencyModalState] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem('authUser'));

  const [username, setusername] = useState("Admin")
  const userRole = localStorage.getItem('UserRole');
  const parentRole = localStorage.getItem('ParentRole');
  const [partners, setPartners] = useState([])
  const [users, setUsers] = useState([]);
  const [agency, setAgency] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedAgency, setSelectAgency] = useState(null);
  const [partnerData, setPartnerData] = useState([])
  const [usersData, setUsersData] = useState([])
  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
  const adminDetails = JSON.parse(localStorage.getItem('AdminDetails'));
  const adminRolePrivileges = JSON.parse(localStorage.getItem('AdminRolePrivileges'));

  console.log(userRole, parentRole)

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }


  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
    }
  }, [props.success])

  const callUsers = () => {
    setSelectedPartner(null);
    setSelectedUser(null);
    setSelectAgency(null);
    let body = {
      filter: 'Active', partnerType: '', partnerCountry: '', userID: adminDetails ? adminDetails.userID : userInfo.userID,
      userRole: adminDetails ? adminDetails.userRole : userInfo.userRole,
      companyID: adminDetails ? adminDetails.companyID : userInfo.companyID, name: adminDetails ? adminDetails.name : userInfo.name
    }
    axios.post(`/v1.0/partnerListFull?page=${0}&size=${0}`, body,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is partners ==>", res)
        if (res.data.response) {
          res.data.response.partners.map((ele, index) => {
            ele.id = index + 1
          })
          setPartnerData(res.data.response.partners)
          setPartners(objectModal((res.data.response.partners)))
        }
      })
      .catch((err) => {
        console.log("e", err)
      });

    let bodyUsers = {
      filter: 'Active', role: '', userID: adminDetails ? adminDetails.userID : userInfo.userID,
      userRole: adminDetails ? adminDetails.userRole : userInfo.userRole,
      companyID: adminDetails ? adminDetails.companyID : userInfo.companyID, name: adminDetails ? adminDetails.name : userInfo.name
    }
    axios.post(`/v1.0/usersList?page=${0}&size=${0}`, bodyUsers,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is partners ==>", res)
        if (res.data.response) {
          res.data.response.users.map((ele, index) => {
            ele.id = index + 1
          })
          setUsersData(res.data.response.users)
          setUsers(objectModalUsers((res.data.response.users)))
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.partnerID
      obj.label = item.userName
      dataList.push(obj)
    })
    return dataList
  }

  const objectModalUsers = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.userID
      obj.label = item.userName
      dataList.push(obj)
    })
    return dataList
  }

  function handleSelectPartner(selectPartner) {
    console.log(selectPartner)
    setSelectedPartner(selectPartner);
  }

  function handleSelectUser(selectUser) {
    console.log(selectUser)
    setSelectedUser(selectUser);
  }

  function handleSelectAgency(selectAgency) {
    setSelectAgency(selectAgency)
  }

  const navigatePublisher = () => {
    if (selectedPartner) {
      const UserSwitching = localStorage.getItem('UserSwitching');
      partnerData.map((ele, index) => {
        console.log(ele)
        if (ele.partnerID === selectedPartner.value) {
          if (!adminDetails) {
            localStorage.setItem("AdminDetails", JSON.stringify(userInfo));
            localStorage.setItem("AdminRolePrivileges", JSON.stringify(UserRolePrivileges));
            localStorage.setItem("ParentRole", 'Root');
          }
          let userDetails = {
            "companyID": ele.companyID,
            "name": ele.userName,
            "userRole": "Partner",
            "userID": selectedPartner.value,
            "username": ele.userEmail,
            "token": userInfo.token,
            parentID:userInfo.parentID,
            isAccepted: true,
            inventoryType: ele.partnerInvertoryType
          }
          setJsTagModalState(false);
          localStorage.setItem("UserRole", 'Publisher');
          localStorage.setItem("UserSwitching", 'switching');
          localStorage.setItem("authUser", JSON.stringify(userDetails));
          localStorage.setItem("NotificationStatus", 'open');
          localStorage.setItem("InventoryType", ele.partnerInvertoryType);
          let filterBody = {
            userRole: 'Partner',
            userID:selectedPartner.value,
            parentID:userInfo.parentID
          }
          console.log(filterBody)
          axios.post("/v1.0/AMRole/copyRolePrivileges", filterBody,
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": `Bearer ${userInfo.token}`
              }
            })
            .then((res) => {
              console.log("cdsresult is ==>", res)
              if (res.data) {
                console.log(res.data)
                localStorage.setItem("UserRolePrivileges", JSON.stringify(res.data));
                if (document.body) document.body.classList.add('Self-serve')
                pubRef.current.click()
              }
            })
            .catch((err) => {
              console.log("e", err)
            });
        }
      })
    }
  }

  const navigateUser = () => {
    console.log(selectedUser)
    if (selectedUser) {
      usersData.map((ele, index) => {
        if (ele.userID === selectedUser.value) {
          console.log(ele)
          if (!adminDetails) {
            localStorage.setItem("AdminDetails", JSON.stringify(userInfo));
            localStorage.setItem("AdminRolePrivileges", JSON.stringify(UserRolePrivileges));
            localStorage.setItem("ParentRole", 'Root');
          }
          let userDetails = {
            "companyID": ele.companyID,
            "name": ele.userName,
            "userRole": ele.userRole,
            "userID": selectedUser.value,
            "username": ele.userEmail,
            "token": userInfo.token,
            parentID:userInfo.parentID,
            isAccepted: true,
            inventoryType: ele.inventoryType
          }
          setUserModalState(false);
          localStorage.setItem("UserRole", 'User');
          localStorage.setItem("UserSwitching", 'switching');
          localStorage.setItem("authUser", JSON.stringify(userDetails));
          localStorage.setItem("NotificationStatus", 'open');
          localStorage.setItem("InventoryType", ele.inventoryType);
          let filterBody = {
            userRole: ele.userRole,
            userID:selectedPartner.value
          }
          axios.post("/v1.0/AMRole/copyRolePrivileges", filterBody,
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": `Bearer ${userInfo.token}`
              }
            })
            .then((res) => {
              console.log("cdsresult is ==>", res)
              if (res.data) {
                console.log(res.data)
                localStorage.setItem("UserRolePrivileges", JSON.stringify(res.data));
                nextRef.current.click()
              }
            })
            .catch((err) => {
              console.log("e", err)
            });
        }
      })
    }
  }

  const navigateAdOps = () => {
    localStorage.setItem("UserRole", 'AdOps');
    localStorage.setItem("UserSwitching", 'switching');
    adRef.current.click()
  }

  const navigateBSRV = () => {
    localStorage.setItem("UserRole", 'BSRV21');
    localStorage.setItem("UserSwitching", 'switching');
    reportRef.current.click()
  }

  const navigateAdmin = () => {
    localStorage.setItem("UserRole", 'Admin');
    localStorage.setItem("UserSwitching", 'switching');
    console.log(adminDetails)
    if (adminDetails) {
      localStorage.setItem("authUser", JSON.stringify(adminDetails));
      localStorage.setItem("UserRolePrivileges", JSON.stringify(adminRolePrivileges));
      localStorage.setItem("InventoryType", 'both');
    }
    nextRef.current.click()
  }

  const navigateAgency = () => {
    console.log(selectedAgency)
    if (selectedAgency) {
      partnerData.map((ele, index) => {
        if (ele.partnerID === selectedAgency.value) {
          console.log(ele)
          if (!adminDetails) {
            localStorage.setItem("AdminDetails", JSON.stringify(userInfo));
            localStorage.setItem("AdminRolePrivileges", JSON.stringify(UserRolePrivileges));
            localStorage.setItem("ParentRole", 'Root');
          }
          let userDetails = {
            "companyID": ele.companyID,
            "name": ele.userName,
            "userRole": "Partner",
            "userID": ele.partnerID,
            "username": ele.userEmail,
            "token": userInfo.token,
            parentID:userInfo.parentID,
            isAccepted: true,
            partnerCurrencyType:ele.partnerCurrencyType
          }
          setJsTagModalState(false);
          localStorage.setItem("UserRole", 'Agency');
          localStorage.setItem("UserSwitching", 'switching');
          localStorage.setItem("authUser", JSON.stringify(userDetails));
          localStorage.setItem("NotificationStatus", 'open');
          let filterBody = {
            userRole: 'Agency',
            userID:ele.partnerID
          }
          axios.post("/v1.0/AMRole/copyRolePrivileges", filterBody,
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": `Bearer ${userInfo.token}`
              }
            })
            .then((res) => {
              console.log("cdsresult is ==>", res)
              if (res.data) {
                console.log(res.data)
                localStorage.setItem("UserRolePrivileges", JSON.stringify(res.data));
                agencyRef.current.click()
              }
            })
            .catch((err) => {
              console.log("e", err)
            });

        }
      })
    }

  }

  const navigateLockScreen = () => {
    localStorage.setItem("previousNavigation", (window.location.pathname + window.location.search));
    sessionRef.current.click()
  }

  useEffect(() => {
    const agencyFilter = partnerData.filter((partnerTypeFil) => partnerTypeFil.partnerType === "agency");
    setAgency(objectModal((agencyFilter)))

  }, [partnerData])

  return (
    <React.Fragment>

      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
          onClick={callUsers}
        >
          <span className="noti-icon">   <i className="bx bx-shuffle align-middle me-1" /></span>

          <span className=" d-xl-inline-block ms-2 me-1">Switch Account</span>
          <i className="mdi mdi-chevron-down  d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* {userRole === 'Admin' && userInfo.userRole === 'Root' &&
            <DropdownItem onClick={navigateBSRV} >
              <i className="bx bx-user font-size-16 align-middle me-1" />
              Bsrv 2021
            </DropdownItem>
          } */}



          { ((UserRolePrivileges && UserRolePrivileges.isSwitchManagementAccess && UserRolePrivileges.SwitchAccount && UserRolePrivileges.SwitchAccount.isPartnerAccountAccess ) || 
          (adminRolePrivileges && adminRolePrivileges.isSwitchManagementAccess && adminRolePrivileges.SwitchAccount && adminRolePrivileges.SwitchAccount.isPartnerAccountAccess )) &&
            <DropdownItem onClick={() => {
              setJsTagModalState(true);
            }} >
              <i className="bx bx-user font-size-16 align-middle me-1" />
              Partner Accounts
            </DropdownItem>
          }
          {/* {(userRole === 'Admin' || parentRole === 'Root' || (UserRolePrivileges && UserRolePrivileges.isSwitchManagementAccess)) &&
            <DropdownItem onClick={() => {
              setUserModalState(true);
            }} >
              <i className="bx bx-user font-size-16 align-middle me-1" />
              User Accounts
            </DropdownItem>
          } */}

          {userRole === 'BSRV21' &&
            <DropdownItem onClick={navigateAdmin}>
              <i className="bx bx-user font-size-16 align-middle me-1" />
              My Account
            </DropdownItem>
          }
          {userRole === 'Publisher' &&
            <DropdownItem onClick={navigateAdmin}>
              <i className="bx bx-user font-size-16 align-middle me-1" />
              My Account
            </DropdownItem>
          }
          {userRole === 'Agency' &&
            <DropdownItem onClick={navigateAdmin}>
              <i className="bx bx-user font-size-16 align-middle me-1" />
              My Account
            </DropdownItem>
          }
          {userRole === 'User' && parentRole === 'Root' &&
            <DropdownItem onClick={navigateAdmin}>
              <i className="bx bx-user font-size-16 align-middle me-1" />
              My Account
            </DropdownItem>
          }
          {/* {userRole === 'Admin' && userInfo.userRole === 'Root' &&
            <DropdownItem onClick={navigateAdOps}>
              <i className="bx bx-user font-size-16 align-middle me-1" />
              Internal Console
            </DropdownItem>
          } */}
          {userRole === 'AdOps' && parentRole === 'Root' &&
            <DropdownItem onClick={navigateAdmin}>
              <i className="bx bx-user font-size-16 align-middle me-1" />
              My Account
            </DropdownItem>
          }

          {/* mithun */}
          { ((UserRolePrivileges && UserRolePrivileges.isSwitchManagementAccess && UserRolePrivileges.SwitchAccount && UserRolePrivileges.SwitchAccount.isAgencyAccountAccess ) || 
          (adminRolePrivileges && adminRolePrivileges.isSwitchManagementAccess && adminRolePrivileges.SwitchAccount && adminRolePrivileges.SwitchAccount.isAgencyAccountAccess )) &&
            <DropdownItem onClick={() => {
              setAgencyModalState(true);
            }} >
              <i className="bx bx-user font-size-16 align-middle me-1" />
              Agency Accounts
            </DropdownItem>
          }

        </DropdownMenu>
      </Dropdown>
      <Link ref={nextRef} to="/Dashboard"  >
        <span style={{ display: 'none' }}>{props.t("Home")}</span></Link>
      <Link ref={pubRef} to="/Dashboard"  >
        <span style={{ display: 'none' }}>{props.t("Home")}</span></Link>
      <Link ref={adRef} to="/Dashboard"  >
        <span style={{ display: 'none' }}>{props.t("Home")}</span></Link>
      <Link ref={reportRef} to="/Display_Analytics"  >
        <span style={{ display: 'none' }}>{props.t("Display Analytics Report")}</span></Link>
      <Link ref={agencyRef} to="/Dashboard"  >
        <span style={{ display: 'none' }}>{props.t("Home")}</span></Link>
      <Link ref={sessionRef} to="/session-out"></Link>


      <Modal
        size="lg"
        isOpen={jsTagModalState}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Partners
          </h5>
          <button
            type="button"
            onClick={() => {
              setJsTagModalState(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5>Please Select Partner</h5>
          <br />
          <Select
            value={selectedPartner}
            onChange={s => {
              handleSelectPartner(s)
            }}
            options={partners}
            classNamePrefix="select2-selection"
          />
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setJsTagModalState(false);
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <Button
            color="primary"
            id="Popoverdismiss"
            className="font-16 btn-block btn btn-primary"
            onClick={navigatePublisher}
          // onClick={copyTag}
          // onMouseLeave={() => {
          //   setpopoverdismiss(!popoverdismiss)
          // }}
          >
            Publisher Console
          </Button>
        </div>
      </Modal>

      <Modal
        size="lg"
        isOpen={userModalState}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Users
          </h5>
          <button
            type="button"
            onClick={() => {
              setUserModalState(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5>Please Select User</h5>
          <br />
          <Select
            value={selectedUser}
            onChange={s => {
              handleSelectUser(s)
            }}
            options={users}
            classNamePrefix="select2-selection"
          />
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setUserModalState(false);
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <Button
            color="primary"
            id="Popoverdismiss"
            className="font-16 btn-block btn btn-primary"
            onClick={navigateUser}
          // onClick={copyTag}
          // onMouseLeave={() => {
          //   setpopoverdismiss(!popoverdismiss)
          // }}
          >
            Users Console
          </Button>
        </div>
      </Modal>


      <Modal
        size="lg"
        isOpen={agencyModalState}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Agency
          </h5>
          <button
            type="button"
            onClick={() => {
              setAgencyModalState(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5>Please Select Agency</h5>
          <br />
          <Select
            value={selectedAgency}
            onChange={s => {
              handleSelectAgency(s)
            }}
            options={agency}
            classNamePrefix="select2-selection"
          />
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setAgencyModalState(false);
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <Button
            color="primary"
            id="Popoverdismiss"
            className="font-16 btn-block btn btn-primary"
            onClick={navigateAgency}
          // onClick={copyTag}
          // onMouseLeave={() => {
          //   setpopoverdismiss(!popoverdismiss)
          // }}
          >
            Agency Console
          </Button>
        </div>
      </Modal>



    </React.Fragment>
  )
}

switchMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(switchMenu))
)