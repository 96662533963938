/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux";
import {
    Card, CardBody, Col, Form, Input, Label, Row, Spinner, Button, CardTitle, Offcanvas,
    OffcanvasHeader,
    OffcanvasBody, FormFeedback, Progress
} from "reactstrap"

import Select from "react-select";

import moment from "moment";

import preview from '../../../../assets/images/media/preview.png'

// Formik Validation
import * as Yup from "yup";
import { useFormik, yupToFormErrors } from "formik";

import {
    getVideoContents as onGetVideoContent,
} from "store/Media/VideoContent/actions";


import VideoPlayer from "../Video_Content_Library/VideoPlayer";

//redux saga
import {
    createPlaylist as onCreatePlaylist,
    createPlaylistSuccess,
    createPlaylistFail
} from 'store/Media/Playlist/actions'

import {
    updatePlaylist as onUpdatePlaylist,
    updatePlaylistSuccess,
    updatePlaylistFail
} from "store/Media/Playlist/actions";


// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

//react-tooltip 
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { options } from "toastr";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

var videoSelected = []
var selectedVideo = ''
var selectedIndex = []
var selectedItem = []

const Edit_Playlist = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));

    const [loader, setLoader] = useState(false);
    const [playListOtherName, setPlaylistName] = useState("")
    const [isVideoRight, setIsVideoRight] = useState(false);
    const [selectedVideos, setSelectedVideos] = useState([])
    const [activeState, setActiveState] = useState('list')


    const [selected, setSelected] = useState([])
    const [videosData, setVideosData] = useState([])
    const [infochecked, setInfoChecked] = useState(false)
    const [warningChecked, setWarningChecked] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [submitted, setSubmitted] = useState(false);
    const [randomizeSubmit, setRandomizeSubmit] = useState(false)
    const [randomChecked, setRandomChecked] = useState(false)
    const [randomSelected, setRandomSelected] = useState(false)
    const [numList, setNumList] = useState([1, 2, 3, 4, 5])
    const [filteredContentCount, setFilteredContentCount] = useState(0)

    const [category, setCategory] = useState('')
    const [language, setLanguage] = useState('')
    const [time, setTime] = useState(0)
    const [count, setCount] = useState(0)

    console.log(props.type)
    console.log(filteredContentCount, count)
    console.log(filteredContentCount < count)

    const playlistDatas = props.playlistData
    selectedItem = playlistDatas.playListVideoID
    // setSelected(playlistData.playListVideoID)
    // selectedItem = [1,4,7]


    const [playlistData, setPlaylistData] = useState({
        playListID: props.playlistData.playListID,
        playListOtherName: props.playlistData.playListOtherName,
        playListVideoID: props.playlistData.playListVideoID,
        oldPlayListVideoID: [...props.playlistData.playListVideoID],
        playListSettings: props.playlistData.playListSettings,
        filer: "",
        playListThumbNail: props.playlistData.playListThumbNail
    })

    console.log(playlistData)

    const escFunction = useCallback((event) => {
        console.log("called esc function", event.key)
        if (event.key === "Escape") {
            videoSelected = []
            selectedVideo = ''
            selectedIndex = []
            selectedItem = []
            setIsVideoRight(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);


    console.log(selectedItem)

    useEffect(() => {
        setPlaylistData(prevState => ({
            ...prevState,
            playListVideoID: selectedItem
        }))
    }, [selectedItem])

    console.log(playlistData)

    const dispatch = useDispatch()

    const [hoverStateID, setHoverStateID] = useState('');
    const [checkedAll, setCheckedAll] = useState(false);

    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)



    const _handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true);
        console.log(playlistData)
        if (playlistData.playListOtherName &&
            playlistData.playListVideoID) {
            setLoader(true)
            dispatch(onUpdatePlaylist({ ...playlistData }))
            console.log(playlistData)
        }

    }

    const { errorMsg, successMsg } = useSelector(state => ({

        errorMsg: state.playlistsReducer.errorMsg,
        successMsg: state.playlistsReducer.successMsg,
    }));

    console.log(errorMsg)
    console.log(successMsg)



    useEffect(() => {
        if (successMsg !== null) {
            if (successMsg.success) {
                dispatch(updatePlaylistSuccess(""));
                dispatch(updatePlaylistFail(""));
                setLoader(false)
                props.closeCanvas()
                toastr.success("Playlist Updated")
            }
        }
    }, [successMsg]);

    useEffect(() => {
        console.log(errorMsg)
        if (errorMsg !== null && errorMsg !== '') {
            setLoader(false)
            dispatch(updatePlaylistSuccess(""));
            dispatch(updatePlaylistFail(""));
            toastr.error(errorMsg)

        }
    }, [errorMsg]);


    const { videoContents } = useSelector(state => ({
        videoContents: state.videoContents.vcons
    }));
    console.log(videoContents)

    useEffect(() => {
        if (videoContents !== null && videoContents.length > 0) {
            setVideosData(videoContents)
        }
    }, [videoContents.length]);

    useEffect(() => {
        if (videoContents !== null) {
            dispatch(onGetVideoContent({ VideoContentCategory: "", VideoContentLanguage: "" }));
        }
    }, []);

    const selectedVideoData = videoContents
        .filter(item => selectedItem.includes(item.videoContentID))
        .map(item => item.id)

    // Now selectedVideoData contains the matching video data
    // setSelected(selectedVideoData);
    // selectedItem = selectedVideoData
    useEffect(() => {
        setSelected(selectedVideoData)
    }, [videoContents])


    const copyThumbnail = (value) => {
        console.log("this is video content alert============", value)
        window.navigator.clipboard.writeText(value);
    }

    const handleSelectVideos = videos => {
        console.log(videos)
        setSelectedVideos(videos)
    }

    function handleCheckboxChange(name, url, e, thumbnail) {
        if (e.target.checked) {
            setSelectedVideos(selectedVideos.concat([{ name, url, thumbnail }]));
        } else {
            setSelectedVideos(selectedVideos.filter(video => video.name !== name));
        }
    }

    function handleCreatePlaylist() {
        // Do something with the selected videos, e.g. create a playlist
        console.log('Selected videos:', selectedVideos);
    }

    const handleEditState = (id) => (e) => {
        setHoverStateID(id)
    }

    const toggleRightVideoCanvas = (videoData) => (e) => {
        selectedVideo = videoData
        setIsVideoRight(!isVideoRight)
        console.log('called toggleRightVideoCanvas')
    }

    const selectVideo = (id, i) => (e) => {
        console.log(id, e.target.checked)
        console.log(videoSelected.includes(id))
        let videoLists = [...videosData]
        if (e.target.checked) {
            videoSelected.push(id)
            videoLists[i].checked = true
            console.log(videoSelected)
        } else {
            let index = videoSelected.indexOf(id)
            videoSelected.splice(index, 1)
            videoLists[i].checked = false
        }
        setVideosData(videoLists)
    }

    const selectAllVideo = (e) => {
        let videoLists = [...videosData]
        setCheckedAll(e.target.checked)
        videoSelected = []
        if (e.target.checked) {
            videoLists.map((item, index) => {
                videoSelected.push(item.videoContentURL)
                item.checked = true
            })
        } else {
            videoLists.map((item, index) => {
                item.checked = false
            })
        }
        setVideosData(videoLists)
    }

    const handleClose = () => {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
        setRandomChecked(false)
    }

    const listView = () => {
        setActiveState('list')
    }

    const gridView = () => {
        setActiveState('grid')
    }

    const { SearchBar } = Search;


    const videoListCoulumns = [
        // {
        //     text: "ID",
        //     dataField: "id",
        //     sort: true,
        //     // eslint-disable-next-line react/display-name
        //     formatter: user => <>{user.id}</>,
        //   },
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "id",
            sort: true
        },
        {
            text: "vid",
            dataField: "videoContentID",
            sort: true,
        },
        {
            dataField: "videoContentMp4URL",
            isDummyField: true,
            editable: false,
            text: "video",
            align: 'left',
            formatter: (cellContent, eachRow) => (
                eachRow.isUploaded === "Upload is success" ?
                    <img src={eachRow.videoContentOtherThumbnailURL} alt="thumbnail" style={{ height: "3.5rem", width: "6 rem" }} />

                    :
                    <img src={preview} alt="thumbnail" style={{ height: "3.5rem", width: "6 rem" }} />

            )
        },
        {
            text: 'Name',
            dataField: 'videoContentName',
            formatter: (cellContent, eachRow) => {
                if (cellContent.length > 50) {
                    return cellContent.substring(0, 50) + ' . . .';
                }
                return cellContent;
            },
        },
        {
            text: 'Category',
            dataField: 'videoContentCategory'
        },
        {
            text: 'Language',
            dataField: 'videoContentLanguage'
        },
        {
            text: 'Duration',
            dataField: 'videoContentDuration',
            formatter: (cellContent, eachRow) => (
                <span className="mb-2">{moment.unix(eachRow.videoContentDuration).utc().format(' m [min] s [secs]')} </span>
            )
        }, {
            text: "Action",
            dataField: '',
            formatter: (cellContent, eachRow) => (
                <>
                    {eachRow.isUploaded === "Upload is success" &&
                        <div className="d-flex">
                            <div>
                                <Button className="m-3" id={eachRow.videoContentOtherThumbnailURL} onClick={() => copyThumbnail(eachRow.videoContentOtherThumbnailURL)}>thumbnail</Button>
                                <ReactTooltip
                                    anchorId={eachRow.videoContentOtherThumbnailURL}
                                    place='top'
                                    variant='info'
                                    content='Copied thumbnail url'
                                    openOnClick="true"
                                    delayHide={800}
                                />
                            </div>
                            <div>
                                <Button className="m-3" id={eachRow.VideoContentCompressedMp4URL} onClick={() => copyThumbnail(eachRow.VideoContentCompressedMp4URL)}>Video</Button>
                                <ReactTooltip
                                    anchorId={eachRow.VideoContentCompressedMp4URL}
                                    place='top'
                                    variant='info'
                                    content='Copied Video url'
                                    openOnClick="true"
                                    delayHide={800}
                                />
                            </div>
                        </div>
                    }
                    {eachRow.isUploaded === "Upload is not yet start" &&
                        <div>
                            <Progress
                                animated
                                color="info"
                                style={{
                                    height: '15px'
                                }}
                                striped
                                value={100}
                            >In progress</Progress>
                        </div>
                    }
                </>
            ),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    e.stopPropagation()
                    console.log("cliked")
                }
            }
        },
        {
            text: "Status",
            dataField: '',
            formatter: (cellContent, eachRow) => (
                <>
                    {eachRow.isUploaded === "Upload is success" &&
                        <span className="active-state">Ready</span>
                    }
                    {eachRow.isUploaded === "Upload is not yet start" &&
                        <span className="support-ticket-urgent">Pending</span>
                    }
                    {eachRow.isUploaded === "Upload is not success" &&
                        <span className="support-ticket-high">Error</span>
                    }
                </>
            )
        }
    ]

    const selectRow = {
        mode: "checkbox",
        nonSelectable: [],
        selected: selected,
        onSelect: (row, isSelect, rowIndex, e) => {
            console.log(row)
            if (isSelect) {
                setSelected([...selected, row.id])
                setSelectedRowsStatus(true)
                selectedIndex.push(row.id)
                selectedItem.push(row.videoContentID)
            } else {
                setSelected(selected.filter(each => each !== row.id))
                let index = selectedIndex.indexOf(row.id)
                selectedIndex.splice(index, 1)
                let index2 = selectedItem.indexOf(row.videoContentID)
                selectedItem.splice(index2, 1)
                if (selectedIndex.length === 0) {
                    setSelectedRowsStatus(false)
                    setRandomChecked(false)
                }
            }

        },
        onSelectAll: (isSelect, rows, e) => {
            const ids = rows.map(r => r.id);
            if (isSelect) {
                setSelected(ids)
                rows.map((item, index) => {
                    selectedIndex.push(item.id)
                    selectedItem.push(item.videoContentID)
                })
                setSelectedRowsStatus(true)
            } else {
                setSelected([])
                setSelectedRowsStatus(false)
                setRandomChecked(false)
                selectedIndex = []
                selectedItem = []
            }
        }

    };

    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            // order: "asc", // desc or asc
        },
    ];

    const keyField = "id";


    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );

    const pageOptions = {
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        totalSize: videosData.length,
        custom: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };

    const rowEvents = {
        onClick: (e, row) => {
            selectedVideo = row
            setIsVideoRight(!isVideoRight)
        }
    }




    const geranteRandom = (e) => {
        e.preventDefault()
        const videoReadyData = videosData.filter(video => video.isUploaded === "Upload is success")
        const filteredList = videoReadyData.filter((each) => {
            let matchCategory = true;
            let matchLanguage = true;
            let matchDuration = true;

            if (category && each.VideoContentCategory !== category) {
                matchCategory = false;
            }
            if (language && each.VideoContentCategory !== language) {
                matchLanguage = false;
            }
            if (time && each.videoContentDuration / 60 >= time) {
                matchDuration = false;
            }

            return matchCategory && matchLanguage && matchDuration;
        }).sort((a, b) => b.videoContentDuration - a.videoContentDuration);

        console.log(filteredList);
        setVideosData(filteredList);
        setFilteredContentCount(filteredList.length)

        const numItems = Math.min(filteredList.length, count); // get the minimum of 5 or the length of the filtered list
        const randomItems = [];

        setRandomizeSubmit(true)


        for (let i = 0; i < numItems; i++) {
            const randomIndex = Math.floor(Math.random() * filteredList.length);
            const randomItem = filteredList[randomIndex];
            console.log(randomItem.videoContentID)
            if (!randomItems.includes(randomItem.id)) {
                randomItems.push(randomItem.id);
            }
        }
        //creating array with length of video
        const rangeList = Array(filteredList.length).fill().map((_, index) => index);
        setNumList(rangeList)
        // Log the random item ids as a list
        console.log('Randomly selected item ids:');
        console.log(randomItems);
        setSelected([])
        selectedIndex = []

        randomItems.map(each => {
            setSelected(prevState => [...prevState, each])
            selectedIndex.push(each)
        })


        const selectedItems = filteredList.filter(each => randomItems.includes(each.id))
        selectedItem = selectedItems.map(each => each.videoContentID);


        if (randomItems.length !== 0) {
            setSelectedRowsStatus(true)
        } else {
            setSelectedRowsStatus(false)
        }

        console.log(videosData.length, count)


    }
    console.log(selectedItem)

    const fileChangeThumbnail = async (file) => {
        const fileName = file.target.files[0].name;
        const fileType = file.target.files[0].type;


        const toBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

        try {
            const newBaseData = await toBase64(file.target.files[0]);
            console.log(newBaseData)
            let base64URL = ''

            if (newBaseData.includes("data:image/png;base64,")) {
                base64URL = newBaseData.replace("data:image/png;base64,", "");
            }
            else if (newBaseData.includes("data:image/jpeg;base64,")) {
                base64URL = newBaseData.replace("data:image/jpeg;base64,", "");
            } else if (newBaseData.includes("data:image/gif;base64,")) {
                base64URL = newBaseData.replace("data:image/gif;base64,", "");
            }

            console.log(base64URL)
            // Update the playlistData state with the base64 URL
            setPlaylistData({
                ...playlistData,
                playListThumbNail: {
                    type: "manual",
                    url: base64URL,
                },
            });
            console.log(playlistData)


        } catch (error) {
            console.error(error);
        }
    }


    return (
        <>
            <Col md="12">
                {loader &&
                    <Spinner className="ms-2 loader" color="primary" />
                }
                <Form onSubmit={_handleSubmit}>
                    {/* <Form action="https://support-ticket.bidsxchange.com/create" encType="multipart/form-data" method="post"> */}
                    <div style={{ padding: '1% 2%', height: '80vh', overflow: 'auto' }}>
                        <Row >
                            <Col lg="12">
                                {/* <Card className="p-3" >
                                <CardBody> */}
                                <div >
                                    <div>
                                        <div className="mb-3 m-3">
                                            <Label className="form-label">Name</Label>
                                            <Input
                                                type="text"
                                                name='playListOtherName'
                                                placeholder="Playlist name"
                                                value={playlistData.playListOtherName}
                                                className={`form-control col-8 ${(submitted && !playlistData.playListOtherName ? ' is-invalid' : '')}`}
                                                onChange={(e) => setPlaylistData(prevState => ({
                                                    ...prevState,
                                                    playListOtherName: e.target.value
                                                }))}
                                            />
                                            {submitted && !playlistData.playListOtherName ? (
                                                <FormFeedback type="invalid">{'Please Enter Playlist Name'}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="mb-3 m-3">
                                            <Label className="form-label">Playlist Settings</Label>
                                            <Input
                                                name='playlistSettings'
                                                placeholder="Player settings"
                                                value={playlistData.playListSettings}
                                                type="select"
                                                // className={`form-control col-8 select2-selection ${(submitted && playlistData.playListSettings !== [] ? ' is-invalid' : '')}`}
                                                className={`form-control col-8 select2-selection`}

                                                onChange={(e) => setPlaylistData(prevState => ({
                                                    ...prevState,
                                                    playListSettings: e.target.value
                                                }))}

                                            >
                                                <option value=''>Select</option>
                                                <option value='ASC'>A to Z</option>
                                                <option value='DEC'>Z to A</option>
                                                <option value="shuffle">shuffle</option>
                                            </Input>

                                        </div>
                                        <div className=" mb-3 m-3" >

                                            <Label className="form-check-label  m-2 mt-1">Select Thumbnail Type</Label>
                                            <div className="d-flex" >
                                                <div className="form-check">
                                                    <input disabled className="form-check-input" type="radio" onChange={() => {
                                                        setInfoChecked(true); setWarningChecked(false); setPlaylistData({
                                                            ...playlistData,
                                                            playListThumbNail: {
                                                                type: "default",
                                                                url: "",
                                                            },
                                                        });
                                                    }} style={{ backgroundColor: infochecked ? 'green' : '' }} name="flexRadioDefault" id="flexRadioDefault1"  />
                                                    <label className="form-check-label  m-2 mt-1 " style={{ marginRight: "12px" }} htmlFor="flexRadioDefault1">default</label>
                                                </div>
                                                <div className="form-check">
                                                    <input disabled className="form-check-input" type="radio" onChange={() => { setWarningChecked(true); setInfoChecked(false) }}
                                                        name="flexRadioDefault" id="flexRadioDefault2" style={{ backgroundColor: warningChecked ? 'black' : '' }} />
                                                    <label className="form-check-label  m-2 mt-1" htmlFor="flexRadioDefault2" >Manual Thumbnail</label>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            warningChecked &&
                                            <div className="mb-3 m-3">
                                                <Input type="file" accept="image/x-png,image/jpg,image/jpeg" onChange={fileChangeThumbnail} />
                                            </div>
                                        }
                                        <Col md={2} >
                                            <div className="form-check mb-2 m-3">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="random"
                                                    // checked={randomChecked}
                                                    onChange={(e) => setRandomSelected(e.target.checked)}
                                                    id="random"
                                                />
                                                <label
                                                    className="form-check-label  m-2 mt-1"
                                                    htmlFor="random"
                                                >
                                                    Select Randomly
                                                </label>
                                            </div>
                                        </Col>

                                        {randomSelected &&
                                            <Row>
                                                <Col lg={3} disabled>
                                                    <div className="mb-3 m-3" >
                                                        <Label className="form-label">Category</Label>
                                                        <Input name='playlist Category'
                                                            placeholder="Category name"
                                                            className={`form-control col-8 select2-selection `}
                                                            type="select"
                                                            onChange={(e) => setCategory(e.target.value)}
                                                        >
                                                            <option value=''>Select</option>
                                                            <option value='News'>News</option>
                                                            <option value='sports'>Sports</option>
                                                            <option value='Arts & entertainment'>Arts & Entertainment</option>
                                                            <option value='Hospitality'>Hospitality</option>
                                                            <option value='Politics'>Politics</option>
                                                            <option value='Entertainment'>Entertainment</option>
                                                            <option value="Movies">Movies</option>
                                                        </Input>
                                                        {submitted && (language !== '' || category !== '') ? (
                                                            <FormFeedback type="invalid">{'Please Select Playlist Category'}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col lg={3}>
                                                    <div className="mb-3 m-3">
                                                        <Label className="form-label">Language</Label>
                                                        <Input name='playlist Language'
                                                            placeholder="Language"
                                                            className={`form-control col-8 select2-selection `}
                                                            type="select"
                                                            onChange={(e) => setLanguage(e.target.value)}
                                                        >
                                                            <option value=''>Select</option>
                                                            <option value='Tamil'>Tamil</option>
                                                            <option value='te'>te</option>
                                                            <option value='Kannada'>Kannada</option>
                                                            <option value='Marathi'>Marathi</option>
                                                            <option value='Hindi'>Hindi</option>
                                                            <option value='english'>english</option>
                                                            <option value="Telugu">Telugu</option>
                                                            <option value="Bengal">Bengal</option>
                                                            <option value="eng">Eng</option>
                                                        </Input>
                                                        {submitted && (language !== '' || category !== '') ? (
                                                            <FormFeedback type="invalid">{'Please Select Playlist Language'}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col lg={3}>
                                                    <div className="mb-3 m-3">
                                                        <Label >Duration</Label>
                                                        <Input name='time'
                                                            className={`form-control col-8 `}
                                                            type="select"
                                                            onChange={(e) => setTime(e.target.value)}
                                                        >
                                                            <option value=''>Select</option>
                                                            <option value={20}>20 Mins</option>
                                                            <option value={15}>15 Mins</option>
                                                            <option value={10}>10 Mins</option>
                                                            <option value={5}>5 Mins</option>
                                                            <option value={1}>1 Min</option>

                                                        </Input>
                                                    </div>
                                                </Col>
                                                <Col lg={3}>
                                                    <div className="mb-3 m-3">
                                                        <Label >Maximum No of videos</Label>
                                                        <Input type="text" onChange={(e) => setCount(parseInt(e.target.value))} />
                                                        {true ? (
                                                            <FormFeedback type="info">{'* filtered list is less than given number'}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col lg={4} className="d-flex justify-content-left align-items-center">
                                                    <div className=" m-3" >
                                                        <Button type="text" onClick={(e) => geranteRandom(e)}>Randomize</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                        <div className="mb-3 m-3">
                                            <Label>Select Videos</Label>

                                            {/* <Row>
                                            <Col sm="12">
                                                <div className="text-end d-flex justify-content-end" >
                                                    <div style={{ backgroundColor: `${activeState === 'list' ? "#dedede" : "#ffffff"}` }}>
                                                        <i className='bx bx-sm bx-list-ul m-1' onClick={listView} style={{ cursor: "pointer" }}></i>
                                                    </div>
                                                    <div style={{ backgroundColor: `${activeState === 'grid' ? "#dedede" : "#ffffff"}` }}>
                                                        <i className='bx bx-sm bx-grid-alt m-1' onClick={gridView} style={{ cursor: "pointer" }}></i>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row> */}
                                            <div style={{ padding: '0% 0%', height: '80vh' }}>
                                                {activeState === 'grid' &&

                                                    <Row >
                                                        {videoContents !== null &&
                                                            videoContents.map((item, index) => {
                                                                return (
                                                                    <Col sm={4} xxl={3} key={index} >
                                                                        <Card onMouseOver={handleEditState(item.videoContentURL)} onMouseLeave={handleEditState('')} style={{ backgroundColor: (item.checked || hoverStateID === item.videoContentURL) ? '#556ee61f' : '', cursor: 'pointer', boxShadow: (hoverStateID && hoverStateID === item.videoContentURL) ? 'rgb(149 157 165 / 20%) 0px 8px 24px' : 'none' }}>
                                                                            {(item.checked || hoverStateID === item.videoContentURL) &&
                                                                                <div><div className="form-check mt-2 mb-2" style={{ position: 'absolute', left: '1pc', top: '0.5pc', zIndex: '200' }}>
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        id={item.videoContentURL} style={{ width: '1.5pc', height: '1.5pc' }}
                                                                                        checked={item.checked}
                                                                                        onChange={selectVideo(item.videoContentURL, index)}
                                                                                    />
                                                                                </div>
                                                                                </div>
                                                                            }
                                                                            <CardBody className="row justify-content-center " style={{ cursor: 'pointer' }} onClick={''}>
                                                                                <i className="bx bx-play-circle text-center" style={{ position: 'absolute', fontSize: '2rem', color: 'white', zIndex: '200', top: '5vw' }} />
                                                                                <video type="video/mp4" style={{ padding: '12px', borderRadius: '1.2rem', width: '100%' }}
                                                                                    src={item.videoContentURL}   >
                                                                                </video>
                                                                                {/* <video type="video/mp4" style={{padding:'12px',borderRadius:'1.2rem'}}
                                                                                src={item.videoContentURL}   onMouseOver={event => event.target.play()}   onMouseOut={event => event.target.pause()} >
                                                                                </video> */}
                                                                                <h5 className="text-center m-3">{item.videoContentName}</h5>
                                                                                <h6 style={{ fontWeight: 600 }} className="text-center">Category :  <span style={{ fontWeight: 400 }}>{item.VideoContentCategory} </span> &nbsp;&nbsp; |  &nbsp;&nbsp; Language :  <span style={{ fontWeight: 400 }}>{item.VideoContentCategory}</span> </h6>
                                                                                {/* <Row className=" row mt-3">
                                                                    <Col md={6}>
                                                                      <Button
                                                                        color="primary"
                                                                        className="font-18 w-100 btn btn-primary"
                                                                        onClick={() => copyThumbnail(item.videoContentOtherThumbnailURL)}
                                                                      >
                                                                        Thumbnail &nbsp;
                                                                        <i className="mdi mdi-plus-circle-outline me-1" />
                                                                      </Button>
                                                                    </Col >
                                                                    <Col md={6}>
                                                                      <Button
                                                                        color="primary"
                                                                        className="font-18 w-100 btn btn-primary"
                                                                        onClick={() => copyThumbnail(item.videoContentURL)}
                                                                      >
                                                                        Video &nbsp;
                                                                        <i className="mdi mdi-plus-circle-outline me-1" />
                                                                      </Button>
                                                                    </Col>
                                                                  </Row> */}
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Col>
                                                                )
                                                            })}
                                                    </Row>
                                                }
                                                {activeState === 'list' &&
                                                    <Row>
                                                        <Col lg="12">

                                                            <PaginationProvider
                                                                pagination={paginationFactory(pageOptions)}
                                                                keyField={keyField}
                                                                columns={videoListCoulumns}
                                                                data={videosData.filter(video => video.isUploaded === "Upload is success")}
                                                            >
                                                                {({ paginationProps, paginationTableProps }) => {
                                                                    return (
                                                                        <ToolkitProvider
                                                                            keyField={keyField}
                                                                            data={videosData.filter(video => video.isUploaded === "Upload is success")}
                                                                            columns={videoListCoulumns}
                                                                            // bootstrap4
                                                                            search
                                                                        >
                                                                            {toolkitProps => (
                                                                                <React.Fragment>
                                                                                    <Row className="mb-2 m-1">
                                                                                        <Col sm="8" className="pl-0">
                                                                                            <div className="search-box  d-inline-block">
                                                                                                <div className="position-relative">
                                                                                                    <SearchBar {...toolkitProps.searchProps} />
                                                                                                    <i className="bx bx-search-alt search-icon" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col xl="12">
                                                                                            {selectedRowsStatus &&
                                                                                                <div className="row m-0 React-Table-Selection">
                                                                                                    <div style={{ padding: '0.5% 2%' }} className="d-flex justify-content-between align-items-center">

                                                                                                        <span color="inherit" style={{ padding: '1.2% 1%' }}>{selected.length} Selected </span>           &nbsp;  &nbsp;

                                                                                                        <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                                                                                            className="mdi mdi-close-thick font-size-18"
                                                                                                        ></i></Button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        </Col>
                                                                                        <Col xl="12">
                                                                                            <div className="table-responsive">
                                                                                                <BootstrapTable
                                                                                                    keyField={keyField}
                                                                                                    responsive
                                                                                                    bordered={false}
                                                                                                    striped={false}
                                                                                                    defaultSorted={defaultSorted}
                                                                                                    selectRow={selectRow}
                                                                                                    rowEvents={rowEvents}
                                                                                                    classes={
                                                                                                        "table align-middle table-nowrap"
                                                                                                    }
                                                                                                    headerWrapperClasses={"thead-light"}
                                                                                                    {...toolkitProps.baseProps}
                                                                                                    {...paginationTableProps}
                                                                                                    noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                                                // ref={node}
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    {/* <Row className="align-items-md-center mt-30">
                                                                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                                    <PaginationListStandalone
                                                                                    {...paginationProps}
                                                                                    />
                                                                                </Col>
                                                                                </Row> */}
                                                                                    <Row className="align-items-md-center mt-30">
                                                                                        <Col className="inner-custom-pagination d-flex">
                                                                                            <div className="d-inline">
                                                                                                <PaginationTotalStandalone
                                                                                                    {...paginationProps}
                                                                                                />
                                                                                            </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                            <div className="text-md-center d-inline " style={{ marginLeft: '5%' }} >
                                                                                                <span>Show Rows : </span> &nbsp;&nbsp;
                                                                                                <SizePerPageDropdownStandalone
                                                                                                    {...paginationProps}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="text-md-right ms-auto">
                                                                                                <PaginationListStandalone
                                                                                                    {...paginationProps}
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </React.Fragment>
                                                                            )}
                                                                        </ToolkitProvider>
                                                                    );
                                                                }}
                                                            </PaginationProvider>
                                                        </Col>
                                                    </Row>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* </CardBody>
                            </Card> */}
                            </Col>
                        </Row>
                    </div>
                    <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
                        <div className="">
                            <Button type="submit"
                                color="primary"
                                onClick={handleCreatePlaylist}

                                disabled={playlistData.playListVideoID.length === 0}
                            >
                                Update Playlist

                            </Button>
                        </div>
                    </Row>
                </Form>
            </Col>
            <Offcanvas isOpen={isVideoRight} direction='end' className="videoPlayer">
                <OffcanvasHeader toggle={toggleRightVideoCanvas('')} style={{ padding: '0.5rem 1rem' }}>
                    <div className="p-3">
                        <Row className="align-items-center">
                            <Col>
                                <h5 className="m-0"> Video Details </h5>
                            </Col>
                        </Row>
                    </div>
                </OffcanvasHeader>
                <OffcanvasBody style={{ padding: '1rem 0.2rem' }}>
                    <VideoPlayer selectedVideo={selectedVideo} />
                </OffcanvasBody>
            </Offcanvas>
        </>
    )
}

Edit_Playlist.propTypes = {}

export default Edit_Playlist