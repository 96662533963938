/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Spinner, NavItem,
  NavLink,
  TabContent,
  TabPane, InputGroup, Tooltip
} from "reactstrap";
import PropTypes from "prop-types";
import axios from "axios";
import Select from "react-select";
import classnames from "classnames"

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import _ from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

import { MultiSelect } from "react-multi-select-component";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone, PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// actions
import {
  addNewHeaderBid as onADDHBBuyer,
  updateHeaderBid as onUpdateHBBuyer,
  addHeaderBidSuccess,
  addHeaderBidFail,
  updateHeaderBidSuccess,
  updateHeaderBidFail,
} from "store/HeaderBidding/HeaderBid/actions";
import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";
import AssociateAdUnits from "./AssociateAdUnits";

var selectedItem = []
var selectedIndex = []

var lineItemsData = []
var selectedData = ''

// var fORM10F = ''
// var NPE = ''
// var TRC = ''

var country = ''
var countriesList = {}

var cityRetrieved = []
var countriesAll = ["DZ", "AO", "BJ", "BW", "BF", "BI", "CM", "CV", "CF", "TD", "KM", "CG", "CD", "CI", "DJ", "EG", "GQ", "ER", "ET", "GA", "GM", "GH", "GN", "GW", "KE", "LS", "LR", "LY", "MG", "MW", "ML", "MR", "MU", "YT", "MA", "MZ", "NA", "NE", "NG", "RW", "RE", "SH", "SN", "SC", "SL", "SO", "ZA", "SD", "SZ", "ST", "TZ", "TG", "TN", "UG", "EH", "ZM", "ZW", "AI", "AG", "AR", "AW", "BS", "BB", "BZ", "BM", "BO", "BR", "VG", "CA", "KY", "CL", "CO", "CR", "CU", "DM", "DO", "EC", "SV", "FK", "GF", "GL", "GD", "GP", "GT", "GY", "HT", "HN", "JM", "MQ", "MX", "MS", "AN", "NI", "PA", "PY", "PE", "PR", "BL", "KN", "LC", "MF", "PM", "VC", "SR", "TT", "TC", "VI", "US", "UY", "VE", "AF", "AM", "AZ", "BH", "BD", "BT", "BN", "KH", "CN", "GE", "HK", "IN", "ID", "IR", "IQ", "IL", "JP", "JO", "KZ", "KW", "KG", "LA", "LB", "MO", "MY", "MV", "MN", "MM", "NP", "NT", "KP", "OM", "PK", "PS", "YD", "PH", "QA", "SA", "SG", "KR", "LK", "SY", "TW", "TJ", "TH", "TL", "TR", "™", "AE", "UZ", "VN", "YE", "AL", "AD", "BY", "BA", "DD", "FO", "GI", "GG", "IS", "IM", "JE", "LI", "MK", "FX", "MD", "MC", "ME", "NO", "RU", "SM", "RS", "CS", "SJ", "CH", "UA", "SU", "VA", "AX", "AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE", "GB", "AS", "AQ", "AU", "BV", "IO", "CX", "CC", "CK", "FJ", "PF", "TF", "GU", "HM", "KI", "MH", "FM", "NR", "NC", "NZ", "NU", "NF", "MP", "PW", "PG", "PN", "WS", "SB", "GS", "TK", "TO", "TV", "UM", "VU", "WF"]
var creativeBodyInfo = []
var adUnitLists = []

const Create_HBBuyer = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  // const [hbBuyer, setHBBuyer] = useState({
  //   password: ''
  // });
  const [isEdit, setIsEdit] = useState(false);
  const [regionList, setRegionList] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedCountry, setSelectedCountry] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loader, setLoader] = useState(false);

  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [biddersOriginalData, setBiddersOriginalData] = useState([])
  const [biddersData, setBiddersData] = useState([])
  const [selectedBidders, setSelectedBidders] = useState(null);
  const [adUnitData, setAdUnitData] = useState([])
  const [selectedAdUnit, setSelectedAdUnit] = useState([]);
  const [lineItems, setSettingsDatas] = useState([])
  const [associateAdUnitStatus, setAssociatedAdUnitStatus] = useState(false)

  console.log(props)

  const [hbBuyer, setHBBuyer] = useState({
    "hbBuyerName": "",
    "hbBidder": "",
    "hB_SyncWarmupType": "IFrame",
    "hB_SellerJSONURL": "",
    "hB_ConfigMode": "SimpleMode",
    "hbBuyerInfo": {},
    "hbBuyerCountriesIn": [],
    "hbBuyerCountriesEx": [],
    "hbBidderAdjustment": 100,
    "hbBidderTimeout": 1500,
    AdUnitIDS: [],
    hB_AdUnitsAssociatedInfo: [{
      // adUnits: [
      //   {
      //     adUnitID: "",
      //     adUnitName: "",
      //   },
      // ],
      // AssociateAdUnitSettings: [{
      //   "settingsEnabled": false,
      //   "settingInfo": {
      //     "productId": "331133",
      //     "SiteId": "abc.com",
      //     "ZoneID": "1234"
      //   },
      //   "floorPrice": "",
      //   "placement_ID": "",
      //   "size": "",
      //   "settingConfigMode": "SimpleMode"
      // }]
    }]
  });

  const [hbBuyerInfoData, setHbBuyerInfoData] = useState("");

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 2) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name: userInfo.name,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner,
      hbBuyerID: props.selectedHBBuyer.hbBuyerID
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 1000,
    extendedTimeOut: 1000
  };

  useEffect(() => {

    axios.post(userInfo.userRole === 'SupplyPartner' ? "/v2.0/headderBiddingSupplyPartner/HB_Buyers/read/getAllBidder/SupplyPartner" : "/OP/HB_Buyers/read/getAllBidder", body,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is headerBids ==>", res)
        if (res.data.statusCode === 200) {
          setBiddersOriginalData(res.data.response)
          setBiddersData(objectModalBidders(res.data.response))
          if (props.selectedHBBuyer !== '') {
            creativeBodyInfo = []
            creativeBodyInfo.push(props.selectedHBBuyer.hbBuyerInfo)
            res.data.response.map((item, index) => {
              if (props.selectedHBBuyer.hbBidder === item.bidderName) {
                setSelectedBidders({ value: item.bidderID, label: item.bidderName });
                if (item.bidderJSON) {
                  item.bidderJSON.map((ele, index) => {
                    let ParameterName = ele.ParameterName
                    console.log(item, creativeBodyInfo[0], creativeBodyInfo[0][ParameterName])
                    ele.value = creativeBodyInfo[0][ParameterName]
                  })
                  console.log(item.bidderJSON)
                  const result = Object.keys(props.selectedHBBuyer.hbBuyerInfo).map(k => ({ParameterName: k, value: props.selectedHBBuyer.hbBuyerInfo[k],type: 'string', required: false}));
                  let arr3 = item.bidderJSON.concat(result.filter( ({ParameterName}) => !item.bidderJSON.find(f => f.ParameterName == ParameterName) ));
                  console.log(arr3)
                  setVideosList(([...arr3]))
                }
              }
            })
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });


    // body.url = "http://127.0.0.1:2002/v1.0/HB_Buyers/read/Adunit"
    // axios.post("/v1.0/connectOP", body,
    //   {
    //     headers: headers
    //   })
    //   .then((res) => {
    //     console.log("result is headerBids ==>", res)
    //     if (res.data.response) {
    //       // setSettingsDatas(res.data.response)
    //       setAdUnitData(objectModal(res.data.response))
    //       // if (props.selectedHBBuyer !== '') {
    //       //   setHBBuyer(hbBuyer => ({ ...hbBuyer, AdUnitIDSOld: props.selectedHBBuyer.AdUnitIDS ? props.selectedHBBuyer.AdUnitIDS : [] }));
    //       //   let dataList = []
    //       //   res.data.response.map((item, index) => {
    //       //     if (props.selectedHBBuyer.AdUnitIDS && props.selectedHBBuyer.AdUnitIDS.length) {
    //       //       props.selectedHBBuyer.AdUnitIDS.map((ele, index) => {
    //       //         if (ele === item.adUnitID) {
    //       //           let obj = {}
    //       //           obj.value = item.adUnitID
    //       //           obj.label = item.aUnitName
    //       //           dataList.push(obj)
    //       //         }
    //       //       })
    //       //     }
    //       //   })
    //       //   setSelectedAdUnit(dataList)
    //       // }
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("e", err)
    //   });

    // body.url = "http://127.0.0.1:2002/v1.0/HB_Buyers/read/AdunitSelected"
    // axios.post("/v1.0/connectOP", body,
    //   {
    //     headers: headers
    //   })
    //   .then((res) => {
    //     console.log("result is headerBids seleted ==>", res)
    //     if (res.data.response) {
    //       setSettingsDatas(res.data.response)
    //       setAdUnitData(objectModal(res.data.response))
    //       if (props.selectedHBBuyer !== '') {
    //         setHBBuyer(hbBuyer => ({ ...hbBuyer, AdUnitIDSOld: props.selectedHBBuyer.AdUnitIDS ? props.selectedHBBuyer.AdUnitIDS : [] }));
    //         let dataList = []
    //         res.data.response.map((item, index) => {
    //           if (props.selectedHBBuyer.AdUnitIDS && props.selectedHBBuyer.AdUnitIDS.length) {
    //             props.selectedHBBuyer.AdUnitIDS.map((ele, index) => {
    //               if (ele === item.adUnitID) {
    //                 let obj = {}
    //                 obj.value = item.adUnitID
    //                 obj.label = item.aUnitName
    //                 dataList.push(obj)
    //               }
    //             })
    //           }
    //         })
    //         setSelectedAdUnit(dataList)
    //       }
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("e", err)
    //   });

    axios.post("/v2.0/operations/regions/list", body.requestBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is ==>", res)
        if (res.response) {
          countriesList = res.response
          setRegionList([{
            label: 'Africa',
            options: objectRegionModal(res.response.Africa)
          }, {
            label: 'America',
            options: objectRegionModal(res.response.Americas)
          }, {
            label: 'Asia',
            options: objectRegionModal(res.response.Asia)
          }, {
            label: 'Europe',
            options: objectRegionModal(res.response.Europe)
          }, {
            label: 'GDPR',
            options: objectRegionModal(res.response.GDPR)
          }, {
            label: 'Oceania',
            options: objectRegionModal(res.response.Oceania)
          }])
          // countriesList = [...res.data.Africa, ...res.data.Americas, ...res.data.Asia, ...res.data.Europe, ...res.data.GDPR, ...res.data.Oceania]
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }, []);

  useEffect(() => {
    if (props.selectedHBBuyer !== '') {
      setIsEdit(true)
      setHBBuyer(props.selectedHBBuyer)

      // setHBBuyer(hbBuyer => ({
      //   ...hbBuyer, hB_AdUnitsAssociatedInfo: [{
      //     "settingsEnabled": false,
      //     "settingInfo": {
      //       "allow_sizes": "yes",
      //       "allow_domains": "yes",
      //     },
      //     "floorPrice": "",
      //     "placement_ID": "",
      //     "size": "",
      //     "settingConfigMode": "SimpleMode"
      //   },]
      // }))

      setTreeSelect(treeSelect => ({ ...treeSelect, checked: props.selectedHBBuyer.hbBuyerCountriesIn.length > 0 ? props.selectedHBBuyer.hbBuyerCountriesIn : props.selectedHBBuyer.hbBuyerCountriesEx.length > 0 ? props.selectedHBBuyer.hbBuyerCountriesEx : [] }));
    }
  }, []);

  const objectModalBidders = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.bidderID
      obj.label = item.bidderName
      dataList.push(obj)
    })
    return dataList
  }

  const objectRegionModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.label
      obj.label = item.label
      dataList.push(obj)
    })
    return dataList
  }


  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.adUnitID
      obj.label = item.aUnitName
      dataList.push(obj)
    })
    return dataList
  }

  // const handleChange = (e) => {
  //   setSelectedRegion(e.target.value)
  //   hbBuyer.hbBuyerRegion = e.target.value
  //   setRegionList(countriesList[e.target.value])
  // }

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.headerBids.errorMsg,
    successMsg: state.headerBids.successMsg,
  }));

  console.log(errorMsg, successMsg)



  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.statusCode === 200) {
        dispatch(addHeaderBidSuccess(""));
        dispatch(addHeaderBidFail(null));
        dispatch(updateHeaderBidSuccess(""));
        dispatch(updateHeaderBidFail(null));
        setLoader(false)
        if (props.selectedHBBuyer !== '') {
          props.closeCanvas('Update')
        } else {
          props.closeCanvas('Create')
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if (errorMsg.status && errorMsg.status === 500) {
      } else {
        dispatch(addHeaderBidSuccess(""));
        dispatch(addHeaderBidFail(null));
        dispatch(updateHeaderBidSuccess(""));
        dispatch(updateHeaderBidFail(null));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addHeaderBidSuccess(""));
    dispatch(addHeaderBidFail(null));
    dispatch(updateHeaderBidSuccess(""));
    dispatch(updateHeaderBidFail(null));
    props.closeCanvas('')
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    let newKey = name;
    if (newKey === 'hbBuyerRegion') {
      setSelectedRegion(e.target.value)
      setRegionList(countriesList[e.target.value])
    }

    setHBBuyer(hbBuyer => ({ ...hbBuyer, [newKey]: value }));
  }

  const handleChangeInfo = (e) => {
    const { name, value } = e.target;
    console.log(name, value)
    setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerInfo: JSON.parse(value) }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    console.log(hbBuyer)
    let partitionValidation = checkBidderData()
    if (hbBuyer.hbBuyerName && partitionValidation && hbBuyer.hbBidder) {
      console.log("this If condition==============");
      setLoader(true)
      if (props.selectedHBBuyer !== '' ) {
        dispatch(onUpdateHBBuyer({
          ...hbBuyer, hbBuyerID: props.selectedHBBuyer.hbBuyerID,userRole:userInfo.userRole,userID:userInfo.userID ,structure:'old',
        }));
      } else {
        dispatch(onADDHBBuyer({ ...hbBuyer ,userRole:userInfo.userRole,userID:userInfo.userID,structure:'old', }));
      }
    }
  }

  const checkBidderData = () => {
    if (hbBuyer.hbBidderAdjustment && (parseInt(hbBuyer.hbBidderAdjustment) < 1)) {
      toastr.error('Please Enter Bidder Adjustment value 1-100 Range')
      return false
    }
    else if (!hbBuyer.hbBidderAdjustment) {
      toastr.error('Please Enter Bidder Adjustment value 1-100 Range')
      return false
    } else  if (hbBuyer.hbBidderTimeout && (parseInt(hbBuyer.hbBidderTimeout) < 1000)) {
      toastr.error('Please Enter Bidder Timeout greater than 1000 ms')
      return false
    }
    else if (!hbBuyer.hbBidderTimeout) {
      toastr.error('Please Enter Bidder Timeout greater than 1000 ms')
      return false
    }else {
      return true
    }
  }

  function allowAlphaNumericSpace(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault(); }
  };

  function allowOnlyNumbers(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault(); }
  };

  const [videosList, setVideosList] = useState([])
  const addDirectVideoContent = () => {
    let videoContentList = [...videosList]
    videoContentList.push({
      id: videoContentList.length + 1,
      ParameterName: '',
      value: '',
      type: 'string',
      required: false,
    })
    setVideosList([...videoContentList])
  }

  const handleChangeUser = (index) => e => {
    const { name, value } = e.target;
    let videoContentList = [...videosList]
    console.log(videoContentList)
    videoContentList[index] = { ...videoContentList[index], [name]: value };
    setVideosList([...videoContentList])
    // console.log(videoContentList, videoContentList[index], videoContentList[index].ParameterName)
    // const { paramname } = { paramname: videoContentList[index].ParameterName }
    // console.log([paramname], videoContentList[index].value)
    // if(videoContentList[index].type === "integer"){
    //   setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerInfo: { ...hbBuyer.hbBuyerInfo, [paramname]: Number(videoContentList[index].value) } }));
    // }else if(videoContentList[index].type === "boolean"){
    //   setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerInfo: { ...hbBuyer.hbBuyerInfo, [paramname]: JSON.parse(videoContentList[index].value) } }));
    // }else{
    //   setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerInfo: { ...hbBuyer.hbBuyerInfo, [paramname]: videoContentList[index].value } }));
    // }
  }

  const handleChangeValues = (index) => e => {
    const { name, value } = e.target;
    let videoContentList = [...videosList]
    console.log(videoContentList)
    videoContentList[index] = { ...videoContentList[index], [name]: value };
    setVideosList([...videoContentList])
    console.log(videoContentList, videoContentList[index], videoContentList[index].ParameterName)
    const { paramname } = { paramname: videoContentList[index].ParameterName }
    console.log([paramname], videoContentList[index].value)
    if(videoContentList[index].value)
    {
      if(videoContentList[index].type === "integer"){
        setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerInfo: { ...hbBuyer.hbBuyerInfo, [paramname]: Number(videoContentList[index].value) } }));
      }else if(videoContentList[index].type === "boolean"){
        setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerInfo: { ...hbBuyer.hbBuyerInfo, [paramname]: JSON.parse(videoContentList[index].value) } }));
      }else{
        setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerInfo: { ...hbBuyer.hbBuyerInfo, [paramname]: videoContentList[index].value } }));
      }
    }

  }


  const removeDirectContent = (i) => e => {
    let bidderJSON = {}
    let videoContentList = [...videosList]
    console.log(videoContentList)
    videoContentList.splice(i, 1)
    setVideosList([...videoContentList])
    videoContentList.map((ele, index) => {
      let ParameterNameS = ele.ParameterName
      if(ele.value){
        bidderJSON = { ...bidderJSON, [ParameterNameS]: ele.value ? ele.value : "" }
      }
    })
    console.log(bidderJSON,videoContentList)
    setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerInfo: bidderJSON }));
  }

  const handleCheckType = (e) => {
    const { name, value } = e.target;
    setHBBuyer(hbBuyer => ({ ...hbBuyer, hB_ConfigMode: name }))
    // if(name === 'SimpleMode'){
    //   let creativeBodyInfos = []
    //   creativeBodyInfos.push(hbBuyer.hbBuyerInfo)
    //   console.log(hbBuyer.hbBuyerInfo)
    //   biddersOriginalData.map((item, index) => {
    //     if (selectedBidders.label === item.bidderName) {
    //       if (item.bidderJSON) {
    //         item.bidderJSON.map((ele, index) => {
    //           let ParameterNameS1 = ele.ParameterName
    //           ele.value = creativeBodyInfos[0][ParameterNameS1]
    //         })
    //         setVideosList(([...item.bidderJSON]))
    //       }
    //     }
    //   })
    // }
    let bidderJSON = {}
    let JSONData = {}
    if (name === 'RawMode') {
      console.log(biddersOriginalData, hbBuyer.hbBuyerInfo)
      if (hbBuyer.hbBuyerInfo) {
        biddersOriginalData.map((item, index) => {
          if (selectedBidders) {
            if (selectedBidders.label === item.bidderName) {
              if (item.bidderJSON) {
                item.bidderJSON.map((ele, index) => {
                  let ParameterNameS = ele.ParameterName
                  bidderJSON = { ...bidderJSON, [ParameterNameS]: ele.value ? ele.value : "" }
                })
                console.log(bidderJSON)
              }
            }
          } else {
            bidderJSON = {}
          }
        })
        console.log(bidderJSON, hbBuyer.hbBuyerInfo)
        JSONData = { ...bidderJSON, ...hbBuyer.hbBuyerInfo }
        console.log(JSONData)
      } else {
        biddersOriginalData.map((item, index) => {
          if (selectedBidders) {
            if (selectedBidders.label === item.bidderName) {
              if (item.bidderJSON) {
                item.bidderJSON.map((ele, index) => {
                  let ParameterNameS = ele.ParameterName
                  bidderJSON = { ...bidderJSON, [ParameterNameS]: ele.value ? ele.value : "" }
                })
                console.log(bidderJSON)
              }
            }
          }
        })
        JSONData = JSON.stringify(bidderJSON)
      }
      setHbBuyerInfoData(JSON.stringify(JSONData));
      setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerInfo: JSONData }));
    }
    if (name === 'SimpleMode') {
      if (hbBuyer.hbBuyerInfo) {
        creativeBodyInfo = []
        creativeBodyInfo.push(hbBuyer.hbBuyerInfo)
        biddersOriginalData.map((item, index) => {
          if (selectedBidders) {
            if (selectedBidders.label === item.bidderName) {
              if (item.bidderJSON) {
                item.bidderJSON.map((ele, index) => {
                  let ParameterName = ele.ParameterName
                  ele.value = creativeBodyInfo[0][ParameterName]
                })
              }
              console.log(item.bidderJSON)
              setVideosList(([...item.bidderJSON]))
            }
          }
        })
      } else {
        setVideosList([])
        biddersOriginalData.map((item, index) => {
          if (selectedBidders) {
            if (selectedBidders.label === item.bidderName) {
              if (item.bidderJSON) {
                item.bidderJSON.map((ele, index) => {
                  ele.value = ""
                })
                console.log(item.bidderJSON)
                setVideosList(([...item.bidderJSON]))
              }
            }
          }
        })
      }
      setHbBuyerInfoData(JSON.stringify(JSONData));
      setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerInfo: JSONData }));
    }
  }

  const handleCheckTypeChange = (e) => {
    const { name, value } = e.target;
    setHBBuyer(hbBuyer => ({ ...hbBuyer, hB_ConfigMode: name }))
    let bidderJSON = {}
    let JSONData = {}
    if (name === 'RawMode') {
      if (hbBuyer.hbBuyerInfo) {
        biddersOriginalData.map((item, index) => {
          if (selectedBidders) {
            if (selectedBidders.label === item.bidderName) {
              if (item.bidderJSON) {
                item.bidderJSON.map((ele, index) => {
                  let ParameterNameS = ele.ParameterName
                  bidderJSON = { ...bidderJSON, [ParameterNameS]: ele.value ? ele.value : "" }
                })
                console.log(bidderJSON)
              }
            } else {
              bidderJSON = {}
            }
          }
        })
        JSONData = { ...bidderJSON, ...hbBuyer.hbBuyerInfo }
      } else {
        biddersOriginalData.map((item, index) => {
          if (selectedBidders) {
            if (selectedBidders.label === item.bidderName) {
              if (item.bidderJSON) {
                item.bidderJSON.map((ele, index) => {
                  let ParameterNameS = ele.ParameterName
                  bidderJSON = { ...bidderJSON, [ParameterNameS]: ele.value ? ele.value : "" }
                })
                console.log(bidderJSON)
              }
            }
          }
        })
        JSONData = bidderJSON
      }
      setHbBuyerInfoData(JSON.stringify(JSONData));
      setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerInfo: JSON.stringify(JSONData) }));
    }
  }


  const [value, setValue] = useState([]);
  const [cityData, setCityData] = useState([])

  const [countriesState, setCountriesState] = useState(true);
  const [countryData, setCountryData] = useState([]);
  const [selectAllCountry, setSelectAllCountry] = useState(false);

  const [treeSelect, setTreeSelect] = useState({
    checked: [],
    expanded: [],
    keyword: ''
  })

  const [targetStates, setTargetStates] = useState({
    countries: false,
    cities: false,
  })

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const toggleOptions = () => {
    setTreeSelect(treeSelect => ({ ...treeSelect, keyword: '' }));
    setIsOptionsOpen(!isOptionsOpen);
  };

  const myRef = useRef();
  const cityRef = useRef();

  const handleClickOutside = e => {
    // if (!myRef.current.contains(e.target)) {
    //   setIsOptionsOpen(false);
    // }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const [countriesData, setCountriesData] = useState([])


  useEffect(() => {
    axios.post("/v2.0/operations/regions/list", body.requestBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is ==>", res)
        if (res.data) {
          let trees = [
            createTree("Africa", res.data.Africa),
            createTree("America", res.data.Americas),
            createTree("Asia", res.data.Asia),
            createTree("Europe", res.data.Europe),
            createTree("GDPR", res.data.GDPR),
            createTree("Oceania", res.data.Oceania),
          ];
          setCountryData(trees)
          setCountriesData([...res.data.Africa, ...res.data.Americas, ...res.data.Asia, ...res.data.Europe, ...res.data.GDPR, ...res.data.Oceania])
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }, []);

  const getCities = (countries, countryData) => {
    let countryList = []
    var array3 = countryData.filter(function (obj) {
      return countries.indexOf(obj.value) !== -1
    });
    array3.map((item, index) => {
      countryList.push(item.label)
    })
    const bodyCity = {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner,
        "countrylist": countryList
    }
    axios.post("/v2.0/operations/cities/list/countrylist", bodyCity,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is cities ==>", res)
        if (res.data) {
          let trees = []
          let selectedCities = []
          if (res.data.response.length > 0) {
            res.data.response.map((item, i) => {
              trees.push(createCityTree(item.label, item.value))
              selectedCities = [...selectedCities, ...item.value]
              cityRetrieved = selectedCities
            })
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const toggleSelectAllCountries = (e) => {
    setSelectAllCountry(e.target.checked)
    if (e.target.checked) {
      setTreeSelect(treeSelect => ({ ...treeSelect, checked: countriesAll }));
      countriesState ? setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerCountriesIn: cityRetrieved })) : setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerCountriesEx: countriesAll }));
      getCities(countriesAll, countriesData)
      setTargetStates(targetStates => ({
        ...targetStates,
        countries: true,
      }));
    } else {
      cityRetrieved = []
      setTreeSelect(treeSelect => ({ ...treeSelect, checked: [] }));
      countriesState ? setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerCountriesIn: [] })) : setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerCountriesEx: [] }));
    }
  }

  const createTree = (groupName, options) => {
    return {
      label: groupName,
      value: groupName,
      children: options,
    };
  };

  const createCityTree = (groupName, options) => {
    return {
      label: groupName,
      value: groupName,
      children: objectModalCity(options)
    };
  };

  const objectModalCity = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  useEffect(() => {
    if (treeSelect.checked.length === 0) {
      cityRetrieved = []
      setTargetStates(targetStates => ({
        ...targetStates,
        cities: false,
      }));
    } else {
      setTargetStates(targetStates => ({
        ...targetStates, countries: true
      }));
    }
  }, [treeSelect.checked.length])

  const countryStateChange = (e) => {
    setCountriesState(e.target.checked)
    var countriess = []
    if (hbBuyer.hbBuyerCountriesIn.length > 0) {
      countriess = hbBuyer.hbBuyerCountriesIn
    } else {
      countriess = hbBuyer.hbBuyerCountriesEx
    }
    if (e.target.checked) {
      setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerCountriesIn: countriess, hbBuyerCountriesEx: [] }));
    } else {
      setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerCountriesEx: countriess, hbBuyerCountriesIn: [] }));
    }
  }

  const onSearchInputChange = (event, data, searchedNodes) => {
    console.log(event, data, searchedNodes)
    setTreeSelect(treeSelect => {
      if (treeSelect.keyword.trim() && !data.value.trim()) {
        return {
          ...treeSelect,
          expanded: [],
          keyword: data.value
        };
      }
      return {
        ...treeSelect,
        expanded: getAllValuesFromNodes(searchedNodes, true),
        keyword: data.value
      };
    });
  };

  const getAllValuesFromNodes = (nodes, firstLevel) => {
    if (firstLevel) {
      const values = [];
      for (let n of nodes) {
        values.push(n.value);
        if (n.children) {
          values.push(...getAllValuesFromNodes(n.children, false));
        }
      }
      return values;
    } else {
      const values = [];
      for (let n of nodes) {
        values.push(n.value);
        if (n.children) {
          values.push(...getAllValuesFromNodes(n.children, false));
        }
      }
      return values;
    }
  };

  const keywordFilter = (nodes, keyword) => {
    let newNodes = [];
    for (let n of nodes) {
      if (n.children) {
        const nextNodes = keywordFilter(n.children, keyword);
        if (nextNodes.length > 0) {
          n.children = nextNodes;
        } else if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
          n.children = nextNodes.length > 0 ? nextNodes : [];
        }
        if (
          nextNodes.length > 0 ||
          n.label.toLowerCase().includes(keyword.toLowerCase())
        ) {
          n.label = getHighlightText(n.label, keyword);
          newNodes.push(n);
        }
      } else {
        if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
          n.label = getHighlightText(n.label, keyword);
          newNodes.push(n);
        }
      }
    }
    if (newNodes.length === 1) {
      let regions = countryData
      regions.map((item, index) => {
        if (item.value.toLowerCase() === newNodes[0].value.toLowerCase()) {
          newNodes[0].children = item.children
        }
      })
    }
    return newNodes;
  };

  const getHighlightText = (text, keyword) => {
    console.log(text, keyword)
    const startIndex = text.indexOf(keyword);
    return startIndex !== -1 ? (
      <span>
        {text.substring(0, startIndex)}
        <span style={{ color: "red" }}>
          {text.substring(startIndex, startIndex + keyword.length)}
        </span>
        {text.substring(startIndex + keyword.length)}
      </span>
    ) : (
      <span>{text}</span>
    );
  };

  const checkValue = (row, checked) => {
    if (row.isParent) {
      if (row.checked) {
        let finalChecked = [...treeSelect.checked, ...checked]
        finalChecked = finalChecked.filter((item, index) => finalChecked.indexOf(item) === index)
        setTreeSelect(treeSelect => ({ ...treeSelect, checked: finalChecked }));
        countriesState ? setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerCountriesIn: finalChecked })) : setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerCountriesEx: finalChecked }));
        if (finalChecked.length > 0) {
          getCities(finalChecked, countriesData)
        }
      } else {
        let finalChecked = [...treeSelect.checked]
        let filteredChildren = row.children.map(a => a.value);
        let result = finalChecked.filter(val => !filteredChildren.includes(val));
        setTreeSelect(treeSelect => ({ ...treeSelect, checked: result }));
        countriesState ? setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerCountriesIn: result })) : setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerCountriesEx: result }));
        if (result.length > 0) {
          getCities(result, countriesData)
        }
      }
    } else {
      if (row.checked) {
        let finalChecked = [...treeSelect.checked, ...checked]
        finalChecked = finalChecked.filter((item, index) => finalChecked.indexOf(item) === index)
        setTreeSelect(treeSelect => ({ ...treeSelect, checked: finalChecked }));
        countriesState ? setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerCountriesIn: finalChecked })) : setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerCountriesEx: finalChecked }));
        if (finalChecked.length > 0) {
          getCities(finalChecked, countriesData)
        }
      } else {
        let finalChecked = [...treeSelect.checked]
        let index = finalChecked.indexOf(row.value)
        finalChecked.splice(index, 1)
        setTreeSelect(treeSelect => ({ ...treeSelect, checked: finalChecked }));
        countriesState ? setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerCountriesIn: finalChecked })) : setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerCountriesEx: finalChecked }));
        if (finalChecked.length > 0) {
          getCities(finalChecked, countriesData)
        }
      }
    }
  }

  const handleCheck = e => {
    const { name, value } = e.target;
    setTargetStates(targetStates => ({ ...targetStates, [name]: e.target.checked }));
    if (name === 'countries') {
      cityRetrieved = []
      setTreeSelect(treeSelect => ({ ...treeSelect, checked: [] }));
      setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBuyerCountriesEx: [], hbBuyerCountriesIn: [] }));
    }
  }

  const [AMPState, setAMPState] = useState(false)
  const AMPStateChange = e => {
    setAMPState(e.target.checked)
  }

  function handleSelectBidders(selectBidders) {
    setVideosList([])
    console.log(selectBidders)
    biddersOriginalData.map((item, index) => {
      console.log(item, selectBidders.label, item.bidderName)
      if (selectBidders.label === item.bidderName) {
        console.log(item)
        setHBBuyer(hbBuyer => ({ ...hbBuyer, hbBidder: selectBidders.label }));
        if (item.bidderJSON) {
          item.bidderJSON.map((ele, index) => {
            ele.value = ""
          })
          console.log(item.bidderJSON)
          setVideosList(([...item.bidderJSON]))
        }
      }
    })
    setSelectedBidders(selectBidders);
  }

  const handleSelectAdUnit = adUnit => {
    adUnitLists = []
    setSelectedAdUnit(adUnit);
    adUnitLists.push(adUnit.value)
    setHBBuyer(hbBuyer => ({ ...hbBuyer, AdUnitIDS: adUnitLists }));

  }

  function allowOnlyNumbers(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault(); }
  };

  const createContentUI = () => {
    return videosList.map((item, i) =>
      <div key={i}>
        <div className="contentSettings">
          {/* {i === 0 ? <h5 className='mt-3 mb-4'>Current User</h5> : <h5 className='mt-3 mb-4'>User {i}</h5>} */}
          <div className="row mt-1">
            <div className="col-4">
              <div className="mb-3">
                <Input
                  name="ParameterName"
                  onChange={handleChangeUser(i)}
                  value={item.ParameterName}
                  className={'form-control'}
                  placeholder="Enter Key"
                  readOnly={props.selectedArchived}
                />
              </div>
              <br />
            </div>
            <div className="col-6">
              <div className="mb-3">
                {item.type === 'integer' ?
                        <Input
                        name="value"
                        type="text"
                        onChange={handleChangeValues(i)} onKeyPress={allowOnlyNumbers}
                        value={item.value}
                        className={'form-control'}
                        readOnly={props.selectedArchived}
                        placeholder="Enter Value"
                      />  : item.type === 'boolean' ?       
                       <Input
                      name="value"
                      type="select"
                      onChange={handleChangeValues(i)}
                      value={item.value}
                      readOnly={props.selectedArchived}
                      className={'form-control form-select'}
                    >
                      <option value="">Select  </option>
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </Input> : <Input
                    name="value"
                    type="text"
                    onChange={handleChangeValues(i)}
                    value={item.value}
                    readOnly={props.selectedArchived}
                    className={'form-control'}
                    placeholder="Enter Value"
                  />
                }
             
              </div>
              <br />
            </div>
            <div className="col-1 p-0">
            {item.required && 
                <span style={{ color: 'red', fontSize: '15px' }}>*</span>}
            </div>
            <div className="col-1 p-0">
              {!props.selectedArchived && 
                            <Link className="text-danger" to="#" onClick={removeDirectContent(i)} style={{ position: 'absolute', top: '0.5pc', right: '50%' }}  >
                            <i
                              className="bx bx-trash font-size-18"
                              id="edittooltip"
                            ></i>
                          </Link>
              }
              {/* <button type='link' className=' btn-danger  ' style={{ position: 'absolute', background: '#dc3545', right: '0' }} 
                ><i className="bx bx-trash"></i></button> */}
            </div>
          </div>
        </div>
      </div>

    )

  }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const adUnitListColoumns = [
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "AdUnit ID",
      dataField: "adUnitID",
      sort: true,
    },
    {
      text: "AdUnit",
      dataField: "aUnitName",
      sort: true,
    },

    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',

      // eslint-disable-next-line react/display-name
      formatter: (cellContent, adUnit) => (
        <div className="d-flex gap-3">
          <Link to={'#'} onClick={() => { tog_standard(adUnit); }} >
          Edit
          </Link>
        </div>
      ),
    },
  ];

  function tog_standard(adUnit) {
    console.log(adUnit)
    selectedData = adUnit
    setAssociatedAdUnitStatus(true)
  }

  const closeAssocitaePage = () => {
    setAssociatedAdUnitStatus(false)
  }

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );

  const { SearchBar } = Search;
  const pageOptions = {
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    totalSize: lineItems.length,
    custom: true,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };


  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const getSettingsData = async (selectedSettingsData) => {
    console.log(selectedSettingsData)
    setHBBuyer({ ...hbBuyer, ...selectedSettingsData })
  }

  return (
    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
        <Form onSubmit={handleSubmit}  >
          <Row style={{ padding: '3%', height: '85vh', overflow: 'auto', width: '100%' }}>
            <Col xl={12} className="mb-4">
              {associateAdUnitStatus ? <AssociateAdUnits selectedItems={getSettingsData} selectedHBAdUnit={selectedData} /> : <Row >
                <Col lg="12" className="p-0">
                  <Card>
                    <CardBody className="p-0">
                      <div className=" wizard clearfix vertical">
                        <div className="steps clearfix">
                          <ul>
                            <NavItem
                              className={classnames({ current: activeTab === 1 })}
                            >
                              <NavLink
                                className={classnames({ current: activeTab === 1 })}
                                onClick={() => {
                                  setactiveTab(1)
                                }}
                              // disabled={!(passedSteps || []).includes(1)}
                              >
                                <span className="number">01.</span>Basic Settings
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({ current: activeTab === 2 })}
                            >
                              <NavLink
                                className={classnames({ active: activeTab === 2 })}
                                onClick={() => {
                                  setactiveTab(2)
                                }}
                              // disabled={!(passedSteps || []).includes(2)}
                              >
                                <span className="number ">02.</span> Advanced Settings
                              </NavLink>
                            </NavItem>
                            {/* {isEdit &&
                              <NavItem
                                className={classnames({ current: activeTab === 3 })}
                              >
                                <NavLink
                                  className={classnames({ active: activeTab === 3 })}
                                  onClick={() => {
                                    setactiveTab(3)
                                  }}
                                >
                                  <span className="number">03.</span> Associate AdUnits
                                </NavLink>
                              </NavItem>
                            } */}


                          </ul>
                        </div>
                        <div className="content clearfix ">
                          <TabContent activeTab={activeTab} className="body">
                            <TabPane tabId={1}>
                              <div className="col-12">
                                <div className="mb-3">
                                  <Label className="form-label">Name <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                  <Input
                                    name="hbBuyerName"
                                    onChange={handleChange}
                                    value={hbBuyer.hbBuyerName}
                                    maxLength={65}
                                    onKeyPress={allowAlphaNumericSpace}
                                    className={'form-control' + (submitted && !hbBuyer.hbBuyerName ? ' is-invalid' : '')}
                                    readOnly={props.selectedArchived}
                                  />
                                  {submitted && !hbBuyer.hbBuyerName && (
                                    <FormFeedback type="invalid">{'Name is required'}</FormFeedback>
                                  )}
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Bidders <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                  <Select
                                    value={selectedBidders}
                                    onChange={s => {
                                      handleSelectBidders(s)
                                    }}
                                    options={biddersData}
                                    classNamePrefix="select2-selection"
                                    isDisabled={props.selectedArchived || isEdit}
                                  />
                                  {submitted && !hbBuyer.hbBuyerPaymentTerms ?
                                    <FormFeedback  type="invalid" >{'Bidder is required'}</FormFeedback> :null
                                  }
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Sync warmup type <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                  <Input
                                    name="hB_SyncWarmupType"
                                    type="select"
                                    onChange={handleChange}
                                    className={'form-control form-select'}
                                    value={hbBuyer.hB_SyncWarmupType}
                                    readOnly={props.selectedArchived}
                                  >
                                    <option value="">Select Sync warmup type </option>
                                    <option value="IFrame">IFrame</option>
                                    <option value="Script">Script</option>
                                  </Input>
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Sellers.JSON URL</Label>
                                  <Input
                                    name="hB_SellerJSONURL"
                                    type="text"
                                    onChange={handleChange}
                                    className={'form-control'}
                                    value={hbBuyer.hB_SellerJSONURL}
                                    readOnly={props.selectedArchived}
                                  />
                                </div>

                              </div>
                              <div className="mb-3">
                                <Label >Configuration </Label>
                                <div className="row">
                                  <Col md={4}>
                                    <div className="form-check mt-2 mb-2">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="SimpleMode"
                                        id="SimpleMode"
                                        checked={hbBuyer.hB_ConfigMode === 'SimpleMode'}
                                        onChange={handleCheckType}
                                      />
                                      <label className="form-check-label  m-2 mt-1" htmlFor="SimpleMode"> Simple mode  </label>
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="form-check mt-2 mb-2">
                                      <input
                                        className="form-check-input "
                                        type="radio"
                                        name="RawMode"
                                        id="RawMode"
                                        checked={hbBuyer.hB_ConfigMode === 'RawMode'}
                                        onChange={handleCheckType}
                                      />
                                      <label className="form-check-label  m-2 mt-1" htmlFor="RawMode"> Raw mode</label>
                                    </div>
                                  </Col>
                                </div>
                              </div>
                              {hbBuyer.hB_ConfigMode === 'SimpleMode' &&
                                <div className=" mb-3">
                                  {createContentUI()}
                                  <div className='col-12'>
                                    <div className='d-flex'>
                                      <button type='button' className='mt-4 mb-4 btn btn-primary ml-auto' style={{ marginRight: '1rem  2.5rem 1rem auto' }} onClick={addDirectVideoContent} disabled={props.selectedArchived}>Add Parameter &nbsp;&nbsp; <i className="fa fa-plus"></i></button>

                                    </div>
                                  </div>
                                </div>
                              }
                              {hbBuyer.hB_ConfigMode === 'RawMode' &&
                                <div className="mb-3">
                                  <Label className="form-label">JSON : </Label>
                                  <Input
                                    name="hbBuyerInfo"
                                    type="textarea"
                                    rows="7"
                                    onChange={handleChangeInfo}
                                    className={'form-control'}
                                    value={JSON.stringify(hbBuyer.hbBuyerInfo)}
                                    readOnly={props.selectedArchived}
                                  />
                                </div>
                              }
                            </TabPane>
                            <TabPane tabId={2}>
                              <div className="mb-3">
                                <div className="form-group d-flex mt-1">
                                  <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="countries" id='countries' checked={targetStates.countries} onChange={handleCheck} />
                                  <Label className='m-2 mt-1 ' htmlFor='countries'>Country Targeting</Label>
                                </div>
                                {countryData.length > 0 && targetStates.countries &&
                                  <div className="form-group d-flex mt-1">
                                    <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="countriesSelectAll" id='countriesSelectAll' checked={(selectAllCountry && treeSelect.checked.length === 252)} onChange={toggleSelectAllCountries} disabled={props.selectedArchived} />
                                    <Label className='m-2 mt-1 ' htmlFor='countriesSelectAll'>Select All </Label>
                                  </div>
                                }
                                <div className='row checkbox-tree rmsc'>
                                  <div className='col-8' ref={myRef}>

                                    <button
                                      type="button"
                                      aria-haspopup="listbox"
                                      aria-expanded={isOptionsOpen}
                                      className={'selectionButton ' + (isOptionsOpen ? "expanded" : "")}
                                      onClick={toggleOptions}
                                      disabled={props.selectedArchived || !targetStates.countries}
                                    >
                                      {treeSelect.checked && treeSelect.checked.length > 0 ? <span style={{ color: 'black' }}>{treeSelect.checked.length + " Countries are Selected"}</span> : "Please Select Countries"}
                                    </button>
                                    <Input
                                      style={{ display: isOptionsOpen ? 'block' : 'none' }}
                                      placeholder="Search Countries..."
                                      className='mt-1'
                                      value={treeSelect.keyword}
                                      onChange={(event, data) => {
                                        onSearchInputChange(event, event.target, treeSelect.keyword.trim()
                                          ? keywordFilter(_.cloneDeep(countryData), treeSelect.keyword.trim())
                                          : countryData);
                                      }}
                                    />
                                    <ul
                                      className={`options ${isOptionsOpen ? "show" : ""}`}
                                      role="listbox"
                                      tabIndex={-1}
                                    >
                                      <CheckboxTree
                                        nodes={treeSelect.keyword.trim()
                                          ? keywordFilter(_.cloneDeep(countryData), treeSelect.keyword.trim())
                                          : countryData}
                                        checked={treeSelect.checked}
                                        expanded={treeSelect.expanded}
                                        onCheck={(checked, row) => {
                                          checkValue(row, checked)
                                        }}
                                        onExpand={expanded => setTreeSelect(treeSelect => ({ ...treeSelect, expanded: expanded }))}
                                        icons={{
                                          expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                                          expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                                        }}
                                      />
                                    </ul>
                                  </div>
                                  <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="customSwitchsize"
                                      checked={countriesState}
                                      onChange={countryStateChange}
                                      disabled={props.selectedArchived || !targetStates.countries}
                                    />
                                    <label htmlFor="customSwitchsize" className="form-check-label  m-2 mt-1">
                                      {countriesState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                                    </label>
                                  </div>
                                </div>


                              </div>
                              <div className="col-12">


                                {/* <div className="mb-3">
                                <Label >Test Mode : </Label>
                                <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customSwitchsizemd1"
                                    checked={AMPState}
                                    onChange={AMPStateChange}
                                  />
                                  <label className="form-check-label  m-2 mt-1" htmlFor="customSwitchsizemd1">
                                    {AMPState ? <span color='text-success' style={{ color: '#34c38f' }}>On </span> : <span style={{ color: '#a3a3a3' }}>Off</span>}
                                  </label>
                                </div>
                              </div>
                              {AMPState &&
                                <div className="mb-3">
                                  <Label className="form-label">Allow HBMP on</Label>
                                  <InputGroup>
                                    <Input
                                      name="hbBuyerName"
                                      type="text"
                                    />
                                    <div className="input-group-text">% of traffic</div>
                                  </InputGroup>
                                </div>
                              } */}
                                <div className="mb-3">
                                  <Label className="form-label">Bidder Adjustment</Label>
                                  <InputGroup>
                                    <Input
                                      name="hbBidderAdjustment"
                                      type="text"
                                      onChange={handleChange}
                                      className={'form-control'} onKeyPress={allowOnlyNumbers}
                                      value={hbBuyer.hbBidderAdjustment}
                                      readOnly={props.selectedArchived}
                                    />
                                    <div className="input-group-text">%</div>
                                  </InputGroup>
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Bidder Timeout</Label>
                                  <InputGroup>
                                    <Input
                                      name="hbBidderTimeout"
                                      type="text"
                                      onChange={handleChange}
                                      className={'form-control'} onKeyPress={allowOnlyNumbers}
                                      value={hbBuyer.hbBidderTimeout}
                                      readOnly={props.selectedArchived}
                                    />
                                    <div className="input-group-text">ms</div>
                                  </InputGroup>
                                </div>
                              </div>
                            </TabPane>
                            <TabPane tabId={3}>
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="mb-3">
                                      <Label className="form-label">Associate AdUnits </Label>
                                      {/* <MultiSelect
                                    options={adUnitData}
                                    value={selectedAdUnit}
                                    onChange={handleSelectAdUnit}
                                    overrideStrings={{ "selectSomeItems": "Please Select Ad Units" }}
                                  /> */}
                                      <Select
                                        value={selectedAdUnit}
                                        onChange={s => {
                                          handleSelectAdUnit(s)
                                        }}
                                        options={adUnitData}
                                        classNamePrefix="select2-selection"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary mt-4"
                                      onClick={tog_standard}
                                    >
                                      Associate AdUnits
                                    </button>
                                  </div>
                                </div>

                                {/* <Label className="form-label">Edit Associated AdUnits </Label>
                                <Card>
                                  <CardBody>
                                    <PaginationProvider
                                      pagination={paginationFactory(pageOptions)}
                                      keyField={keyField}
                                      columns={adUnitListColoumns}
                                      data={lineItems}
                                    >
                                      {({ paginationProps, paginationTableProps }) => {
                                        return (
                                          <ToolkitProvider
                                            keyField={keyField}
                                            data={lineItems}
                                            columns={adUnitListColoumns}
                                            // bootstrap4
                                            search
                                          >
                                            {toolkitProps => (
                                              <React.Fragment>
                                                <Row className="mb-2">
                                                  <Col sm="3">
                                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                                      <div className="position-relative">
                                                        <SearchBar {...toolkitProps.searchProps} />
                                                        <i className="bx bx-search-alt search-icon" />
                                                      </div>
                                                    </div>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col xl="12">
                                                    <div className="table-responsive">
                                                      <BootstrapTable
                                                        keyField={keyField}
                                                        responsive
                                                        bordered={false}
                                                        striped={false}
                                                        defaultSorted={defaultSorted}
                                                        // selectRow={selectRow}
                                                        classes={
                                                          "table align-middle table-nowrap"
                                                        }
                                                        headerWrapperClasses={"thead-light"}
                                                        {...toolkitProps.baseProps}
                                                        {...paginationTableProps}
                                                        noDataIndication={<h5 className="text-center">No Data</h5>}
                                                      // ref={node}
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>
                                                <Row className="align-items-md-center mt-30">
                                                  <Col className="inner-custom-pagination d-flex">
                                                    <div className="d-inline">
                                                      <PaginationTotalStandalone
                                                        {...paginationProps}
                                                      />
                                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <div className="text-md-center d-inline " style={{ marginLeft: '5%' }} >
                                                      <span>Show Rows : </span> &nbsp;&nbsp;
                                                      <SizePerPageDropdownStandalone
                                                        {...paginationProps}
                                                      />
                                                    </div>
                                                    <div className="text-md-right ms-auto">
                                                      <PaginationListStandalone
                                                        {...paginationProps}
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </React.Fragment>
                                            )}
                                          </ToolkitProvider>
                                        );
                                      }}
                                    </PaginationProvider>
                                  </CardBody>
                                </Card> */}
                              </div>
                            </TabPane>

                          </TabContent>
                        </div>
                        <div className="actions clearfix">
                          <ul>
                            <li
                              className={
                                activeTab === 1 ? "previous disabled" : "previous"
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab - 1)
                                }}
                              >
                                Previous
                              </Link>
                            </li>
                            <li
                              className={activeTab === 2 ? "next disabled" : "next"}
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab + 1)
                                }}
                              >
                                Next
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              }

            </Col>
          </Row>
          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">


                {isEdit && !props.selectedArchived &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }

                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={associateAdUnitStatus ? closeAssocitaePage : props.closeCanvas}
                >
                  Back
                </button>

              </div>
            </Col>
          </Row>
        </Form>
        {
          errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
          <Toaster status="error" msg={errorMsg.message} />
        }
      </div >
      {errorMsg !== null && errorMsg !== '' && errorMsg.status && errorMsg.status === 500 &&
        <div className="p-0 justify-content-center d-flex">
          <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')} />
        </div>
      }

    </React.Fragment >
  )
}

Create_HBBuyer.propTypes = {}

export default Create_HBBuyer