import React, { useEffect, useState, useRef , useCallback} from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Input,
  Spinner,
  Label,
  BreadcrumbItem
} from "reactstrap"

import DateRangePickers from '../../../helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import {
  getPub_Notifications as onGetPub_Notifications,
  statusPub_Notification as onStatusPub_Notification,
  archivePub_Notification as onArchivePub_Notification,
  statusPub_NotificationSuccess,
  archivePub_NotificationSuccess,
  addPub_NotificationFail,
  updatePub_NotificationFail,
} from "store/Notifications/Publisher_Notification/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import New_Product_Notification from "./New_Product_Notification";
import AdminSidebar from "../Admin_Sidebar/AdminSidebar";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var loader = false

const Product_Notification = props => {

  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const [statusSearch, setStatusSearch] = useState('Active')
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  let userLogs = JSON.parse(localStorage.getItem('userLogs'));
  const [isArchived, setIsArchived] = useState(false)
  const [selected, setSelected] = useState([])

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const changeDatePick = (event) => {
    console.log(event)
    console.log("start: ", event.startDate._d);
    console.log("end: ", event.endDate._d);
    setFromDate(event.startDate._d.toISOString());
    setToDate(event.endDate._d.toISOString());
  }
  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setIsRight(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  toastr.options = {
    positionClass: "toast-top-right",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 1000,
    extendedTimeOut: 1000
  };

  useEffect(() => {
    loader = true
    dispatch(onGetPub_Notifications(null));
  }, []);

  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.pubNotificationID)
        selectedItem.push(row.pubNotificationID)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.pubNotificationID)
        selectedIndex.splice(index, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.pubNotificationID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
      }
    }
  };

  const statusChange = (e) => {
    setStatusSearch(e.target.value)
    dispatch(onGetPub_Notifications({ filter: e.target.value,userRole: userInfo.userRole }));
  }

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
  }

  const { pubNotifications } = useSelector(state => ({
    pubNotifications: state.pub_notifications.nots,
  }));

  console.log(pubNotifications)

  const { pubNotificationsResponse } = useSelector(state => ({
    pubNotificationsResponse: state.pub_notifications.response,
  }));

  const { archivePub_NotificationsResponse } = useSelector(state => ({
    archivePub_NotificationsResponse: state.pub_notifications.archiveMsg,
  }));

  // const { errorMsg, successMsg } = useSelector(state => ({
  //   errorMsg: state.pubNotifications.errorMsg,
  //   successMsg: state.pubNotifications.successMsg,
  // }));

  console.log(pubNotificationsResponse)
  useEffect(() => {
    if (pubNotificationsResponse !== null) {
      if (pubNotificationsResponse.success) {
        toastr.success('Pub_Notification Status Changed Successfully')
        dispatch(onGetPub_Notifications({ filter: statusSearch,userRole: userInfo.userRole }));
        dispatch(statusPub_NotificationSuccess(null));
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        userLogs.push({
          userID: userInfo.userID, userName: userInfo.username, userRole: userInfo.userRole, eventName: 'Pub_Notification Status Change',
          eventTime: new Date(), eventResult: 'Success'
        })
        localStorage.setItem("userLogs", JSON.stringify(userLogs));
      }
    }
  }, [dispatch, pubNotificationsResponse]);

  useEffect(() => {
    if (archivePub_NotificationsResponse !== null) {
      if (archivePub_NotificationsResponse.success) {
        toastr.success('Pub_Notification Archived Successfully')
        dispatch(onGetPub_Notifications({ filter: statusSearch,userRole: userInfo.userRole }));
        dispatch(archivePub_NotificationSuccess(null));
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        userLogs.push({
          userID: userInfo.userID, userName: userInfo.username, userRole: userInfo.userRole, eventName: 'Pub_Notification Archived',
          eventTime: new Date(), eventResult: 'Success'
        })
        localStorage.setItem("userLogs", JSON.stringify(userLogs));
      }
    }
  }, [dispatch, archivePub_NotificationsResponse]);

  const toggleRightCanvas = () => {
    selectedData = ''
    dispatch(addPub_NotificationFail(""));
    dispatch(updatePub_NotificationFail(""));
    dispatch(onGetPub_Notifications({ filter: statusSearch,userRole: userInfo.userRole }));
    setIsRight(!isRight);
  };

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, pubNotification) => (event) => {
    console.log(event)
    if (event === 'Create') {
      toastr.success('Pub_Notification Saved Successfully!...')
      userLogs.push({
        userID: userInfo.userID, userName: userInfo.username, userRole: userInfo.userRole, eventName: 'Pub_Notification Create',
        eventTime: new Date(), eventResult: 'Success'
      })
      localStorage.setItem("userLogs", JSON.stringify(userLogs));
    }
    if (event === 'Update') {
      toastr.success('Pub_Notification Updated Successfully!...')
      userLogs.push({
        userID: userInfo.userID, userName: userInfo.username, userRole: userInfo.userRole, eventName: 'Pub_Notification Update',
        eventTime: new Date(), eventResult: 'Success'
      })
      localStorage.setItem("userLogs", JSON.stringify(userLogs));
    }
    selectedData = pubNotification
    setIsArchived(pubNotification.isPub_NotificationArchived)
    dispatch(onGetPub_Notifications({ filter: statusSearch,userRole: userInfo.userRole }));
    dispatch(addPub_NotificationFail(""));
    dispatch(updatePub_NotificationFail(""));
    setIsRight(close);
  };

  useEffect(() => {
    if (pubNotifications !== null) {
      loader = true
      dispatch(onGetPub_Notifications({ filter: statusSearch,userRole: userInfo.userRole }));
    }
  }, []);

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const pubNotificationListColumns = [
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "NotificationID",
      sort: true,
      formatter: (cellContent, pubNotification) => (
        <>
          <span>{pubNotification.NotificationID}</span>
        </>
      ),
    },
    {
      dataField: "NotificationCreateTime",
      text: "Creation Date",
      sort: true,
    },

    {
      text: "Notification Name",
      dataField: "NotificationName",
      sort: true,
    },
    {
      text: "Notification Data",
      dataField: "NotificationData",
      sort: true,
    },
    {
      dataField: "NotificationStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, pubNotification) => (
        <>
          {pubNotification.NotificationStatus && !pubNotification.isNotificationArchived &&
            <span className="active-state">Active</span>
          }
          {!pubNotification.NotificationStatus && !pubNotification.isNotificationArchived &&
            <span className="inactive-state">Inactive</span>
          }
          {pubNotification.isNotificationArchived &&
            <span className="archived-state">Archived</span>
          }
        </>

      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',

      // eslint-disable-next-line react/display-name
      formatter: (cellContent, pubNotification) => (
        <div className="d-flex gap-3">
          <Link className="Email-state " to="#" onClick={toggleDrawer(true, pubNotification)}>
            {pubNotification.isNotificationArchived &&
       'View'
            }
            {!pubNotification.isNotificationArchived &&
                          'Edit'
            }
          </Link>
        </div>
      ),
    },
  ];

  const { SearchBar } = Search;
  const sizePerPage = 10;
  const pageOptions = {
    sizePerPage: 10,
    totalSize: pubNotifications.length, // replace later with size(pubNotifications),
    custom: true,
  };
  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const archivePub_Notification = async () => {
    dispatch(onArchivePub_Notification({ notificationIDList: selectedItem ,userRole: userInfo.userRole}));
  }

  const statusPub_Notification = (state) => (event) => {
    console.log(state)
    dispatch(onStatusPub_Notification({ notificationIDList: selectedItem, pubNotificationStatus: state,userRole: userInfo.userRole }));
  }

  console.log(pubNotifications)

  if (pubNotifications.length > 0) {
    loader = false
    pubNotifications.map((item, index) => {
      if (item.isPub_NotificationArchived) {
        selectRow.nonSelectable.push(index + 1)
      }
    })
  } else {
    loader = false
  }

  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
         {/* <AdminSidebar />
      <div className="main-content access-management"> */}
        <div className="page-content">
          <Helmet>
            <title>Product Notification | Bidsxchange</title>
          </Helmet>
          {loader &&
            <Spinner className="ms-2 loader" color="primary" />
          }
          <Container fluid style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
            {/* Render Breadcrumbs */}

            <div className=" d-sm-flex  justify-content-between">
            <h4 className="mb-2 font-size-18 text-uppercase">  <Link to="/Admin"><i className="mdi mdi-arrow-left-circle"></i></Link> &nbsp;&nbsp;&nbsp; Product Notification</h4>
            <ol className="breadcrumb m-0 mb-2 p-0">
            <div className='d-flex'>
                <BreadcrumbItem>
                  <Link to="/Dashboard" className="color-dark-blue signUp">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to="/Admin" className="color-dark-blue signUp">Admin Settings</Link>
                </BreadcrumbItem>
              </div>
            </ol>
              <div className=" text-sm-end">
                <Button
                  color="primary"
                  className="font-16 btn-block btn btn-primary"
                  onClick={toggleRightCanvas}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Add Notification
                </Button>
              </div>
            </div>
            <Card>
              <CardBody aria-hidden="true">
                <Row >
                  <Col sm="2">
                    <Label className="form-label">Status</Label> <br />
                    <div className=" text-sm-end">
                      <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                        <option value=''> All</option>
                        <option value='Active' > Active</option>
                        <option value='Inactive'> InActive</option>
                        <option value='Archived'> Archived</option>
                      </Input>
                    </div>
                  </Col>
                  <Col sm="4" className="date-range">
                    <Label className="form-label">Date Range</Label> <br />
                    <DateRangePickers datePick={changeDatePick} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {loader &&
              <Spinner className="ms-2 loader" color="primary" />
            }
            {!loader &&
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody aria-hidden="true">
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField={keyField}
                        columns={pubNotificationListColumns}
                        data={pubNotifications}
                      >
                        {({ paginationProps, paginationTableProps }) => {
                          return (
                            <ToolkitProvider
                              keyField={keyField}
                              data={pubNotifications}
                              columns={pubNotificationListColumns}
                              // bootstrap4
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>

                                  <Row className="mb-2">
                                    <Col sm="4">
                                      <div className="search-box ms-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar {...toolkitProps.searchProps} />
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xl="12">
                                      {selectedRowsStatus && selectedIndex.length > 0 &&
                                        <div className="row m-0 React-Table-Selection">
                                        {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                        <div style={{ padding: '0.5% 2%' }}>

                                            {/* <button color="primary" className="btn btn-primary " onClick={statusPub_Notification(true)}>Activate</button> */}
                                            <button color="primary" className="btn btn-primary " onClick={statusPub_Notification(true)}>
                                              Activate
                                            </button>
                                            &nbsp;  &nbsp;
                                            <button color="primary" className="btn btn-primary " onClick={statusPub_Notification(false)}>
                                              DeActivate
                                            </button>
                                            &nbsp;  &nbsp;
                                            <Button color="primary" className="btn btn-primary " onClick={archivePub_Notification} >
                                              Archive
                                            </Button>
                                            <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                              className="mdi mdi-close-thick font-size-18"
                                            ></i></Button>
                                          </div>
                                        </div>
                                      }
                                    </Col>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={keyField}
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          selectRow={selectRow}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                          noDataIndication={<h5 className="text-center">No Data</h5>}
                                        // ref={node}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  {/* <Row className="align-items-md-center mt-30">
                                        <Col className="pagination pagination-rounded justify-content-end mb-2">
                                            <PaginationListStandalone
                                            {...paginationProps}
                                            />
                                        </Col>
                                        </Row> */}
                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          );
                        }}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            }
          </Container>
        </div>
        {/* </div> */}
      {/* </div> */}
      
      <Offcanvas isOpen={isRight} direction='end'  >
        <OffcanvasHeader toggle={toggleRightCanvas}>
          {selectedData ? 'Edit Notification' : 'Add Notification'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <New_Product_Notification closeCanvas={toggleDrawer(false, '')} selectedPub_Notification={selectedData} selectedArchived={isArchived} />
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment >
  )
}

 Product_Notification.propTypes = {}

export default Product_Notification
