import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";

import classNames from "classnames";
import axios from "axios";

import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";

//import Charts
import StackedColumnChart from "pages/Dashboard/StackedColumnChart";

//import action
import { getChartsData as onGetChartsData } from "store/actions";


// Pages Components
import WelcomeComp from "pages/Dashboard/WelcomeComp";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DateRangePickers from 'helpers/DateRangePicker'

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import moment from "moment";
import MarketStackColoumn from "./MarketStackColoumn";
import LoadingDashboardCard from "pages/LoaderPages/LoadingDashboardCard";
import LoaderChart from "pages/LoaderPages/LoaderChart";
import DateRangePickerDashboard from "helpers/DateRangePickerDashboard";

var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 3 : 2

var fromDate = moment().subtract(estCurrentDate > 2 ? 8 : 7, 'days')
var toDate = moment().subtract(estCurrentDate > 2 ? 2 : 1, 'days')
var dateFromDate = moment().subtract(estCurrentDate > 2 ? 8 : 7, 'days')
var dateToDate = moment().subtract(estCurrentDate > 2 ? 2 : 1, 'days')

var xAxis = []
var stdbXAxis = []
var highImpactXAxis = []
var videoXAxis = []

const MarketDashboard = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);

  const [chartData, setChartData] = useState([]);
  const [stdbPeriodData, setStdbPeriodData] = useState([]);
  const [highImpactPeriodData, setHighImpactPeriodData] = useState([]);
  const [videoPeriodData, setVideoPeriodData] = useState([]);

  const [periodType, setPeriodType] = useState("day");
  const [stdbPeriodType, setStdbPeriodType] = useState("day");
  const [highImpactPeriodType, setHighImpactPeriodType] = useState("day");
  const [videoPeriodType, setVideoPeriodType] = useState("day");

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };


  const [reportData, setReportData] = useState([
    { title: "Total Code Served", iconClass: "bx-bar-chart", description: "0", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Paid Impressions", iconClass: "bx-bar-chart", description: "0", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Revenue", iconClass: "bx-dollar-circle", description: "$0", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Estimated eCPM", iconClass: "bx-dollar-circle", description: "$0", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Estimated Fill Rate", iconClass: "bx-show-alt", description: "0%", yesterdayRate: '0%', isIncreased: 'zero' },
  ])
  const [inventoryType, setInventoryType] = useState('')
  const [adUnitData, setAdUnitData] = useState([]);
  const [highImpactAdUnitData, setHighImpactAdUnitData] = useState([]);
  const [videoAdUnitData, setVideoAdUnitData] = useState([]);
  const [selectedAdUnit, setSelectedAdUnit] = useState([])
  const [selectedHighImpactAdUnit, setSelectedHighImpactAdUnit] = useState([])
  const [selectedVideoAdUnit, setSelectedVideoAdUnit] = useState([])
  const [userInventoryState, setUserInventoryState] = useState('both')

  const [switchLoader, setSwitchLoader] = useState(true)
  const [loading, setLoading] = useState(false)

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  useEffect(() => {
    console.log(userInfo)
    fromDate = moment().subtract(estCurrentDate > 2 ? 8 : 7, 'days')
    toDate = moment().subtract(estCurrentDate > 2 ? 2 : 1, 'days')
    dateFromDate = moment().subtract(estCurrentDate > 2 ? 8 : 7, 'days')
    dateToDate = moment().subtract(estCurrentDate > 2 ? 2 : 1, 'days')
    setUserInventoryState(userInfo.inventoryType)
    // const body = {
    //   "url": "/v2.0/re/Query/Revenue/getInventoryType",
    //   "requestBody": {
    //     userID: userInfo.userID,
    //     userRole: userInfo.userRole,
    //     companyID: userInfo.companyID,
    //     name: userInfo.name,
    //   }
    // }
    // axios.post("/v1.0/connectOP", body,
    //   {
    //     headers: headers
    //   })
    //   .then((res) => {
    //     console.log("result is dashBoardData ==>", res)
    //     if (res.response) {

    //     }
    //   })
    //   .catch((err) => {
    //     console.log("e", err)
    //   });
    getDashboardData('')
  }, []);


  const changeInventory = (e) => {
    setInventoryType(e.target.value)
    getDashboardData(e.target.value)
  }

  const getDashboardData = (inventory) => {
    setLoading(true)
    const body = {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        "startDate": moment(fromDate).format("YYYY-MM-DD"),
        "endDate": moment(toDate).format("YYYY-MM-DD"),
        inventoryType: inventory,
        name: userInfo.name,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
      }
    axios.post(userInfo.userRole === 'SupplyPartner' ? "/v2.0/reportingSupplyPartner/Query/Revenue/DashBoards" : "/v2.0/reporting/Query/Revenue/DashBoards", body,
      {
        headers: headers
      })
      .then((res) => {
        console.log(res)
        setLoading(false)
        if (res.data) {
          if (res.data){
            if (res.data.response.PreviousDayTotalCodeServed) {
              let prevCompTotalCodeServed = res.data.response.totalCodeServed === 0 ? 0 : (100 * ((res.data.response.totalCodeServed - res.data.response.PreviousDayTotalCodeServed) / res.data.response.PreviousDayTotalCodeServed))
              let prevCompEstimatedImpressions = res.data.response.estimatedImpressions === 0 ? 0 : (100 * ((res.data.response.estimatedImpressions - res.data.response.PreviousDayEstimatedImpressions) / res.data.response.PreviousDayEstimatedImpressions))
              let prevCompEstimatedRevenue = res.data.response.estimatedRevenue === 0 ? 0 : (100 * ((res.data.response.estimatedRevenue - res.data.response.PreviousDayEstimatedRevenue) / res.data.response.PreviousDayEstimatedRevenue))
              let prevCompEstimatedeCPM = res.data.response.estimatedeCPM === 0 ? 0 : (100 * ((res.data.response.estimatedeCPM - res.data.response.PreviousDayEstimatedeCPM) / res.data.response.PreviousDayEstimatedeCPM))
              let prevCompEstimatedFillRate = res.data.response.estimatedFillRate === 0 ? 0 : (100 * ((res.data.response.estimatedFillRate - res.data.response.PreviousDayEstimatedFillRate) / res.data.response.PreviousDayEstimatedFillRate))
  
              setReportData([
                { title: "Total Code Served", iconClass: "bx-bar-chart", description: (res.data.response.totalCodeServed).toLocaleString(), yesterdayRate: (prevCompTotalCodeServed.toFixed(2) < 0 ? prevCompTotalCodeServed.toFixed(2) * -1 : prevCompTotalCodeServed.toFixed(2)) + "%", isIncreased: prevCompTotalCodeServed > 0 ? 'yes' : prevCompTotalCodeServed < 0 ? 'no' : 'zero' },
                { title: "Paid Impressions", iconClass: "bx-bar-chart", description: (res.data.response.estimatedImpressions).toLocaleString(), yesterdayRate: (prevCompEstimatedImpressions.toFixed(2) < 0 ? prevCompEstimatedImpressions.toFixed(2) * -1 : prevCompEstimatedImpressions.toFixed(2)) + "%", isIncreased: prevCompEstimatedImpressions > 0 ? 'yes' : prevCompEstimatedImpressions < 0 ? 'no' : 'zero' },
                { title: "Revenue", iconClass: "bx-dollar-circle", description: "$" + (res.data.response.estimatedRevenue).toLocaleString(), yesterdayRate: (prevCompEstimatedRevenue.toFixed(2) < 0 ? prevCompEstimatedRevenue.toFixed(2) * -1 : prevCompEstimatedRevenue.toFixed(2)) + "%", isIncreased: prevCompEstimatedRevenue > 0 ? 'yes' : prevCompEstimatedRevenue < 0 ? 'no' : 'zero' },
                { title: "Estimated eCPM", iconClass: "bx-dollar-circle", description:  "$" + (res.data.response.estimatedeCPM).toLocaleString(), yesterdayRate: (prevCompEstimatedeCPM.toFixed(2) < 0 ? prevCompEstimatedeCPM.toFixed(2) * -1 : prevCompEstimatedeCPM.toFixed(2)) + "%", isIncreased: prevCompEstimatedeCPM > 0 ? 'yes' : prevCompEstimatedeCPM < 0 ? 'no' : 'zero' },
                { title: "Estimated Fill Rate", iconClass: "bx-show-alt", description: res.data.response.estimatedFillRate + "%", yesterdayRate: (prevCompEstimatedFillRate.toFixed(2) < 0 ? prevCompEstimatedFillRate.toFixed(2) * -1 : prevCompEstimatedFillRate.toFixed(2)) + "%", isIncreased: prevCompEstimatedFillRate > 0 ? 'yes' : prevCompEstimatedFillRate < 0 ? 'no' : 'zero' },
              ])
            }
            else {
              setReportData([
                { title: "Total Code Served", iconClass: "bx-bar-chart", description: (res.data.response.totalCodeServed).toLocaleString(), yesterdayRate: '0%', isIncreased: 'zero' },
                { title: "Paid Impressions", iconClass: "bx-bar-chart", description: (res.data.response.estimatedImpressions).toLocaleString(), yesterdayRate: '0%', isIncreased: 'zero' },
                { title: "Revenue", iconClass: "bx-dollar-circle", description: "$" + (res.data.response.estimatedRevenue).toLocaleString(), yesterdayRate: '0%', isIncreased: 'zero' },
                { title: "Estimated eCPM", iconClass: "bx-dollar-circle", description: "$" + (res.data.response.estimatedeCPM).toLocaleString(), yesterdayRate: '0%', isIncreased: 'zero' },
                { title: "Estimated Fill Rate", iconClass: "bx-show-alt", description: res.data.response.estimatedFillRate + "%", yesterdayRate: '0%', isIncreased: 'zero' },
              ])
            }
            if (res.data.response.overview && res.data.response.overview.length > 0) {
              xAxis = []
              let estimatedImpressions = []
              let estimatedRevenue = []
              let estimatedeCPM = []
              res.data.response.overview.map((item, index) => {
                xAxis.push(item['x-axis'])
              })
              res.data.response.overview.map((item, index) => {
                estimatedImpressions.push(item['estimatedImpressions'] < 0 ? 0 : item['estimatedImpressions'])
              })
              res.data.response.overview.map((item, index) => {
                estimatedRevenue.push(item['estimatedRevenue'] < 0 ? 0 : item['estimatedRevenue'])
              })
              res.data.response.overview.map((item, index) => {
                estimatedeCPM.push(item['estimatedeCPM'] < 0 ? 0 : item['estimatedeCPM'])
              })
  
              setChartData([
                { name: 'Total Code Served', type: "column", data: estimatedImpressions },
                { name: 'Estimated Revenue', type: "column", data: estimatedRevenue },
                { name: 'Estimated eCPM', type: "line", data: estimatedeCPM },
              ])
            } else {
              setChartData([])
              xAxis = []
            }
            if (res.data.response.stdb && res.data.response.stdb.length > 0) {
              stdbXAxis = []
              let estimatedImpressions = []
              let estimatedRevenue = []
              let estimatedeCPM = []
              res.data.response.stdb.map((item, index) => {
                stdbXAxis.push(item['x-axis'])
              })
              res.data.response.stdb.map((item, index) => {
                estimatedImpressions.push(item['estimatedImpressions'] < 0 ? 0 : item['estimatedImpressions'])
              })
              res.data.response.stdb.map((item, index) => {
                estimatedRevenue.push(item['estimatedRevenue'] < 0 ? 0 : item['estimatedRevenue'])
              })
              res.data.response.stdb.map((item, index) => {
                estimatedeCPM.push(item['estimatedeCPM'] < 0 ? 0 : item['estimatedeCPM'])
              })
  
              setStdbPeriodData([
                { name: 'Total Code Served', type: "column", data: estimatedImpressions },
                { name: 'Estimated Revenue', type: "column", data: estimatedRevenue },
                { name: 'Estimated eCPM', type: "line", data: estimatedeCPM },
              ])
            } else {
              setStdbPeriodData([])
              stdbXAxis = []
            }
            if (res.data.response.highImpact && res.data.response.highImpact.length > 0) {
              highImpactXAxis = []
              let estimatedImpressions = []
              let estimatedRevenue = []
              let estimatedeCPM = []
              res.data.response.highImpact.map((item, index) => {
                highImpactXAxis.push(item['x-axis'])
              })
              res.data.response.highImpact.map((item, index) => {
                estimatedImpressions.push(item['estimatedImpressions'] < 0 ? 0 : item['estimatedImpressions'])
              })
              res.data.response.highImpact.map((item, index) => {
                estimatedRevenue.push(item['estimatedRevenue'] < 0 ? 0 : item['estimatedRevenue'])
              })
              res.data.response.highImpact.map((item, index) => {
                estimatedeCPM.push(item['estimatedeCPM'] < 0 ? 0 : item['estimatedeCPM'])
              })
  
              setHighImpactPeriodData([
                { name: 'Total Code Served', type: "column", data: estimatedImpressions },
                { name: 'Estimated Revenue', type: "column", data: estimatedRevenue },
                { name: 'Estimated eCPM', type: "line", data: estimatedeCPM },
              ])
            } else {
              setHighImpactPeriodData([])
              highImpactXAxis = []
            }
            if (res.data.response.video && res.data.response.video.length > 0) {
              videoXAxis = []
              let estimatedImpressions = []
              let estimatedRevenue = []
              let estimatedeCPM = []
              res.data.response.video.map((item, index) => {
                videoXAxis.push(item['x-axis'])
              })
              res.data.response.video.map((item, index) => {
                estimatedImpressions.push(item['estimatedImpressions'] < 0 ? 0 : item['estimatedImpressions'])
              })
              res.data.response.video.map((item, index) => {
                estimatedRevenue.push(item['estimatedRevenue'] < 0 ? 0 : item['estimatedRevenue'])
              })
              res.data.response.video.map((item, index) => {
                estimatedeCPM.push(item['estimatedeCPM'] < 0 ? 0 : item['estimatedeCPM'])
              })
              setVideoPeriodData([
                { name: 'Total Code Served', type: "column", data: estimatedImpressions },
                { name: 'Estimated Revenue', type: "column", data: estimatedRevenue },
                { name: 'Estimated eCPM', type: "line", data: estimatedeCPM },
              ])
            } else {
              setVideoPeriodData([])
              videoXAxis = []
            }
          }else{
            setReportData([
              { title: "Total Code Served", iconClass: "bx-bar-chart", description: '0%', yesterdayRate: '0%', isIncreased: 'zero' },
              { title: "Paid Impressions", iconClass: "bx-bar-chart", description: '0%', yesterdayRate: '0%', isIncreased: 'zero' },
              { title: "Revenue", iconClass: "bx-dollar-circle", description: "$" + '0', yesterdayRate: '0%', isIncreased: 'zero' },
              { title: "Estimated eCPM", iconClass: "bx-dollar-circle", description: "$" + '0', yesterdayRate: '0%', isIncreased: 'zero' },
              { title: "Estimated Fill Rate", iconClass: "bx-show-alt", description: '0%' , yesterdayRate: '0%', isIncreased: 'zero' },
            ])
            setChartData([])
            xAxis = []
            setStdbPeriodData([])
            stdbXAxis = []
            setHighImpactPeriodData([])
            highImpactXAxis = []
            setVideoPeriodData([])
            videoXAxis = []
          }

        }
      })
      .catch((err) => {
        setLoading(false)
        console.log("e", err)
      });
  }

  const dataModal = (data, label) => (e) => {
    let dataList = []
    data.map((item, index) => {
      dataList.push(item[label])
    })
    console.log(dataList)
    return dataList

  }


  const changeDatePick = (event) => {
    dateFromDate = event.startDate
    dateToDate = event.endDate
    fromDate = event.startDate._d.toISOString();
    toDate = event.endDate._d.toISOString();
    setSelectedAdUnit([])
    setSelectedHighImpactAdUnit([])
    setSelectedVideoAdUnit([])
    setPeriodType("day")
    setStdbPeriodType("day");
    setHighImpactPeriodType("day");
    setVideoPeriodType("day");
    getDashboardData(inventoryType)

    // setFromDate(event.startDate._d.toISOString());
    // setToDate(event.endDate._d.toISOString());
  }

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));




  useEffect(() => {
    getAdUnitsData()
  }, []);

  // const onChangeAdUnit = (e) => {
  //   setSelectedAdUnit(e.target.value)
  //   getStdbData(stdbPeriodType, e.target.value)
  // };

  // const onChangeHighImpactAdUnit = (e) => {
  //   setSelectedHighImpactAdUnit(e.target.value)
  //   getHighImpactData(highImpactPeriodType, e.target.value)
  // };

  // const onChangeVideoAdUnit = (e) => {
  //   setSelectedVideoAdUnit(e.target.value)
  //   getVideoOverViewData(videoPeriodType, e.target.value)
  // };

  function onChangeAdUnit(selectAdUnit) {
    setSelectedAdUnit(selectAdUnit);
    getStdbData(stdbPeriodType, selectAdUnit)
  }

  function onChangeHighImpactAdUnit(selectAdUnit) {
    setSelectedHighImpactAdUnit(selectAdUnit);
    getHighImpactData(highImpactPeriodType, selectAdUnit)
  }

  function onChangeVideoAdUnit(selectAdUnit) {
    setSelectedVideoAdUnit(selectAdUnit);
    getVideoOverViewData(videoPeriodType, selectAdUnit)
  }

  const getAdUnitsData = () => {
    const body = {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
        inventoryType: '',parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
    }
    axios.post(userInfo.userRole === 'SupplyPartner' ? "/v2.0/reportingSupplyPartner/Query/Revenue/DashBoards/getAdunit" : "/v2.0/reporting/Query/Revenue/DashBoards/getAdunit", body,
      {
        headers: headers
      })
      .then((res) => {
        if (res.data) {
          setAdUnitData(objectModal(res.data.response.stdbAdUnitName))
          setHighImpactAdUnitData(objectModal(res.data.response.highImpactAdUnitName))
          setVideoAdUnitData(objectModal(res.data.response.videoAdUnitName))
      }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }


  const onChangeChartPeriod = pType => {
    setPeriodType(pType);
    getOverViewData(pType)
  };


  const getOverViewData = (pType) => {
    const body = {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
        frequency: pType,
        "startDate": moment(fromDate).format("YYYY-MM-DD"),
        "endDate": moment(toDate).format("YYYY-MM-DD"),
        parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
    }
    axios.post(userInfo.userRole === 'SupplyPartner' ? "/v2.0/reportingSupplyPartner/Query/Revenue/DashBoards/overView" : "/v2.0/reporting/Query/Revenue/DashBoards/overView", body,
      {
        headers: headers
      })
      .then((res) => {
        if (res.data) {
          if (res.data.response.overview.length > 0) {
            xAxis = []
            let estimatedImpressions = []
            let estimatedRevenue = []
            let estimatedeCPM = []
            res.data.response.overview.map((item, index) => {
              xAxis.push(item['x-axis'])
            })
            res.data.response.overview.map((item, index) => {
              estimatedImpressions.push(item['estimatedImpressions'] < 0 ? 0 : item['estimatedImpressions'])
            })
            res.data.response.overview.map((item, index) => {
              estimatedRevenue.push(item['estimatedRevenue'] < 0 ? 0 : item['estimatedRevenue'])
            })
            res.data.response.overview.map((item, index) => {
              estimatedeCPM.push(item['estimatedeCPM'] < 0 ? 0 : item['estimatedeCPM'])
            })
            setChartData([
              { name: 'Total Code Served', type: "column", data: estimatedImpressions },
              { name: 'Estimated Revenue', type: "column", data: estimatedRevenue },
              { name: 'Estimated eCPM', type: "line", data: estimatedeCPM },
            ])
          } else {
            setChartData([])
            xAxis = []
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const stdbOnChangeChartPeriod = pType => {
    setStdbPeriodType(pType);
    getStdbData(pType, selectedAdUnit)
  };


  const getStdbData = (pType, adUnit) => {
    let listData = []
    adUnit.length > 0 ? adUnit.map((item, index) => {
      listData.push(item.value)
    }) : []
    const body = {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
        frequency: pType,
        "adunit": listData,
        "startDate": moment(fromDate).format("YYYY-MM-DD"),
        "endDate": moment(toDate).format("YYYY-MM-DD"),
        parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
    }
    axios.post(userInfo.userRole === 'SupplyPartner' ? "/v2.0/reportingSupplyPartner/Query/Revenue/DashBoards/stdb" : "/v2.0/reporting/Query/Revenue/DashBoards/stdb", body,
      {
        headers: headers
      })
      .then((res) => {
        if (res.data) {
          if (res.data.response.stdb.length > 0) {
            stdbXAxis = []
            let estimatedImpressions = []
            let estimatedRevenue = []
            let estimatedeCPM = []
            res.data.response.stdb.map((item, index) => {
              stdbXAxis.push(item['x-axis'])
            })
            res.data.response.stdb.map((item, index) => {
              estimatedImpressions.push(item['estimatedImpressions'] < 0 ? 0 : item['estimatedImpressions'])
            })
            res.data.response.stdb.map((item, index) => {
              estimatedRevenue.push(item['estimatedRevenue'] < 0 ? 0 : item['estimatedRevenue'])
            })
            res.data.response.stdb.map((item, index) => {
              estimatedeCPM.push(item['estimatedeCPM'] < 0 ? 0 : item['estimatedeCPM'])
            })
            setStdbPeriodData([
              { name: 'Total Code Served', type: "column", data: estimatedImpressions },
              { name: 'Estimated Revenue', type: "column", data: estimatedRevenue },
              { name: 'Estimated eCPM', type: "line", data: estimatedeCPM },
            ])
          } else {
            setStdbPeriodData([])
            stdbXAxis = []
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const highImpactOnChangeChartPeriod = pType => {
    setHighImpactPeriodType(pType);
    getHighImpactData(pType, selectedHighImpactAdUnit)
  };


  const getHighImpactData = (pType, adUnit) => {
    let listData = []
    adUnit.length > 0 ? adUnit.map((item, index) => {
      listData.push(item.value)
    }) : []
    const body = {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
        frequency: pType,
        "adunit": listData,
        "startDate": moment(fromDate).format("YYYY-MM-DD"),
        "endDate": moment(toDate).format("YYYY-MM-DD"),
        parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
    }
    axios.post(userInfo.userRole === 'SupplyPartner' ? "/v2.0/reportingSupplyPartner/Query/Revenue/DashBoards/highImpac" : "/v2.0/reporting/Query/Revenue/DashBoards/highImpact", body,
      {
        headers: headers
      })
      .then((res) => {
        if (res.data) {
          if (res.data.response.highImpact.length > 0) {
            highImpactXAxis = []
            let estimatedImpressions = []
            let estimatedRevenue = []
            let estimatedeCPM = []
            res.data.response.highImpact.map((item, index) => {
              highImpactXAxis.push(item['x-axis'])
            })
            res.data.response.highImpact.map((item, index) => {
              estimatedImpressions.push(item['estimatedImpressions'] < 0 ? 0 : item['estimatedImpressions'])
            })
            res.data.response.highImpact.map((item, index) => {
              estimatedRevenue.push(item['estimatedRevenue'] < 0 ? 0 : item['estimatedRevenue'])
            })
            res.data.response.highImpact.map((item, index) => {
              estimatedeCPM.push(item['estimatedeCPM'] < 0 ? 0 : item['estimatedeCPM'])
            })
            setHighImpactPeriodData([
              { name: 'Total Code Served', type: "column", data: estimatedImpressions },
              { name: 'Estimated Revenue', type: "column", data: estimatedRevenue },
              { name: 'Estimated eCPM', type: "line", data: estimatedeCPM },
            ])
          } else {
            setHighImpactPeriodData([])
            highImpactXAxis = []
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const videoOnChangeChartPeriod = pType => {
    setVideoPeriodType(pType);
    getVideoOverViewData(pType, selectedVideoAdUnit)
  };


  const getVideoOverViewData = (pType, adUnit) => {
    let listData = []
    adUnit.length > 0 ? adUnit.map((item, index) => {
      listData.push(item.value)
    }) : []
    const body = {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
        frequency: pType,
        "adunit": listData,
        "startDate": moment(fromDate).format("YYYY-MM-DD"),
        "endDate": moment(toDate).format("YYYY-MM-DD"),
        parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
    }
    axios.post(userInfo.userRole === 'SupplyPartner' ? "/v2.0/reportingSupplyPartner/Query/Revenue/DashBoards/video" : "/v2.0/reporting/Query/Revenue/DashBoards/video", body,
      {
        headers: headers
      })
      .then((res) => {
        if (res.data) {
          if (res.data.response.video.length > 0) {
            videoXAxis = []
            let estimatedImpressions = []
            let estimatedRevenue = []
            let estimatedeCPM = []
            res.data.response.video.map((item, index) => {
              videoXAxis.push(item['x-axis'])
            })
            res.data.response.video.map((item, index) => {
              estimatedImpressions.push(item['estimatedImpressions'] < 0 ? 0 : item['estimatedImpressions'])
            })
            res.data.response.video.map((item, index) => {
              estimatedRevenue.push(item['estimatedRevenue'] < 0 ? 0 : item['estimatedRevenue'])
            })
            res.data.response.video.map((item, index) => {
              estimatedeCPM.push(item['estimatedeCPM'] < 0 ? 0 : item['estimatedeCPM'])
            })
            setVideoPeriodData([
              { name: 'Total Code Served', type: "column", data: estimatedImpressions },
              { name: 'Estimated Revenue', type: "column", data: estimatedRevenue },
              { name: 'Estimated eCPM', type: "line", data: estimatedeCPM },
            ])
          }
          else {
            setVideoPeriodData([])
            videoXAxis = []
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  useEffect(() => {
    if (loading) {
      setSwitchLoader(true)
    }
  }, [loading]);


  // useEffect(() => {
  //   dispatch(onGetChartsData("year"));
  // }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content ">
        <Helmet>
          <title>Marketplace Dashboard | Bidsxchange</title>
        </Helmet>
        <Container fluid>
          {/* Render Breadcrumb */}

          <Row>
            <Col md="5">
              <Breadcrumbs
                title={props.t("Marketplace Dashboard")}
                breadcrumbItem={props.t("Marketplace Dashboard")}
              />
            </Col>
            <Col md="7">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-end mb-4">
                    {(userInventoryState === 'both' || userInventoryState === 'display') &&
                      <Input type="select" className="form-select" style={{ width: '18pc' }} value={inventoryType} onChange={changeInventory}>
                        <option value=''> Select Inventory Type</option>
                        <option value='stdb' >Standard Banner</option>
                        <option value='highImpact'> High Impact</option>
                        {userInventoryState === 'both' &&
                          <option value='video'> Video</option>
                        }
                        {userInventoryState === 'both' &&
                          <option value='video_CTV' > Video CTV</option>
                        }
                      </Input>
                    }
                    {(userInventoryState === 'video') &&
                      <Input type="select" className="form-select" style={{ width: '18pc' }} value={inventoryType} onChange={changeInventory}>
                        <option value=''> Select Inventory Type</option>
                        <option value='video'> Video</option>
                        <option value='video_CTV' > Video CTV</option>
                      </Input>
                    }
                    &nbsp;&nbsp;
                    <DateRangePickerDashboard  datePick={changeDatePick} startDate={dateFromDate} endDate={dateToDate} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            {/* <Col xl="4">
                  <WelcomeComp />
                </Col> */}
            <Col xl="">
              <Row>
                {/* Reports Render */}
                {/* <Col md='4'>
                  <WelcomeComp />
                </Col> */}
                {switchLoader &&
                  <LoadingDashboardCard loading={loading} targetLoading={handleSwitch} />
                }
                {!switchLoader && reportData.map((report, key) => (
                  <Col md="" key={"_col_" + key}>
                    <Card className="mini-stats-wid dashboard-cards text-center" style={{backgroundColor:"#f8f8fa",color:"inherited" }} >
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className={"text-muted" + " fw-medium " }>
                              {report.title}
                            </p>
                            <h4 style={{ marginBottom: '3%',color:"inherited" }}>{report.description}</h4>

                            <span className={report.isIncreased === 'yes' ? 'badge badge-soft-success' : report.isIncreased === 'no' ?  'badge badge-soft-danger' : 'badge badge-soft-warning'} style={{ fontSize: '12px', fontWeight: '500', marginBottom: '0.7em' }}>
                              {(report.isIncreased === 'yes' ? <i className="mdi mdi-arrow-up " /> : report.isIncreased === 'no' ? <i className="mdi mdi-arrow-down " /> : '')} {report.yesterdayRate}</span>                               <br></br>
                            &nbsp;&nbsp;From Previous Period
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}

                {/* {!switchLoader && reportData.map((report, key) => (
                  <Col md="" key={"_col_" + key}>
                    <Card className="mini-stats-wid dashboard-cards text-center" >
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <h5 className="bL ">
                              {report.title}
                            </h5>
                            <h5 style={{ borderTop: '2px solid',margin:'10px 0px 20px 0px' }}></h5>
                            <h5 className="hL fw-bold" style={{ marginBottom: '3%',marginTop:'5%' }}>{report.description}</h5>

                            <span className={report.isIncreased === 'yes' ? 'badge badge-soft-success' : report.isIncreased === 'no' ? 'badge badge-soft-danger' : 'badge badge-soft-warning'} style={{ fontSize: '12px', fontWeight: '500', marginBottom: '0.7em' }}>
                              {(report.isIncreased === 'yes' ? <i className="mdi mdi-arrow-up " /> : report.isIncreased === 'no' ? <i className="mdi mdi-arrow-down " /> : '')} {report.yesterdayRate}</span>       
                            &nbsp;&nbsp;From Previous Period
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))} */}
              </Row>
            </Col>
          </Row>
          {/* <Row>
            {!switchLoader && reportData.map((report, key) => (
                  <Col md="" key={"_col_" + key}>

                    <Card className="mini-stats-wid">
                            <CardBody>
                                <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <p className="text-muted fw-medium">{report.title}</p>
                                        <h4 className="mb-0">{report.description}</h4>
                                    </div>
                                </div>
                            </CardBody>
                                <div className="card-body border-top py-3">
                                    <p className="mb-0"> <span className={" me-2" + report.isIncreased === 'yes' ? 'badge badge-soft-success' : report.isIncreased === 'no' ? 'badge badge-soft-danger' : 'badge badge-soft-warning'} >
                                    {(report.isIncreased === 'yes' ? <i className="bx bx-trending-up align-bottom me-1"></i>: report.isIncreased === 'no' ? <i className="bx bx-trending-down align-bottom me-1"></i> : '')}   {report.yesterdayRate}</span>
                                      From Previous Period</p>
                                </div>
                            

                        </Card>
                  </Col>
                ))}
            </Row> */}

          <Row>
            <div className="d-flex  mb-4">
              <Nav tabs className="nav-tabs-custom nav-justified" style={{ width: '40pc' }}>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classNames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="d-none d-sm-block" style={{ fontSize: '13px' }}>Overview</span>
                  </NavLink>
                </NavItem>
                {(userInventoryState === 'video' || userInventoryState === 'both') &&
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classNames({
                        active: customActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleCustom("2");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-user"></i>
                      </span>
                      <span className="d-none d-sm-block" style={{ fontSize: '13px' }}>Video</span>
                    </NavLink>
                  </NavItem>
                }
                {(userInventoryState === 'display' || userInventoryState === 'both') &&
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classNames({
                        active: customActiveTab === "3",
                      })}
                      onClick={() => {
                        toggleCustom("3");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-user"></i>
                      </span>
                      <span className="d-none d-sm-block" style={{ fontSize: '13px' }}>High Impact</span>
                    </NavLink>
                  </NavItem>
                }
                {(userInventoryState === 'display' || userInventoryState === 'both') &&
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classNames({
                        active: customActiveTab === "4",
                      })}
                      onClick={() => {
                        toggleCustom("4");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-user"></i>
                      </span>
                      <span className="d-none d-sm-block" style={{ fontSize: '13px' }}>Standard Banner</span>
                    </NavLink>
                  </NavItem>
                }
              </Nav>
            </div>
          </Row>
          <TabContent
            activeTab={customActiveTab}
            className=" text-muted"
          >
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody>
                      <div className="d-sm-flex flex-wrap">
                        <h4 className="card-title mb-4">Overview of Total code served | Revenue | Estimated eCPM</h4>
                        <div className="ms-auto">
                          <ul className="nav nav-pills">
                            <li className="nav-item">
                              <Link
                                to="#"
                                className={classNames(
                                  { active: periodType === "day" },
                                  "nav-link"
                                )}
                                onClick={() => {
                                  onChangeChartPeriod("day");
                                }}
                                id="one_month"
                              >
                                Day
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="#"
                                className={classNames(
                                  { active: periodType === "week" },
                                  "nav-link"
                                )}
                                onClick={() => {
                                  onChangeChartPeriod("week");
                                }}
                                id="one_month"
                              >
                                Week
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="#"
                                className={classNames(
                                  { active: periodType === "month" },
                                  "nav-link"
                                )}
                                onClick={() => {
                                  onChangeChartPeriod("month");
                                }}
                                id="one_month"
                              >
                                Month
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <MarketStackColoumn chartData={chartData} loading={loading} xAxisData={xAxis} dataColors='["--bs-danger","--bs-primary", "--bs-success"]' title={''} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody>
                      <div className="d-sm-flex flex-wrap">
                        <h4 className="card-title mb-4">Video</h4>
                        <span style={{ position: 'absolute', width: '35%', left: '30%' }}>
                          <MultiSelect
                            options={videoAdUnitData}
                            value={selectedVideoAdUnit}
                            onChange={onChangeVideoAdUnit}
                            overrideStrings={{ "selectSomeItems": "Please Select AdUnits" }}
                          />
                        </span>
                        <div className="ms-auto">
                          <ul className="nav nav-pills">
                            <li className="nav-item">
                              <Link
                                to="#"
                                className={classNames(
                                  { active: videoPeriodType === "day" },
                                  "nav-link"
                                )}
                                onClick={() => {
                                  videoOnChangeChartPeriod("day");
                                }}
                                id="one_month"
                              >
                                Day
                              </Link>
                            </li>
                            <li className="nav-item">

                              <Link
                                to="#"
                                className={classNames(
                                  { active: videoPeriodType === "week" },
                                  "nav-link"
                                )}
                                onClick={() => {
                                  videoOnChangeChartPeriod("week");
                                }}
                                id="one_month"
                              >
                                Week
                              </Link>{" "}
                            </li>
                            <li className="nav-item">
                              <Link
                                to="#"
                                className={classNames(
                                  { active: videoPeriodType === "month" },
                                  "nav-link"
                                )}
                                onClick={() => {
                                  videoOnChangeChartPeriod("month");
                                }}
                                id="one_month"
                              >
                                Month
                              </Link>
                            </li>

                          </ul>
                        </div>
                      </div>
                      <div className="clearfix mt-2"></div>
                      <MarketStackColoumn chartData={videoPeriodData} loading={loading} xAxisData={videoXAxis} dataColors='["--bs-danger","--bs-primary", "--bs-success"]' title={''} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody>
                      <div className="d-sm-flex flex-wrap">
                        <h4 className="card-title mb-4">High Impact</h4>
                        <span style={{ position: 'absolute', width: '35%', left: '30%' }}>
                          <MultiSelect
                            options={highImpactAdUnitData}
                            value={selectedHighImpactAdUnit}
                            onChange={onChangeHighImpactAdUnit}
                            overrideStrings={{ "selectSomeItems": "Please Select AdUnits" }}
                          />
                        </span>
                        <div className="ms-auto">
                          <ul className="nav nav-pills ">
                            <li className="nav-item">
                              <Link
                                to="#"
                                className={classNames(
                                  { active: highImpactPeriodType === "day" },
                                  "nav-link"
                                )}
                                onClick={() => {
                                  highImpactOnChangeChartPeriod("day");
                                }}
                                id="one_month"
                              >
                                Day
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="#"
                                className={classNames(
                                  { active: highImpactPeriodType === "week" },
                                  "nav-link"
                                )}
                                onClick={() => {
                                  highImpactOnChangeChartPeriod("week");
                                }}
                                id="one_month"
                              >
                                Week
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="#"
                                className={classNames(
                                  { active: highImpactPeriodType === "month" },
                                  "nav-link"
                                )}
                                onClick={() => {
                                  highImpactOnChangeChartPeriod("month");
                                }}
                                id="one_month"
                              >
                                Month
                              </Link>
                            </li>

                          </ul>
                        </div>
                      </div>
                      <div className="clearfix mt-2"></div>
                      <MarketStackColoumn chartData={highImpactPeriodData} loading={loading} xAxisData={highImpactXAxis} dataColors='["--bs-danger","--bs-primary", "--bs-success"]' title={''} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="4">
              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody>
                      <div className="d-sm-flex flex-wrap">
                        <h4 className="card-title mb-4">Standard Banner</h4>
                        <span style={{ position: 'absolute', width: '35%', left: '30%' }}>
                          <MultiSelect
                            options={adUnitData}
                            value={selectedAdUnit}
                            onChange={onChangeAdUnit}
                            overrideStrings={{ "selectSomeItems": "Please Select AdUnits" }}
                          />
                        </span>
                        <div className="ms-auto">
                          <ul className="nav nav-pills ">
                            <li className="nav-item">
                              <Link
                                to="#"
                                className={classNames(
                                  { active: stdbPeriodType === "day" },
                                  "nav-link"
                                )}
                                onClick={() => {
                                  stdbOnChangeChartPeriod("day");
                                }}
                                id="one_month"
                              >
                                Day
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="#"
                                className={classNames(
                                  { active: stdbPeriodType === "week" },
                                  "nav-link"
                                )}
                                onClick={() => {
                                  stdbOnChangeChartPeriod("week");
                                }}
                                id="one_month"
                              >
                                Week
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="#"
                                className={classNames(
                                  { active: stdbPeriodType === "month" },
                                  "nav-link"
                                )}
                                onClick={() => {
                                  stdbOnChangeChartPeriod("month");
                                }}
                                id="one_month"
                              >
                                Month
                              </Link>
                            </li>

                          </ul>
                        </div>
                      </div>
                      <div className="clearfix mt-2"></div>
                      <MarketStackColoumn chartData={stdbPeriodData} loading={loading} xAxisData={stdbXAxis} dataColors='["--bs-danger","--bs-primary", "--bs-success"]' title={''} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </React.Fragment>
  );
};

MarketDashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(MarketDashboard)