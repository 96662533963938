import React, { useEffect, useState, useRef, useCallback, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  ButtonDropdown,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Input,
  Spinner,
  Label
} from "reactstrap"
import queryString from 'query-string';
import { MultiSelect } from "react-multi-select-component";

import {
  getLineItem as onGetLineItem,
  statusLineItem as onStatusLineItem,
  archiveLineItem as onArchiveLineItem,
  statusLineItemSuccess,
  archiveLineItemSuccess,
  addLineItemFail,
  updateLineItemFail,
} from "store/Demand/LineItem/actions";

import {
  getAdUnit as onGetAdUnits,
} from "store/Supply/AdUnit/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Create_LI from "./Create_LineItem/Create_LI";
import LoadingTable from "pages/LoaderPages/LoadingTable";
import DetachLineItem from "./DetachLineItem";

import LineItemDataTable from "Product_Helpers/ProductDataTable"
import ProductDataTableLI from "Product_Helpers/ProductDataTableLI";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import ErrorPage from "pages/errorFile";
var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 2 : 1
var fromDate = ''
var toDate = ''

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var lineItemType = ''

var filterCount = 0

let pageNumber = 1
let pageSize = 10
let totalRecords = 0
let searchText = ''

const Line_Items = () => {
  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const [isRights, setIsRights] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const [drp_primary1, setDrp_primary1] = useState(false)
  const [btnprimary1, setBtnprimary1] = useState(false)
  const parsed = queryString.parse(window.location.search);
  const [statusSearch, setStatusSearch] = useState('Active')
  const [lineItemTypeSearch, setLineItemTypeSearch] = useState('')
  const [adUnitSearch, setAdUnitSearch] = useState([])
  const [prioritySearch, setPrioritySearch] = useState('')
  const [isArchived, setIsArchived] = useState(false)
  const [lineItemCreateStatus, setLineItemCreateStatus] = useState('')
  const [selected, setSelected] = useState([])
  const [createState, setCreateState] = useState(true)
  const [adUnitData, setAdUnitData] = useState([])
  const [selectedAdUnit, setSelectedAdUnit] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [switchLoader, setSwitchLoader] = useState(true)
  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
  const UserInventoryType = localStorage.getItem('InventoryType');
  const lineItemFilterStorage = JSON.parse(localStorage.getItem("filterLI"));
  const lineItemFilterStorageAdUnits = JSON.parse(localStorage.getItem("filterLIAdUnits"));

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }
  const [state, setState] = useState({
    start: moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
    end: moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
  });
  const { start, end } = state;
  const handleEvent = (startDate, endDate, label) => {
    setState({ start: startDate, end: endDate, });
    fromDate = startDate._d.toISOString();
    toDate = endDate._d.toISOString();
    filterCount = filterCount + 1
  }
  const labelDate = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setIsRight(false);
      setIsRights(false)
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const [filterState, setFilterState] = useState(false)
  const statusChange = (e) => {
    if (e.target.value === 'Active') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (statusSearch === 'Active') {
      filterCount = filterCount + 1
    }
    setStatusSearch(e.target.value)
    // if (parsed.ioID) {
    //   dispatch(onGetLineItem({ ioID: parsed.ioID, filter: e.target.value, lineItemType: lineItemTypeSearch, priority: prioritySearch,pageSize:pageSize,pageNumber:pageNumber,searchText:searchText,adUnitID: adUnitSearch }));
    // } else {
    //   dispatch(onGetLineItem({ ioID: '', filter: e.target.value, lineItemType: lineItemTypeSearch, priority: prioritySearch,pageSize:pageSize,pageNumber:pageNumber,searchText:searchText,adUnitID: adUnitSearch }));
    // }
  }

  const handleSelectAdUnit = selectAdUnit => {
    if (selectAdUnit.length === 0) {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (adUnitSearch.length === 0) {
      filterCount = filterCount + 1
    }
    setSelectedAdUnit(selectAdUnit);
    localStorage.setItem("filterLIAdUnits", JSON.stringify(selectAdUnit))
    let listData = []
    selectAdUnit.map((item, index) => {
      listData.push(item.value)
    })
    setAdUnitSearch(listData)
  }

  const lineItemTypeChange = (e) => {
    if (e.target.value === '') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (lineItemTypeSearch === '') {
      filterCount = filterCount + 1
    }
    setLineItemTypeSearch(e.target.value)
  }

  const priorityChange = (e) => {
    if (e.target.value === '') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (prioritySearch === '') {
      filterCount = filterCount + 1
    }
    setPrioritySearch(e.target.value)
  }

  const applyFilter = (e) => {
    setisMenu(false)
    let filterStorage = {}
    if (parsed.ioID) {
      filterStorage = { ioID: parsed.ioID, filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, adUnitID: adUnitSearch }
      dispatch(onGetLineItem({ ioID: parsed.ioID, filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, adUnitID: adUnitSearch }));
    } else {
      filterStorage = { ioID: '', filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, adUnitID: adUnitSearch }
      dispatch(onGetLineItem({ ioID: '', filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, adUnitID: adUnitSearch }));
    }
    localStorage.setItem("filterLI", JSON.stringify(filterStorage))
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const clearAllFilter = (e) => {
    setisMenu(false)
    setSelectedAdUnit([])
    setAdUnitSearch([])
    setStatusSearch('Active')
    setLineItemTypeSearch('')
    setPrioritySearch('')
    filterCount = 0
    setFilterState(false)
    fromDate = ''
    toDate = ''
    setState({
      start: moment().subtract(0, 'days'),
      end: moment().subtract(0, 'days'),
    })
    let cleanstorage = {}
    if (parsed.ioID) {
      cleanstorage = { ioID: parsed.ioID, filter: 'Active', lineItemType: '', priority: '', userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, adUnitID: [] }
      dispatch(onGetLineItem({ ioID: parsed.ioID, filter: 'Active', lineItemType: '', priority: '', userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, adUnitID: [] }));
    } else {
      cleanstorage = { ioID: '', filter: 'Active', lineItemType: '', priority: '', userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, adUnitID: [] }
      dispatch(onGetLineItem({ ioID: '', filter: 'Active', lineItemType: '', priority: '', userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, adUnitID: [] }));
    }
    localStorage.setItem("filterLI", JSON.stringify(cleanstorage))
    localStorage.setItem("filterLIAdUnits", JSON.stringify([]))
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };

  useEffect(() => {
    dispatch(onGetLineItem(null));
    pageNumber = 1
    pageSize = 10
    totalRecords = 0
    searchText = ''
    if (parsed.status === 'LineItemCreateByIO') {
      lineItemType = parsed.lineItemType
      setIsRight(true);
      setCreateState(!createState)
    }
  }, []);

  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.lineItemID)
        selectedItem.push(row.lineItemID)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.lineItemID)
        selectedIndex.splice(index, 1)
        let index2 = selectedItem.indexOf(row.lineItemID)
        selectedItem.splice(index2, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.lineItemID)
          selectedItem.push(item.lineItemID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }
    }
  };

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
  }

  const { lineItems, paginationData } = useSelector(state => ({
    lineItems: state.lineItems.lineItems,
    paginationData: state.lineItems.lineItemsPagination
  }));

  totalRecords = paginationData ? paginationData.totalRecords ? paginationData.totalRecords : 0 : 0

  const { loading } = useSelector(state => ({
    loading: state.lineItems.loading,
  }));

  const { noData } = useSelector(state => ({
    noData: state.lineItems.noData,
  }));

  const { lineItemsResponse } = useSelector(state => ({
    lineItemsResponse: state.lineItems.response,
  }));

  const { archiveLineItemResponse } = useSelector(state => ({
    archiveLineItemResponse: state.lineItems.archiveMsg,
  }));

  const { adUnits } = useSelector(state => ({
    adUnits: state.adUnits.adUnitList
  }));

  useEffect(() => {
    if (loading) {
      setSwitchLoader(true)
    }

  }, [loading]);

  useEffect(() => {
    if (adUnits !== null) {
      dispatch(onGetAdUnits({ adUnitGroupID: '', pageSize: 0, pageNumber: 0, searchText: '' , filter: 'Active', userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": '', "endDate": '', adUnitType: '', adUnitSize: '' }));
    }
  }, []);

  useEffect(() => {
    if (adUnits && adUnits.length > 0) {
      setAdUnitData(objectAdUnitModal(adUnits))
      console.log(adUnits)
    }
  }, [adUnits]);

  const objectAdUnitModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.adUnitID
      obj.label = item.adUnitName
      dataList.push(obj)
    })
    return dataList
  }

  useEffect(() => {
    if (lineItemsResponse !== null) {
      if (lineItemsResponse.statusCode === 200) {
        toastr.success('LineItem Status Changed Successfully')
        if (parsed.ioID) {
          dispatch(onGetLineItem({ ioID: parsed.ioID, filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, adUnitID: adUnitSearch }));
        } else {
          dispatch(onGetLineItem({ ioID: '', filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, adUnitID: adUnitSearch }));
        }
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(statusLineItemSuccess(null));
      }
    }
  }, [dispatch, lineItemsResponse]);

  useEffect(() => {
    if (archiveLineItemResponse !== null) {
      if (archiveLineItemResponse.statusCode === 200) {
        toastr.success('LineItem Archived Successfully')
        if (parsed.ioID) {
          dispatch(onGetLineItem({ ioID: parsed.ioID, filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, adUnitID: adUnitSearch }));
        } else {
          dispatch(onGetLineItem({ ioID: '', filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, adUnitID: adUnitSearch }));
        }
        dispatch(archiveLineItemSuccess(null));
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }
    }
  }, [dispatch, archiveLineItemResponse]);

  const toggleRightCanvas = () => {
    selectedData = ''
    // APIforLineItem()
    dispatch(addLineItemFail(""));
    dispatch(updateLineItemFail(""));
    setIsRight(!isRight);
    setCreateState(!createState)
  };

  const toggleRightCanvass = () => {
    selectedData = ''
    APIforLineItem()
    dispatch(addLineItemFail(""));
    dispatch(updateLineItemFail(""));
    setIsRights(!isRights);
  };

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, lineItem, lineItemTypes, creationStatus) => (event) => {
    if (event === 'Create') {
      toastr.success('LineItem Saved Successfully!...')
      APIforLineItem()
    }
    if (event === 'Update') {
      toastr.success('LineItem Updated Successfully!...')
      APIforLineItem()
    }
    selectedData = lineItem
    lineItemType = lineItemTypes
    setIsArchived(lineItem.isLineItemArchived)
    setLineItemCreateStatus(creationStatus)
    dispatch(addLineItemFail(""));
    dispatch(updateLineItemFail(""));
    setIsRight(close);
    setCreateState(!createState)
  };

  const APIforLineItem = (e) => {
    if (parsed.ioID) {
      dispatch(onGetLineItem({ ioID: parsed.ioID, filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, adUnitID: adUnitSearch }));
    } else {
      dispatch(onGetLineItem({ ioID: '', filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, adUnitID: adUnitSearch }));
    }
  }

  const toggleDrawers = (close, highImpact) => (event) => {
    console.log(event)
    if (parsed.ioID) {
      dispatch(onGetLineItem({ ioID: parsed.ioID, filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, adUnitID: adUnitSearch }));
    } else {
      dispatch(onGetLineItem({ ioID: '', filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, adUnitID: adUnitSearch }));
    }
    dispatch(addLineItemFail(""));
    dispatch(updateLineItemFail(""));
    setIsRights(close);
  };

  // useEffect(() => {
  //   if (parsed.ioID) {
  //     dispatch(onGetLineItem({ ioID: parsed.ioID, filter: lineItemFilterStorage.filter, lineItemType: lineItemFilterStorage.lineItemType, priority: lineItemFilterStorage.priority,pageSize:pageSize,pageNumber:pageNumber,searchText:searchText,adUnitID: lineItemFilterStorage.AdUnitID }));
  //   }
  // }, []);

  useEffect(() => {
    if (lineItems !== null) {
      console.log(lineItemFilterStorage)
      // if (lineItemFilterStorage) {
      //   filterCount = 0
      //   console.log(lineItemFilterStorage, filterCount)
      //   if (lineItemFilterStorage.lineItemType === '') {
      //     filterCount = filterCount > 0 ? filterCount - 1 : 0
      //   }
      //   if (lineItemTypeSearch === '') {
      //     filterCount = filterCount + 1
      //   }
      //   setLineItemTypeSearch(lineItemFilterStorage.lineItemType)
      //   if (lineItemFilterStorage.priority === '') {
      //     filterCount = filterCount > 0 ? filterCount - 1 : 0
      //   }
      //   if (prioritySearch === '') {
      //     filterCount = filterCount + 1
      //   }
      //   setPrioritySearch(lineItemFilterStorage.priority)
      //   if (lineItemFilterStorage.AdUnitID.length === 0) {
      //     filterCount = filterCount > 0 ? filterCount - 1 : 0
      //   }
      //   if (adUnitSearch.length === 0) {
      //     filterCount = filterCount + 1
      //   }
      //   // if (lineItemFilterStorageAdUnits.length > 0) {
      //   //   setSelectedAdUnit(lineItemFilterStorageAdUnits)
      //   // }
      //   // console.log(lineItemFilterStorageAdUnits, lineItemFilterStorageAdUnits.length)
      //   // setSelectedAdUnit(selectAdUnit);
      //   // let listData = []
      //   // selectAdUnit.map((item, index) => {
      //   //   listData.push(item.value)
      //   // })
      //   setAdUnitSearch(lineItemFilterStorage.AdUnitID)
      //   if (lineItemFilterStorage.filter === 'Active') {
      //     filterCount = filterCount > 0 ? filterCount - 1 : 0
      //   }
      //   if (statusSearch === 'Active') {
      //     // filterCount = filterCount + 1
      //   }
      //   setStatusSearch(lineItemFilterStorage.filter)
      //   if (parsed.ioID) {
      //     dispatch(onGetLineItem({ ioID: parsed.ioID, filter: lineItemFilterStorage.filter, lineItemType: lineItemFilterStorage.lineItemType, priority: lineItemFilterStorage.priority, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, adUnitID: lineItemFilterStorage.AdUnitID }));
      //   } else {
      //     dispatch(onGetLineItem({ ioID: '', filter: lineItemFilterStorage.filter, lineItemType: lineItemFilterStorage.lineItemType, priority: lineItemFilterStorage.priority, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, adUnitID: lineItemFilterStorage.AdUnitID }));
      //   }
      //   console.log(filterCount)
      //   if (filterCount > 0) {
      //     setFilterState(true)
      //   } else {
      //     setFilterState(false)
      //   }
      // }
      if (parsed.ioID) {
        dispatch(onGetLineItem({ ioID: parsed.ioID, filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, adUnitID: adUnitSearch }));
      } else {
        dispatch(onGetLineItem({ ioID: '', filter: statusSearch, lineItemType: lineItemTypeSearch, priority: prioritySearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, adUnitID: adUnitSearch }));
      }
    }
  }, []);

  const lineItemListColumns = [
    {
      text: "ID",
      headerClasses: "ID",
      dataField: "id",
      sort: true,
      hidden: true,
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
      headerClasses: "ID",
      dataField: "lineItemID",
      sort: true,
      headerClasses: "ID",
      formatter: (cellContent, lineItem) => (
        <>
          <span>{lineItem.lineItemID}</span>
        </>
      ),
    },
    {
      dataField: "lineItemModifyTime",
      text: "Modify Date",
      sort: true,
    },
    {
      text: "LI Name",
      dataField: "lineItemName",
      sort: true,
      style: {
        minWidth: "30em"
      },
      formatter: (cellContent, lineItem) => (
        <>

          {(lineItem.lineItemType === 'video' && lineItem.count !== 0) ?
            <Link to={`/Creative?lineItemID=${lineItem.lineItemID}&lineItemName=${lineItem.lineItemName}&creativeType=${lineItem.lineItemType}&status=CreativeCreateByParent`} className="color-dark-blue ">
              {lineItem.lineItemName}
            </Link>
            :
            <span className="font-size-12 mb-1">
              <span className=" mb-1">{lineItem.lineItemName}</span><br />
              {lineItem.lineItemType !== 'video' && lineItem.count !== 0 &&
                <Link to={`/Creative_Weightage?lineItemID=${lineItem.lineItemID}&lineItemName=${lineItem.lineItemName}&creativeType=${lineItem.lineItemType}&status=CreativeCreateByParent`} className="color-dark-blue ">
                  Manage Creative Weightage
                </Link>
              }
              {(lineItem.count === 0 && lineItem.lineItemType !== 'video') ?
                <Link to={`/Creative_Weightage?lineItemID=${lineItem.lineItemID}&lineItemName=${lineItem.lineItemName}&creativeType=${lineItem.lineItemType}&status=CreativeCreateByLineItem`} className=" " style={{ color: 'red' }}>
                  * No Creatives | <span style={{ color: 'blue' }}> + Add Creative </span>
                </Link> : ''}
              {(lineItem.count === 0 && lineItem.lineItemType === 'video') ?
                <Link to={`/Creative?lineItemID=${lineItem.lineItemID}&lineItemName=${lineItem.lineItemName}&creativeType=${lineItem.lineItemType}&status=CreativeCreateByLineItem`} className=" " style={{ color: 'red' }}>
                  * No Creatives | <span style={{ color: 'blue' }}> + Add Creative </span>
                </Link> : ''}
            </span>
          }
        </>
      ),
    },
    {
      dataField: "priority",
      text: "Priority",
      sort: true,
      style: {
        minWidth: "10em"
      },
      formatter: (cellContent, lineItem) => (
        < >
          {lineItem.priority === 'high' &&
            <span className="inactive-state"> High</span>
          }
          {lineItem.priority === 'medium' &&
            <span className="medium-state"> Medium</span>
          }
          {lineItem.priority === 'low' &&
            <span className="active-state">Low</span>
          }
        </>
      ),
    },
    {
      text: "LI Type",
      dataField: "lineItemType",
      sort: false,
      style: {
        minWidth: "15em"
      },
      formatter: (cellContent, lineItem) => (
        <div className=' ' >
          {lineItem.lineItemType === 'video' &&
            <span>Video</span>
          }
          {lineItem.lineItemType === 'vast' &&
            <span>Vast</span>
          }
          {lineItem.lineItemType === 'stdb' &&
            <span>Standard Banner</span>
          }
          {lineItem.lineItemType === 'highImpact' &&
            <span>High Impact</span>
          }
        </div>
      )
    },
    {
      dataField: "lineItemStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, lineItem) => (
        <>
          <span className={lineItem.newStatus === 'Active' ? "active-state" : lineItem.newStatus === 'Inactive' ? "inactive-state" : "archived-state"}>{lineItem.newStatus}</span>
        </>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',
      headerAlign: 'center',
      hidden: (UserRolePrivileges.Demand && UserRolePrivileges.Demand.LineItems) && (!UserRolePrivileges.Demand.LineItems.update),
      formatter: (cellContent, lineItem) => (
        <span  >
          <span className="Email-state " onClick={toggleDrawer(true, lineItem, lineItem.lineItemType, "Edit")}>
            {lineItem.newStatus === 'Archived' &&
              'View'
            }
            {lineItem.newStatus !== 'Archived' &&
              'Edit'
            }
          </span>&nbsp;&nbsp;
          <span className="medium-state " onClick={toggleDrawer(true, lineItem, lineItem.lineItemType, "Duplicate")}>
            Duplicate
          </span>
        </span>
      ),
    },
  ];


  const archiveLineItem = async () => {
    dispatch(onArchiveLineItem({ lineItemIDList: selectedItem, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner }));
  }

  const statusLineItem = (state) => (event) => {
    dispatch(onStatusLineItem({ lineItemIDList: selectedItem, lineItemStatus: state, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner }));
  }

  if (lineItems && lineItems.length > 0) {
    if (UserRolePrivileges.Demand && UserRolePrivileges.Demand.LineItems) {
      if (!UserRolePrivileges.Demand.LineItems.archive && !UserRolePrivileges.Demand.LineItems.status) {
        lineItems.map((item, index) => {
          selectRow.nonSelectable.push(index + 1)
        })
      } else {
        lineItems.map((item, index) => {
          if (item.isLineItemArchived) {
            selectRow.nonSelectable.push(index + 1)
          }
        })
      }
    }
  }

  const paginationChange = async (selectedPages) => {
    console.log(selectedPages)
    pageSize = selectedPages.pageSize
    pageNumber = selectedPages.pageNumber
    searchText = selectedPages.searchText
    if (parsed.ioID) {
      dispatch(onGetLineItem({ ioID: parsed.ioID, filter: statusSearch, lineItemType:  lineItemTypeSearch, priority:  prioritySearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText, adUnitID: adUnitSearch}));
    } else {
      dispatch(onGetLineItem({ ioID: '', filter: statusSearch, lineItemType: lineItemTypeSearch, priority:  prioritySearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText, adUnitID: adUnitSearch }));
    }
  }

  const DemandServiceStatus = JSON.parse(sessionStorage.getItem("DemandServiceDown"));

  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      {DemandServiceStatus ? <ErrorPage></ErrorPage> :
        <div className="page-content">
          <Helmet>
            <title>Line Item | Bidsxchange</title>
          </Helmet>
          <Container fluid={true}>
            {/* Render Breadcrumbs */}
            <div className='row'>
              <div className='col-6 col-xxl-8'>
                {parsed.ioID ? (
                  <Breadcrumbs title="Demand" breadcrumbItem="Line Item" ioID={parsed.ioID} />
                ) : (
                  <Breadcrumbs title="Demand" breadcrumbItem="Line Item" breadList="All Line Items" />
                )
                }
              </div>
              <div className='col-6 col-xxl-4'>
                <div className='row'>
                  <div className="">

                    {UserRolePrivileges.Demand && UserRolePrivileges.Demand.LineItems && UserRolePrivileges.Demand.LineItems.create &&
                      <div>
                        <div className="btn-group">
                          <Dropdown
                            direction="left"
                            isOpen={isMenu}
                            toggle={() => { }}
                          >
                            <DropdownToggle
                              tag="button"
                              onClick={toggleMenu}
                              className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                            >
                              <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                              {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                                Filter Applied
                              </span> : <span className="d-none font-16 d-sm-inline-block">
                                Filter
                              </span>
                              }
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                              <div className="dropdown-item-text">
                                <div className="row">
                                  <h5 className="mb-0">Filters</h5>
                                </div>
                              </div>

                              <DropdownItem divider />
                              <Card>
                                <CardBody aria-hidden="true">
                                  <Row >
                                    <Col sm="12" className="mt-2">
                                      <Label className="form-label">Ad Unit</Label>
                                      <MultiSelect
                                        options={adUnitData}
                                        value={selectedAdUnit}
                                        onChange={handleSelectAdUnit}
                                        overrideStrings={{ "selectSomeItems": "Please Select Ad Unit " }}
                                      />
                                    </Col>
                                    <Col sm="12" className="mt-2">
                                      <Label className="form-label">Priority</Label>
                                      <div className=" text-sm-end">
                                        <Input type="select" className="form-select" value={prioritySearch} onChange={priorityChange}>
                                          <option value=''> Select Priority</option>
                                          <option value=''> All</option>
                                          <option value='high' >High</option>
                                          <option value='medium'> Medium</option>
                                          <option value='low'> Low</option>
                                        </Input>
                                      </div>
                                    </Col>
                                    {UserInventoryType !== 'video' &&
                                      <Col sm="12" className="mt-2">
                                        <Label className="form-label">Line Item Type</Label>
                                        <div className=" text-sm-end">
                                          <Input type="select" className="form-select" value={lineItemTypeSearch} onChange={lineItemTypeChange}>
                                            <option value=''> Select Line Item Type</option>
                                            <option value=''> All</option>
                                            <option value='stdb' >Standard Banner</option>
                                            <option value='highImpact'> High Impact</option>
                                            {UserInventoryType === 'both' &&
                                              <option value='video'> Video</option>
                                            }
                                          </Input>
                                        </div>
                                      </Col>
                                    }
                                    <Col sm="12" className="mt-2">
                                      <Label className="form-label">Status</Label>
                                      <div className=" text-sm-end">
                                        <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                          <option value=''> Select Status</option>
                                          <option value=''> All</option>
                                          <option value='Active' > Active</option>
                                          <option value='Inactive'> InActive</option>
                                          <option value='Archived'> Archived</option>
                                        </Input>
                                      </div>
                                    </Col>
                                    <Col sm="12" className="date-range mt-2">
                                      <Label className="form-label">Filter by Modified Dates</Label>
                                      <div className="">
                                        <DateRangePicker
                                          initialSettings={{
                                            opens: 'left',
                                            startDate: start.toDate(),
                                            endDate: end.toDate(),
                                          }}
                                          onCallback={handleEvent}
                                        >
                                          <button className='datePicker w-100' type="button">
                                            <i className="bx bx-calendar font-size-18 " id="edittooltip" style={{ margin: '3% 3% 2%' }} ></i>{labelDate}
                                          </button>
                                        </DateRangePicker>
                                      </div>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>

                              <DropdownItem divider />
                              <Row>
                                <Col sm="6">
                                  <DropdownItem
                                    className="text-primary text-center"
                                    onClick={clearAllFilter}
                                  >
                                    Clear ALL
                                  </DropdownItem>
                                </Col>
                                <Col sm="6">
                                  <DropdownItem
                                    className="text-primary text-center"
                                    onClick={applyFilter}
                                  >
                                    Apply Filter
                                  </DropdownItem>
                                </Col>
                              </Row>

                            </DropdownMenu>
                          </Dropdown>
                        </div>&nbsp;&nbsp;
                        {(parsed.status === 'LineItemCreateByIO' || parsed.status === 'LineItemCreateByParent') &&
                          <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            onClick={toggleDrawer(true, '', parsed.lineItemType, "Create")}
                          >
                            <i className="mdi mdi-plus-circle-outline me-1" />
                            Add Line Item
                          </Button>
                        }
                        {(!parsed.status) &&
                          <div className="btn-group">
                            {UserInventoryType !== 'video' ?
                              <Dropdown
                                isOpen={btnprimary1}
                                toggle={() => setBtnprimary1(!btnprimary1)}
                              >
                                <DropdownToggle tag="button" className="btn btn-primary">
                                  <i className="mdi mdi-plus-circle-outline me-1" />Add Line Item&nbsp; <i className="mdi mdi-chevron-down" />
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem onClick={toggleDrawer(true, '', 'stdb', "Create")}>Standard Banner</DropdownItem>
                                  <DropdownItem onClick={toggleDrawer(true, '', 'highImpact', "Create")}>High Impact</DropdownItem>
                                  {UserInventoryType === 'both' &&
                                    <DropdownItem onClick={toggleDrawer(true, '', 'video', "Create")}>Video</DropdownItem>
                                  }
                                </DropdownMenu>
                              </Dropdown> : <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                onClick={toggleDrawer(true, '', 'video', "Create")}
                              >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Add Line Item
                              </Button>}
                            &nbsp;&nbsp;
                            <Button
                              color="primary"
                              className="btn btn-danger"
                              onClick={toggleRightCanvass}
                            >
                              <i className="bx bx-unlink me-1" />
                              Detach Line Item
                            </Button>
                          </div>
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <ProductDataTableLI statusAccess={(UserRolePrivileges.Demand && UserRolePrivileges.Demand.LineItems && UserRolePrivileges.Demand.LineItems.status) ? true : false}
              archiveAccess={(UserRolePrivileges.Demand && UserRolePrivileges.Demand.LineItems && UserRolePrivileges.Demand.LineItems.archive) ? true : false} loading={loading} data={lineItems} columns={lineItemListColumns} statusChange={statusLineItem} archiveLineItem={archiveLineItem} rowSelection={selectRow} handleClose={handleClose} selectedIndex={selectedIndex} paginationSelection={paginationChange} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} />

          </Container>
        </div>
      }
      {/* </div> */}

      <Offcanvas isOpen={isRight} direction='end' className="CanvasLarge">
        <OffcanvasHeader toggle={toggleRightCanvas}>
          {selectedData ? 'Edit Line Item' : 'Add ' + lineItemType + ' Line Item '}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Create_LI closeCanvas={toggleDrawer(false, '', '', '')} selectedArchived={isArchived} selectedLineItemCreateStatus={lineItemCreateStatus} selectedLineItem={selectedData} selectedLineItemType={lineItemType} selectedIO={(parsed.status === 'LineItemCreateByIO' || parsed.status === 'LineItemCreateByParent') ? parsed : ''} />
        </OffcanvasBody>
      </Offcanvas>

      <Offcanvas isOpen={isRights} direction='end' className="CanvasLarge">
        <OffcanvasHeader toggle={toggleRightCanvass}>
          {'Detach Line Items'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <DetachLineItem closeCanvas={toggleDrawers(false, '')} />
        </OffcanvasBody>
      </Offcanvas>

    </React.Fragment >
  )
}

export default Line_Items












