/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";
import Select from "react-select";
import axios from "axios";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

import NewImageCreative from './NewImageCreative';
import NewHtmlCreative from './NewHtmlCreative';
import NewCustomCreative from './NewCustomCreative';
import NewThirdParty from './NewThirdParty';
import '../Creative.css'
import NewVideoCreative from "./NewVideoCreative";
import VideoThirdPartyCreative from "./VideoThirdPartyCreative";
import NewAssetVideoCreative from "./NewAssetVideoCreative";


const Create_Creatives = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [templateSelect, setTemplateSelect] = useState('');
    const [CTTemplateSelect, setCTTemplateSelect] = useState('');
    const [creativeType, setCreativeType] = useState({
        creativeTypeName: 'selection',
    });
    const [templatesData, setTemplatesData] = useState([]);
    const [CTTemplatesData, setCTTemplatesData] = useState([]);
    const [creativeData, setCreativeData] = useState();

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name: userInfo.name,
        }
    }

    const creativeChange = (type) => (event) => {
        // console.log(type)
        // console.log(event)

        if (event === 'close') {
            props.drawerClose()
        }
        if (props.selectedCreative !== '' && props.selectedcreativeCreateStatus !== 'Duplicate' ) {
            if (event === 'Update') {
                props.cancelCreative('Update')
                return
            } else {
                props.cancelCreative('')
            }
        }
        if (props.selectedCreative !== '' && props.selectedcreativeCreateStatus === 'Duplicate' ) {
            if (type === 'closeNew') {
                props.cancelCreative('Create')
                return
            } else {
                props.cancelCreative('')
            }
        }
         else {
            if (type === 'closeNew') {
                props.cancelCreative('Create')
            }
            setCreativeType({
                creativeTypeName: type
            });
        }
    };

    const templateChange = (event) => {
        // console.log(event)
        if (event.value) {
            setTemplateSelect(event);
        }
    };

    const CTTemplateChange = (event) => {
        // console.log(event)
        if (event.value) {
            setCTTemplateSelect(event);
        }
    };

    useEffect(() => {
        if (props.selectedCreative !== '') {
            console.log(props.selectedCreative.creativeType)
            if (props.selectedCreative.typeFor === 'Tags') {
                setCreativeType({
                    creativeTypeName: 'thirdPartyVideo'
                });
            }
           else if (props.selectedCreative.typeFor === 'VideoAsset') {
                setCreativeType({
                    creativeTypeName: 'AssetVideo'
                });
            } else {
                setCreativeType({
                    creativeTypeName: (props.selectedCreative.creativeType === "highImpact" || props.selectedCreative.creativeType === "stdbST") ? 'thirdParty' : props.selectedCreative.creativeType
                });
            }
        }
        // getCreativeTemplateList('stdb')
        getCreativeTemplateList('stdbCT')
    }, [])

    const getCreativeTemplateList = (type) => {
        body.url = (userInfo.userRole === 'Partner'|| userInfo.userRole === 'Agency') ? "http://127.0.0.1:3006/v1.0/CreativeTemplates/list" : "/v2.0/operations/CreativeTemplates/list"
        body.requestBody = {
          userID: userInfo.userID,
          userRole: userInfo.userRole,
          companyID: userInfo.companyID,
          name: userInfo.name,
            CreativeTemplateType: type,
            CreativeTemplateFor: "Template"
        }
        if(userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency'){
            body.requestBody  = {...  body.requestBody ,partnerName:userInfo.name,partnerID:userInfo.userID}
        }
          axios.post(body.url, body.requestBody,
            {
                headers: headers
            })
            .then((res) => {
                // console.log("result is ==>", res)
                if (res.data) {
                    // console.log(res.data.response)
                    res.data.response.map((item, index) => {
                        item.id = index + 1
                    })
                    if (type === 'stdb') {
                        setTemplatesData(objectModals(res.data.response))
                    } else {
                        setCTTemplatesData(objectModals(res.data.response))
                    }

                    // console.log(templatesData)
                }
            })
            .catch((err) => {
                // console.log("e", err)
            });
    }

    const objectModals = (response) => {
        var dataList = []
        response.map((item, index) => {
            let obj = {}
            obj.value = item.creativeTemplateName
            obj.label = item.creativeTemplateName
            dataList.push(obj)
        })
        return dataList
    }

    return (
        <React.Fragment>

                <div className="content">
                    {creativeType.creativeTypeName === 'selection' &&

                            <div className="row mt-4 creative-selections" >
                                {(props.selectedCreativeType === 'stdb' || props.selectedCreativeType === 'highImpact') &&
                                    <div className="col-md-4 mt-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">Asset </h5>
                                                <p className="card-text mb-0">Upload Assets.</p>
                                                <hr></hr>
                                                <b>Types :</b> GIF, JPG, PNG, JPEG
                                                <button className="btn btn-primary" onClick={creativeChange('image')}>Create Asset</button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {props.selectedCreativeType === 'video' &&
                                    <div className="col-md-4 mt-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">Custom Video Template</h5>
                                                <button className="btn btn-primary" onClick={creativeChange('video')}>Create Custom Video Template</button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="col-md-2"></div>
                                {(props.selectedCreativeType === 'stdb' || props.selectedCreativeType === 'highImpact') &&
                                    <div className="col-md-4 mt-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">HTML Creative</h5>
                                                <p className="card-text mb-0">Upload an HTML5 zip file to create rich ad experiences.</p>
                                                <hr></hr>
                                                <b>Types :</b> ZIP file <br></br>
                                                <button className="btn btn-primary" onClick={creativeChange('html')}>Create HTML Creative</button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {props.selectedCreativeType === 'highImpact' &&
                                    <div className="col-md-4 mt-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">Third Party Creative</h5>
                                                <p className="card-text">Use code from third-party ad servers.</p>
                                                <hr></hr>
                                                <b>Assets :</b>Third Party Code<br></br>
                                                <button className="btn btn-primary" onClick={creativeChange('thirdParty')}>Create Third Party Creative</button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {(props.selectedCreativeType === 'stdb' || props.selectedCreativeType === 'highImpact') && <div className="col-md-2"></div>}
                                {(props.selectedCreativeType === 'stdb') &&
                                    <div className="col-md-4 mt-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">Third Party Creative </h5>
                                                <p className="card-text">Use code for Third-party Standard Banner ad servers.</p>
                                                <hr></hr>
                                                <b>Assets :</b>Third Party Code<br></br>
                                                <button className="btn btn-primary" onClick={creativeChange('thirdParty')}>Create Third Party Creative</button>
                                                {/* <div className="mb-3">
                                                    <Select
                                                        value={templateSelect}
                                                        onChange={s => {
                                                            templateChange(s)
                                                        }}
                                                        options={templatesData}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                {templateSelect &&
                                                    <button className="btn btn-primary" onClick={creativeChange('stdb')}>Continue</button>}
                                             */}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/* {(props.selectedCreativeType === 'stdb' || props.selectedCreativeType === 'highImpact') && <div className="col-md-2"></div>}
                                {(props.selectedCreativeType === 'stdb') &&
                                    <div className="col-md-4 mt-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">Custom Creative Template</h5>
                                                <p className="card-text">Use templates defined in your network.</p>
                                                <div className="mb-3">
                                                    <Select
                                                        value={CTTemplateSelect}
                                                        onChange={s => {
                                                            CTTemplateChange(s)
                                                        }}
                                                        options={CTTemplatesData}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                {CTTemplateSelect &&
                                                    <button className="btn btn-primary" onClick={creativeChange('stdb')}>Continue</button>}
                                            </div>
                                        </div>
                                    </div>
                                } */}
                                {props.selectedCreativeType === 'video' &&
                                    <div className="col-md-4 mt-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">Third Party Video Creative</h5>
                                                <button className="btn btn-primary" onClick={creativeChange('thirdPartyVideo')}>Create Third Party Video Creative Template</button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/* {props.selectedCreativeType === 'video' &&
                                    <div className="col-md-5 mt-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">Asset </h5>
                                                <p className="card-text mb-0">Upload Assets.</p>
                                                <hr></hr>
                                                <b>Types :</b> Video,PlayLists from Content Suite
                                                <button className="btn btn-primary" onClick={creativeChange('AssetVideo')}>Create Asset Video Creative</button>
                                            </div>
                                        </div>
                                    </div>
                                } */}
                            </div>
               
                    }
                    {creativeType.creativeTypeName === 'image' &&
                        <NewImageCreative selectedArchived={props.selectedArchived} selectedcreativeCreateStatus={props.selectedcreativeCreateStatus} creativeTypeClose={creativeChange('selection')} selectedCreativeData={props.selectedCreative} creativeNewClose={creativeChange('closeNew')} creativeType={props.selectedCreativeType || props.selectedCreative.creativeType} selectedLineItem={props.selectedLineItem} />}
                    {creativeType.creativeTypeName === 'html' &&
                        <NewHtmlCreative selectedArchived={props.selectedArchived} selectedcreativeCreateStatus={props.selectedcreativeCreateStatus} creativeTypeClose={creativeChange('selection')} selectedCreativeData={props.selectedCreative} creativeNewClose={creativeChange('closeNew')} creativeType={props.selectedCreativeType || props.selectedCreative.creativeType} selectedLineItem={props.selectedLineItem} />}
                    {creativeType.creativeTypeName === 'thirdParty' &&
                        <NewThirdParty selectedArchived={props.selectedArchived} selectedcreativeCreateStatus={props.selectedcreativeCreateStatus} creativeTypeClose={creativeChange('selection')} selectedCreativeData={props.selectedCreative} creativeNewClose={creativeChange('closeNew')} creativeType={props.selectedCreativeType || props.selectedCreative.creativeType} selectedLineItem={props.selectedLineItem} />}
                    {(creativeType.creativeTypeName === 'stdb') &&
                        <NewCustomCreative selectedArchived={props.selectedArchived} selectedcreativeCreateStatus={props.selectedcreativeCreateStatus} creativeTypeClose={creativeChange('selection')} selectedCreativeData={props.selectedCreative} creativeNewClose={creativeChange('closeNew')} selectedTemplate={templateSelect || CTTemplateSelect} creativeType={props.selectedCreativeType || props.selectedCreative.creativeType} selectedLineItem={props.selectedLineItem} />}
                    {(creativeType.creativeTypeName === 'video' || creativeType.creativeTypeName === 'play') &&
                        <NewVideoCreative selectedArchived={props.selectedArchived} selectedcreativeCreateStatus={props.selectedcreativeCreateStatus} creativeTypeClose={creativeChange('selection')} selectedCreativeData={props.selectedCreative} creativeNewClose={creativeChange('closeNew')} creativeType='video' selectedLineItem={props.selectedLineItem} />}
                    {(creativeType.creativeTypeName === 'thirdPartyVideo' || creativeType.creativeTypeName === 'play') &&
                        <VideoThirdPartyCreative selectedArchived={props.selectedArchived}  selectedcreativeCreateStatus={props.selectedcreativeCreateStatus} creativeTypeClose={creativeChange('selection')} selectedCreativeData={props.selectedCreative} creativeNewClose={creativeChange('closeNew')} creativeType='video' selectedLineItem={props.selectedLineItem} />}
                    {(creativeType.creativeTypeName === 'AssetVideo' || creativeType.creativeTypeName === 'play') &&
                        <NewAssetVideoCreative selectedArchived={props.selectedArchived} selectedcreativeCreateStatus={props.selectedcreativeCreateStatus} creativeTypeClose={creativeChange('selection')} selectedCreativeData={props.selectedCreative} creativeNewClose={creativeChange('closeNew')} creativeType='video' selectedLineItem={props.selectedLineItem} />}
                </div>

    
        </React.Fragment>
    );
};

export default Create_Creatives;