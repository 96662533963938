import axios from "axios";
import { del, get, post, put } from "./api_helper";
import { authPost } from "./Auth_API_Helper";
import { demandPost,houseAdsPost,supplyPost,reportPost,contextualSettingsPost,supportTicketPost,
  partnerManagementPost,partnerManagementCreatePost,userManagementPost } from "./Demand_API_Helper";
import { APICallRequest } from "./Demand_API_Helper";
import { toolsPost } from "./Tools_API_Helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};



//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data);

// Login Method
const postLogin = data => authPost(url.POST_LOGIN, data);

// Register Method
const postRegister = data => authPost(url.POST_REGISTER, data);

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data);

// postForgetPwd Method
const postForgetPwd = data => authPost(url.POST_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data);

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data);

// get Products
export const getProducts = () => get(url.GET_PRODUCTS);

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } });

// get Events
export const getEvents = () => get(url.GET_EVENTS);

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event);

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event);

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } });

// // get Categories
// export const getCategories = () => get(url.GET_CATEGORIES);

// get chats
export const getChats = () => get(url.GET_CHATS);

// get groups
export const getGroups = () => get(url.GET_GROUPS);

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS);

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } });

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message);

// get orders
export const getOrders = () => get(url.GET_ORDERS);

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order);

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order);

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } });

// get cart data
export const getCartData = () => get(url.GET_CART_DATA);

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS);

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer);

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer);

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } });

// get shops
export const getShops = () => get(url.GET_SHOPS);

// get wallet
export const getWallet = () => get(url.GET_WALLET);

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS);

// get invoices
export const getInvoices = () => get(url.GET_INVOICES);

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } });

// get project
export const getProjects = () => get(url.GET_PROJECTS);

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } });

// get tasks
export const getTasks = () => get(url.GET_TASKS);

// get contacts
export const getUsers = () => get(url.GET_USERS);

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user);

// update user
export const updateUser = user => put(url.UPDATE_USER, user);

// delete user
export const deleteUser = user => del(url.DELETE_USER, { headers: { user } });

/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project);

// update user
export const updateProject = project => put(url.UPDATE_PROJECT, project);

// delete user
export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } });

export const getUserProfile = () => get(url.GET_USER_PROFILE);

// get inboxmail
export const getInboxMails = () => get(url.GET_INBOX_MAILS);

// add inboxmail
export const addNewInboxMail = inboxmail =>
  post(url.ADD_NEW_INBOX_MAIL, inboxmail);

// delete inboxmail
export const deleteInboxMail = inboxmail =>
  del(url.DELETE_INBOX_MAIL, { headers: { inboxmail } });

// get starredmail
export const getStarredMails = () => get(url.GET_STARRED_MAILS);

// get importantmail
export const getImportantMails = () => get(url.GET_IMPORTANT_MAILS);

// get sent mail
export const getSentMails = () => get(url.GET_SENT_MAILS);

// get trash mail
export const getTrashMails = () => get(url.GET_TRASH_MAILS);

// get starredmail
export const getDraftMails = () => get(url.GET_DRAFT_MAILS);

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA);
export const getYearlyData = () => get(url.GET_YEARLY_DATA);
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA);

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } });

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } });

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS);

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  });
};
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  });
};

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  });
};

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  });
};

//Demand : Advertiser

export const getAPICalls = data => APICallRequest();



//Partner Management

// get Partners
export const getPartners = data => partnerManagementPost(data.userRoleParent === 'SupplyPartner' ? `/v1.0/SupplypartnerListFull?page=${data.pageNumber ? data.pageNumber : 0}&size=${data.pageSize ? data.pageSize : 0}` : data.userRole === 'SupplyPartner' ? "/v1.0/SupplypartnerSubList" : `/v1.0/partnerListFull?page=${data.pageNumber ? data.pageNumber : 0}&size=${data.pageSize ? data.pageSize : 0}` ,data,"PartnerProfiles");
// get Prtners on Type
export const getPartnersOnType = data => contextualSettingsPost(data.userRoleParent === 'SupplyPartner' ? "/v2.0/supplyPartner/partner/listOnType" : "/v2.0/supply/partner/listOnType",data,"PartnerProfiles");
// Create Partners 
export const createPartner = data => partnerManagementCreatePost(data.userRole === 'SupplyPartner' ? "/v1.0/createSupplyPartner" : data.userRoleParent === 'SupplyPartner' ? "/v1.0/createSupplyPartnerSubPartner" :'/v1.0/createPartner', data);
// Update Partners 
export const updatePartner = data => partnerManagementCreatePost(data.userRole === 'SupplyPartner' ? "/v1.0/UpdateSupplyPartner/"+ data.partnerID : data.userRoleParent === 'SupplyPartner' ? "/v1.0/UpdateSupplyPartnerSubPartner/"  + data.partnerID :"/v1.0/updatePartner/" + data.partnerID, data);
// Read By Id Partners 
export const readPartner = data => partnerManagementPost("/v1.0/partner/" + data.partnerID);
// StatusChange of Partners 
export const statusChangePartner = data => partnerManagementCreatePost(data.userRoleParent === 'SupplyPartner' ? "/v1.0/updateSupplyPartnerStatus" : data.userRole === 'SupplyPartner' ? "/v1.0/updateSubPartnerStatus/BySupplyPartner" : "/v1.0/updateUserStatus" ,data);
// Archive Partners 
export const archivePartner = data => partnerManagementCreatePost(data.userRole === 'SupplyPartner' ? "/v1.0/SubPartnerArchive/BySupplyPartner" : "/v1.0/usersArchive" , data);
// Get Users
export const getUserList = data => userManagementPost(data.userRole === 'SupplyPartner' ? `/v1.0/usersList/BySupplayPartner?page=${data.pageNumber ? data.pageNumber : 0}&size=${data.pageSize ? data.pageSize : 0}` :  `/v1.0/usersList?page=${data.pageNumber ? data.pageNumber : 0}&size=${data.pageSize ? data.pageSize : 0}`,data,"Users");
// Create Users 
export const createUsers = data => partnerManagementCreatePost(data.userInfo.userRole === 'SupplyPartner' ? "/v1.0/createUsers/BySupplyPartner" :'/v1.0/createUsers', data);
// Update Users 
export const updateUsers = data => partnerManagementCreatePost(data.userInfo.userRole === 'SupplyPartner' ? "/v1.0/updateUsers/BySupplyPartner/"+ data.userID : "/v1.0/updateUsers/" + data.userID, data);

//Roles Management

// get Roles
export const getRoles = data => userManagementPost(data.userRole === 'SupplyPartner' ? "/v1.0/AMRole/SupplyPartner/list" :"/v1.0/AMRole/list",data,"Roles");
// Create Roles 
export const createRole = data => userManagementPost(data.userRole === 'SupplyPartner' ? "/v1.0/AMRole/SupplyPartner/create" :'/v1.0/AMRole/create', data);
// Update Roles 
export const updateRole = data => userManagementPost(data.userRole === 'SupplyPartner' ? "/v1.0/AMRole/SupplyPartner/update/" + data.roleID:"/v1.0/AMRole/update/" + data.roleID, data);
// Read By Id Roles 
export const readRole = data => userManagementPost(data.userRole === 'SupplyPartner' ? "/v1.0/AMRole/SupplyPartner/read/" + data.roleID :"/v1.0/AMRole/read/" + data.roleID);
// StatusChange of Roles 
export const statusChangeRole = data => userManagementPost(data.userRole === 'SupplyPartner' ? "/v1.0/AMRole/SupplyPartner/status" : "/v1.0/AMRole/status" ,data);
// Archive Roles 
export const archiveRole = data => userManagementPost(data.userRole === 'SupplyPartner' ? "/v1.0/AMRole/SupplyPartner/archive" : "/v1.0/AMRole/archive" , data);

//Demand : Advertisers

// get Advertisers
export const getAdvertisers = data => demandPost(data.userRole === 'SupplyPartner' ? `/v2.0/demandSupplyPartner/Advertisers/read/SupplyPartner?page=${data.pageNumber}&size=${data.pageSize}` : data.userRole === 'Partner' ? '/v2.0/demand/Partner/Advertisers/read' :`/v2.0/demand/Advertisers/read?page=${data.pageNumber}&size=${data.pageSize}`,data,"Advertisers");
// get Advertisers on Type
export const getAdvertisersType = data => contextualSettingsPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/advertiser/listOnType/SupplyPartner' : "/v2.0/demand/advertiser/listOnType",data,"Advertisers");
// Create Advertiser 
export const postAdvertiser = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/Advertisers/create/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/demand/Partner/Advertisers/create' : "/v2.0/demand/Advertisers/create", data);
// Update Advertiser 
export const updateAdvertiser = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/Advertisers/update/SupplyPartner/'+ data.advertiserID  : data.userRole === 'Partner' ? '/v2.0/demand/Partner/Advertisers/update/'+ data.advertiserID : "/v2.0/demand/Advertisers/update/" + data.advertiserID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Read By Id Advertiser 
export const readAdvertiser = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demand/Partner/Advertisers/read' : "/v2.0/demand/Advertisers/readByID/" + data.advertiserID);
// StatusChange of Advertiser 
export const statusChangeAdvertiser = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/Advertisers/status/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/demand/Partner/Advertisers/status' : "/v2.0/demand/Advertisers/status",data);
// Archive Advertiser 
export const archiveAdvertiser = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/Advertisers/archive/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/demand/Partner/Advertisers/archive' : "/v2.0/demand/Advertisers/archive" , data);

//Demand : IOGroup

// get IOGroup
export const getIOGroup = data => demandPost(data.userRole === 'SupplyPartner' ?  `/v2.0/demandSupplyPartner/IOGroups/read/SupplyPartner?page=${data.pageNumber}&size=${data.pageSize}` : data.userRole === 'Partner' ? '/v2.0/demand/Partner/IOGroups/read' : `/v2.0/demand/IOGroups/read?page=${data.pageNumber}&size=${data.pageSize}`,data,"IOGroups");
// get IOGroup by Advertiser
export const getIOGroupAdvertiser = data => demandPost(data.userRole === 'SupplyPartner' ? `/v2.0/demandSupplyPartner/IOGroups/read/advertiser/SupplyPartner/${data.advertiserID}?page=${data.pageNumber}&size=${data.pageSize}` :`/v2.0/demand/IOGroups/read/advertiser/${data.advertiserID}?page=${data.pageNumber}&size=${data.pageSize}`,data,"IOGroups");
// get IOGroup by type
export const getIOGroupType = data => contextualSettingsPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/ioGroup/listOnType/SupplyPartner' :'/v2.0/demand/ioGroup/listOnType',data,"IOGroups");
// Create IOGroup 
export const createIOGroup = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/IOGroups/create/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/demand/Partner/IOGroups/create' :'/v2.0/demand/IOGroups/create', data);
// Update IOGroup 
export const updateIOGroup = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/IOGroups/update/SupplyPartner/' + data.ioGroupID  : data.userRole === 'Partner' ? '/v2.0/demand/Partner/IOGroups/update/' + data.ioGroupID :"/v2.0/demand/IOGroups/update/" + data.ioGroupID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Read By Id IOGroup 
export const readIOGroup = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demand/Partner/Advertisers/read' :"/v2.0/demand/IOGroups/readByID/" + data.ioGroupID);
// StatusChange of IOGroup 
export const statusChangeIOGroup = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/IOGroups/status/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/demand/Partner/IOGroups/status' :"/v2.0/demand/IOGroups/status",data);
// Archive IOGroup 
export const archiveIOGroup = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/IOGroups/archive/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/demand/Partner/IOGroups/archive' :"/v2.0/demand/IOGroups/archive" , data);


//Demand : Bidders

// get Bidder
export const getBidder = data => demandPost('/v2.0/operations/Bidders/read',data);
// Create Bidder 
export const createBidder = data => demandPost('/v2.0/operations/Bidders/create', data);
// Update Bidder 
export const updateBidder = data => demandPost("/v2.0/operations/Bidders/update/" + data.bidderID, data);
// Read By Id Bidder 
export const readBidder = data => demandPost("/v2.0/operations/Bidders/readByID/" + data.bidderID);
// StatusChange of Bidder 
export const statusChangeBidder = data => demandPost("/v2.0/operations/Bidders/status",data);
// Archive Bidder 
export const archiveBidder = data => demandPost("/v2.0/operations/Bidders/archive" , data);

//Demand : IOs

// get IOs
export const getIOs = data => demandPost(data.userRole === 'SupplyPartner' ?  `/v2.0/demandSupplyPartner/IOs/read/SupplyPartner?page=${data.pageNumber}&size=${data.pageSize}` : data.userRole === 'Partner' ? '/v2.0/demand/Partner/IOs/read' : `/v2.0/demand/IOs/read?page=${data.pageNumber}&size=${data.pageSize}`, data,"IOs");
// get IOs by IOGroups
export const getIOsByIOG = data => demandPost(data.userRole === 'SupplyPartner' ? `/v2.0/demandSupplyPartner/IOs/read/ioGroup/SupplyPartner/${data.ioGroupID}?page=${data.pageNumber}&size=${data.pageSize}` :`/v2.0/demand/IOs/read/ioGroup/${data.ioGroupID}?page=${data.pageNumber}&size=${data.pageSize}`,data,"IOs");
// get IOs by type
export const getIOsType = data => contextualSettingsPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/IOs/listOnType/SupplyPartner' : '/v2.0/demand/IOs/listOnType', data,"IOs");
// Create IOs 
export const createIOs = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/IOs/create/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/demand/Partner/IOs/create' : '/v2.0/demand/IOs/create', data);
// Update IOs 
export const updateIOs = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/IOs/update/SupplyPartner/'+ data.ioID  : data.userRole === 'Partner' ? '/v2.0/demand/Partner/IOs/update/'+ data.ioID : "/v2.0/demand/IOs/update/" + data.ioID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data );
// Read By Id IOs 
export const readIOs = data => demandPost(data.userRole === 'Partner' ? '/v2.0/demand/Partner/IOs/read' : "/v2.0/demand/IOs/readByID/" + data.ioID);
// StatusChange of IOs 
export const statusChangeIOs = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/IOs/status/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/demand/Partner/IOs/status' : "/v2.0/demand/IOs/status" ,data);
// Archive IOs 
export const archiveIOs = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/IOs/archive/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/demand/Partner/IOs/archive' : "/v2.0/demand/IOs/archive" ,data);

//Demand : LineItem
// get LineItem
export const getLineItem = data => demandPost(data.userRole === 'SupplyPartner' ?  `/v2.0/demandSupplyPartner/LineItems/read/SupplyPartner?page=${data.pageNumber}&size=${data.pageSize}` : data.userRole === 'Partner' ? `/v2.0/demand/Partner/LineItems/read` : `/v2.0/demand/LineItems/read?page=${data.pageNumber}&size=${data.pageSize}`,data,"LineItems");
// get LineItem by IOS
export const getLineItemByIOs = data => demandPost(data.userRole === 'SupplyPartner' ? `/v2.0/demandSupplyPartner/LineItems/read/IO/SupplyPartner/${data.ioID}?page=${data.pageNumber}&size=${data.pageSize}` : `/v2.0/demand/LineItems/read/IO/${data.ioID}?page=${data.pageNumber}&size=${data.pageSize}`,data,"LineItems");
// get LineItem by Type
export const getLineItemType = data => demandPost('/v2.0/demand/LineItems/list/lineItemType',data,"LineItems");
// get LineItem by Display 
export const getLineItemSTDB = data => demandPost('/v2.0/demand/LineItems/list/display');
// get LineItem by RichMedia
export const getLineItemRCM = data => demandPost('/v2.0/demand/LineItems/list/richMedia');
// get LineItem by Video
export const getLineItemVideo = data => demandPost('/v2.0/demand/LineItems/list/video');
// Create LineItem 
export const createLineItem = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/LineItems/create/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/demand/Partner/LineItems/create' : '/v2.0/demand/LineItems/create', data);
// Update LineItem 
export const updateLineItem = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/LineItems/update/SupplyPartner/'+ data.lineItemID  : data.userRole === 'Partner' ? '/v2.0/demand/Partner/LineItems/update/'  + data.lineItemID : "/v2.0/demand/LineItems/update/" + data.lineItemID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Read By Id LineItem 
export const readLineItem = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demand/Partner/LineItems/create' : data.userRole === 'Partner' ? '/v2.0/demand/Partner/IOGroups/read' : "/v2.0/demand/LineItems/readByID/" + data.lineItemID);
// StatusChange of LineItem 
export const statusChangeLineItem = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/LineItems/status/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/demand/Partner/LineItems/status' : "/v2.0/demand/LineItems/status" ,data);
// Archive LineItem 
export const archiveLineItem = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/LineItems/archive/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/demand/Partner/LineItems/archive' : "/v2.0/demand/LineItems/archive" , data);

//Demand : Creative

// get Creative
export const getCreative = data => demandPost(data.userRole === 'SupplyPartner' ? `/v2.0/demandSupplyPartner/Creatives/read/both/SupplyPartner?page=${data.pageNumber}&size=${data.pageSize}` : `/v2.0/demand/Creatives/read/both?page=${data.pageNumber}&size=${data.pageSize}`,data,"Creatives");
// get Creative by Video line Item
export const getCreativeByVideoLineItem = data => demandPost(data.userRole === 'SupplyPartner' ? `/v2.0/demandSupplyPartner/VideoCreative/lineitem/SupplyPartner/${data.lineItemID}?page=${data.pageNumber}&size=${data.pageSize}` :`/v2.0/demand/VideoCreative/lineitem/${data.lineItemID}?page=${data.pageNumber}&size=${data.pageSize}`,"Creatives");
// Create Creative 
export const createCreative = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/Creatives/create/SupplyPartner' : '/v2.0/demand/Creatives/create', data);
// Create Video Creative 
export const createVideoCreative = data => contextualSettingsPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/VideoCreatives/create/SupplyPartner' : '/v2.0/demand/VideoCreative/create', data);
// Update Creative 
export const updateCreative = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/Creatives/update/SupplyPartner/'+ data.creativeID : "/v2.0/demand/Creatives/update/" + data.creativeID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Update Video Creative 
export const updateVideoCreative = data => contextualSettingsPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/VideoCreatives/update/SupplyPartner/'+ data.videoCreativeID : "/v2.0/demand/VideoCreative/update/" + data.videoCreativeID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Read By Id Creative 
export const readCreative = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/Creatives/read/SupplyPartner/' + data.creativeID: "/v2.0/demand/Creatives/read/" + data.creativeID);
// Read By Id Video Creative 
export const readVideoCreative = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/VideoCreatives/read/SupplyPartner/'+ data.creativeID : "http://127.0.0.1:2002//v1.0/VideoCreative/read/" + data.creativeID);
// StatusChange of Creative 
export const statusChangeCreative = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/Creatives/status/SupplyPartner' : "/v2.0/demand/Creatives/status" ,data);
// StatusChange of VideoCreative 
export const statusChangeVideoCreative = data => contextualSettingsPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/VideoCreatives/status/SupplyPartner' : "/v2.0/demand/VideoCreative/status" ,data);
// Archive Creative 
export const archiveCreative = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/Creatives/archive/SupplyPartner' : "/v2.0/demand/Creatives/archive" , data);
// Video Archive Creative 
export const videoArchiveCreative = data => contextualSettingsPost(data.userRole === 'SupplyPartner' ? '/v2.0/demandSupplyPartner/VideoCreatives/archive/SupplyPartner' : "/v2.0/demand/VideoCreative/archive" , data);

//Demand : Tag Controller

// get Tag Controller
export const getTcs = data => demandPost("/v2.0/BX/TCs/read",data);
// Create Tag Controller 
export const postTc = data => demandPost("/v2.0/BX/TCs/create", data);
// Update Tag Controller 
export const updateTc = data => demandPost("/v2.0/BX/TCs/update/" + data.tcID, data);
// Read By Id Tag Controller 
export const readTc = data => demandPost("/v2.0/BX/TCs/readByID/" + data);
// StatusChange of Tag Controller 
export const statusChangeTc = data => demandPost("/v2.0/BX/TCs/status",data);
// Archive Tag Controller 
export const archiveTc = data => demandPost("/v2.0/BX/TCs/archive" , data);

//Demand : Hb Buyer

// get Hb Buyer
export const getHeaderBid = data => demandPost(data.userRole === 'SupplyPartner' ? `/v2.0/headderBiddingSupplyPartner/HB_Buyers/read/SupplyPartner?page=${data.pageNumber}&size=${data.pageSize}` : data.structure === 'old' ? `/OP/HB_Buyers/read?page=${data.pageNumber}&size=${data.pageSize}` : `/v2.0/headderBidding/HB_Buyers/read?page=${data.pageNumber}&size=${data.pageSize}`,data);
// Create Hb Buyer 
export const createHeaderBid = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/headderBiddingSupplyPartner/HB_Buyers/create/SupplyPartner' : data.structure === 'old' ? "/OP/HB_Buyers/create" : "/v2.0/headderBidding/HB_Buyers/create", data);
// Update Hb Buyer
export const updateHeaderBid = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/headderBiddingSupplyPartner/HB_Buyers/update/SupplyPartner/'+ data.hbBuyerID : data.structure === 'old' ? "/OP/HB_Buyers/update/" + data.hbBuyerID :  "/v2.0/headderBidding/HB_Buyers/update/" + data.hbBuyerID, data.userRole === 'SupplyPartner' ? {...data,createdByPartner:true} : data);
// Update Hb Buyer
export const updateAdUnitBid = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/headderBiddingSupplyPartner/HB_Buyers/AssociateAdUnit/SupplyPartner/'+ data.hbBuyerID : data.structure === 'old' ? "/OP/HB_Buyers/AssociateAdUnit/" + data.hbBuyerID : "/v2.0/headderBidding/HB_Buyers/AssociateAdUnit/" + data.hbBuyerID, data.userRole === 'SupplyPartner' ? {...data,createdByPartner:true} : data);
// Read By Id Hb Buyer 
export const readHeaderBid = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/headderBidding/HB_Buyers/readByID' :   "/v2.0/headderBidding/HB_Buyers/readByID/" + data);
// StatusChange of Hb Buyer
export const statusChangeHeaderBid = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/headderBiddingSupplyPartner/HB_Buyers/update/status/SupplyPartner' : data.structure === 'old' ? "/OP/HB_Buyers/status" : "/v2.0/headderBidding/HB_Buyers/status",data);
// Archive Hb Buyer
export const archiveHeaderBid = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/headderBiddingSupplyPartner/HB_Buyers/archive/SupplyPartner' : data.structure === 'old' ? "/OP/HB_Buyers/archive" :  "/v2.0/headderBidding/HB_Buyers/archive" , data);

//Supply : Publisher

// get Publisher
export const getPublisher = data => supplyPost(data.userRole === 'SupplyPartner' ? `/v2.0/supplyPartner/Publishers/read/SupplyPartner?page=${data.pageNumber}&size=${data.pageSize}` : (data.userRole === 'Partner' || data.userRole === 'PartnerSubProfile') ? `/v2.0/supply/Partner/Publishers/read/${data.userID}?page=${data.pageNumber}&size=${data.pageSize}` : `/v2.0/supply/Publishers/read?page=${data.pageNumber}&size=${data.pageSize}`,data,"Publishers");
// get Publisher by type
export const getPublisherByType = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/Publishers/listOnType/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/supply/Partner/Publishers/listOnType' : '/v2.0/supply/Publishers/listOnType',data,"Publishers");
// get Publishers by Partners
export const getPublishersByPartner = data => supplyPost(data.userRole === 'SupplyPartner' ?  `/v2.0/supplyPartner/Publishers/read/partner/SupplyPartner/${data.partnerID}?page=${data.pageNumber}&size=${data.pageSize}` : `/v2.0/supply/Publishers/read/partner/${data.partnerID}?page=${data.pageNumber}&size=${data.pageSize}`,data,"Publishers");
// Create Publisher 
export const createPublisher = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/Publishers/create/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/supply/Partner/Publishers/create' : '/v2.0/supply/Publishers/create', data);
// Update Publisher 
export const updatePublisher = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/Publishers/update/SupplyPartner/'+ data.publisherID  : data.userRole === 'Partner' ? '/v2.0/supply/Partner/Publishers/update/'+ data.publisherID : "/v2.0/supply/Publishers/update/" + data.publisherID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Read By Id Publisher 
export const readPublisher = data => supplyPost("/v2.0/supply/Publishers/readByID/" + data.publisherID);
// StatusChange of Publisher 
export const statusChangePublisher = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/Publishers/status/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/supply/Partner/Publishers/status' : "/v2.0/supply/Publishers/status" ,data);
// Archive Publisher 
export const archivePublisher = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/Publishers/archive/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/supply/Partner/Publishers/archive' :"/v2.0/supply/Publishers/archive" , data);

//Supply : AdUnitGroup
// get AdUnitGroup
export const getAdUnitGroup = data => supplyPost(data.userRole === 'SupplyPartner' ? `/v2.0/supplyPartner/AdUnitGroups/read/SupplyPartner?page=${data.pageNumber}&size=${data.pageSize}` : (data.userRole === 'Partner' || data.userRole === 'PartnerSubProfile')  ? `/v2.0/supply/Partner/AdUnitGroups/read/${data.userID}?page=${data.pageNumber}&size=${data.pageSize}` : `/v2.0/supply/AdUnitGroups/read?page=${data.pageNumber}&size=${data.pageSize}`,data,"AdUnitGroups");
// get AdUnitGroups by Publisher
export const getAdUnitGroupsByPublisher = data => supplyPost(data.userRole === 'SupplyPartner' ?  `/v2.0/supplyPartner/AdUnitGroups/read/publisher/SupplyPartner/${data.publisherID}?page=${data.pageNumber}&size=${data.pageSize}` :  data.userRole === 'Partner' ?  `/v2.0/supply/Partner/AdUnitGroups/read/publisher/${data.publisherID}?page=${data.pageNumber}&size=${data.pageSize}` : `/v2.0/supply/AdUnitGroups/read/publisher/${data.publisherID}?page=${data.pageNumber}&size=${data.pageSize}`,data,"AdUnitGroups");
// Create AdUnitGroup 
export const createAdUnitGroup = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/AdUnitGroups/create/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/supply/Partner/AdUnitGroups/create' : '/v2.0/supply/AdUnitGroups/create', data);
// Update AdUnitGroup 
export const updateAdUnitGroup = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/AdUnitGroups/update/SupplyPartner/'+ data.adUnitGroupID  : data.userRole === 'Partner' ? '/v2.0/supply/Partner/AdUnitGroups/update/'+ data.adUnitGroupID : "/v2.0/supply/AdUnitGroups/update/" + data.adUnitGroupID,  data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Read By Id AdUnitGroupadUnitGroupID
export const readAdUnitGroup = data => supplyPost("/v2.0/supply/AdUnitGroups/readByID/" + data.adUnitGroupID);
// StatusChange of AdUnitGroup 
export const statusChangeAdUnitGroup = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/AdUnitGroups/status/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/supply/Partner/AdUnitGroups/status' : "/v2.0/supply/AdUnitGroups/status" ,data);
// Archive AdUnitGroup 
export const archiveAdUnitGroup = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/AdUnitGroups/archive/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/supply/Partner/AdUnitGroups/archive' : "/v2.0/supply/AdUnitGroups/archive" , data);

//Supply : AdUnit
     
// get AdUnit
export const getAdUnit = data => supplyPost(data.userRole === 'SupplyPartner' ? `/v2.0/supplyPartner/AdUnits/read/SupplyPartner?page=${data.pageNumber}&size=${data.pageSize}` : (data.userRole === 'Partner' || data.userRole === 'PartnerSubProfile')  ? `/v2.0/supply/Partner/AdUnit/read/${data.userID}?page=${data.pageNumber}&size=${data.pageSize}` : `/v2.0/supply/AdUnits/read?page=${data.pageNumber}&size=${data.pageSize}`,data,"AdUnits");
// get AdUnits by AdUnitGroup
export const getAdUnitsByAdUnitGroup = data => supplyPost(data.userRole === 'SupplyPartner' ?  `/v2.0/supplyPartner/AdUnits/read/byAdunitGroupID/SupplyPartner/${data.adUnitGroupID}?page=${data.pageNumber}&size=${data.pageSize}` :  data.userRole === 'Partner' ? `/v2.0/supply/Partner/AdUnits/read/byAdunitGroupID/${data.adUnitGroupID}?page=${data.pageNumber}&size=${data.pageSize}` : `/v2.0/supply/AdUnits/read/byAdunitGroupID/${data.adUnitGroupID}?page=${data.pageNumber}&size=${data.pageSize}`,data,"AdUnits");
// Create AdUnit
export const createAdUnit = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/AdUnits/create/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/supply/Partner/AdUnits/create' : '/v2.0/supply/AdUnits/create', data);
// Update AdUnitGroup 
export const updateAdUnit = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/AdUnits/update/SupplyPartner/' +data.adUnitID : data.userRole === 'Partner' ? '/v2.0/supply/Partner/AdUnits/update/' +data.adUnitID : "/v2.0/supply/AdUnits/update/" + data.adUnitID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Read By Id AdUnit
export const readAdUnit = data => supplyPost("/v2.0/supply/AdUnits/readByID/" + data);
// StatusChange of AdUnit
export const statusChangeAdUnit = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/AdUnits/status/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/supply/Partner/AdUnits/status' : "/v2.0/supply/AdUnits/status",data);
// Archive AdUnit
export const archiveAdUnit = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/AdUnits/archive/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/supply/Partner/AdUnits/archive' : "/v2.0/supply/AdUnits/archive" , data);
// get AdUnit only Rich Media
export const getAdUnitRichMedia = () => supplyPost('/v2.0/supply/ChildAdUnits/list/notArchived');
// get AdUnit only Standard Banner
export const getAdUnitStandardBanner = () => supplyPost('/v2.0/supply/AdUnits/read/display');
// get AdUnit only Video
export const getAdUnitVideo = () => supplyPost('/v2.0/supply/AdUnits/read/video');
// get AdUnit by TC
export const getAdUnitbyTC = data => supplyPost('/v2.0/operations/TC/getAdunit',data);

export const getAdUnitbyHIS = data => supplyPost('/v2.0/demand/LineItems/read/getAdunit',data);


//Supply : ChildAdUnit

// get ChildAdUnit
export const getChildAdUnit = data => supplyPost(data.userRole === 'SupplyPartner' ? `/v2.0/supplyPartner/ChildAdUnits/read/SupplyPartner?page=${data.pageNumber}&size=${data.pageSize}` : data.userRole === 'Partner' ? '/v2.0/supply/Partner/ChildAdUnits/read' : `/v2.0/supply/ChildAdUnits/read?page=${data.pageNumber}&size=${data.pageSize}`,data,"ChildAdUnits");
// get ChildAdUnits by AdUnit
export const getChildAdUnitsByAdUnit = data => supplyPost(data.userRole === 'SupplyPartner' ?  `/v2.0/supplyPartner/ChildAdUnits/adunit/SupplyPartner/${data.adUnitID}?page=${data.pageNumber}&size=${data.pageSize}` :   `/v2.0/supply/ChildAdUnits/adunit/${data.adUnitID}?page=${data.pageNumber}&size=${data.pageSize}`,data,"ChildAdUnits");
// Create ChildAdUnit 
export const createChildAdUnit = data => supplyPost(data.userRole === 'SupplyPartner' ?  '/v2.0/supplyPartner/ChildAdUnits/create/SupplyPartner' : data.userRole === 'Partner' ?  '/v2.0/supply/Partner/ChildAdUnits/create' : '/v2.0/supply/ChildAdUnits/create', data);
// Update ChildAdUnit 
export const updateChildAdUnit = data => supplyPost(data.userRole === 'SupplyPartner' ?  '/v2.0/supplyPartner/ChildAdUnits/update/SupplyPartner/'+ data.childAdUnitID : data.userRole === 'Partner' ? '/v2.0/supply/Partner/ChildAdUnits/update/' + data.childAdUnitID :  "/v2.0/supply/ChildAdUnits/update/" + data.childAdUnitID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Read By Id ChildAdUnit 
export const readChildAdUnit = data => supplyPost("/v2.0/supply/ChildAdUnits/readByID/" + data.childAdUnitID);
// StatusChange of ChildAdUnit 
export const statusChangeChildAdUnit = data => supplyPost(data.userRole === 'SupplyPartner' ?  '/v2.0/supplyPartner/ChildAdUnits/status/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/supply/Partner/ChildAdUnits/status' : "/v2.0/supply/ChildAdUnits/status" ,data);
// Archive ChildAdUnit 
export const archiveChildAdUnit = data => supplyPost(data.userRole === 'SupplyPartner' ?  '/v2.0/supplyPartner/ChildAdUnits/archive/SupplyPartner' : data.userRole === 'Partner' ? '/v2.0/supply/Partner/ChildAdUnits/archive' : "/v2.0/supply/ChildAdUnits/archive" ,data);

//Supply : HighImpactSettings

// get HighImpactSettings
export const getHighImpactSetting = data => supplyPost(data.userRole === 'SupplyPartner' ? `/v2.0/supplyPartner/HighImpactSettings/read/SupplyPartner?page=${data.pageNumber}&size=${data.pageSize}` : `/v2.0/supply/HighImpactSettings/read?page=${data.pageNumber}&size=${data.pageSize}`,data,"HighImpactSettings");
// get HighImpactSettings by AdUnit
export const getHighImpactSettingsByAdUnit = data => supplyPost("/v2.0/supply/HighImpactSettings/adunit/" + data.adUnitID,data,"HighImpactSettings");
// Create HighImpactSetting 
export const createHighImpactSetting = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/HighImpactSettings/create/SupplyPartner' :'/v2.0/supply/HighImpactSettings/create', data);
// Update HighImpactSetting 
export const updateHighImpactSetting = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/HighImpactSettings/update/SupplyPartner/' + data.highImpactSettingID : "/v2.0/supply/HighImpactSettings/update/" + data.highImpactSettingID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Read By Id HighImpactSetting 
export const readHighImpactSetting = data => supplyPost("/v2.0/supply/HighImpactSettings/readByID/" + data.highImpactSettingID);
// StatusChange of HighImpactSetting 
export const statusChangeHighImpactSetting = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/HighImpactSettings/status/SupplyPartner' : "/v2.0/supply/HighImpactSettings/status" ,data);
// Archive HighImpactSetting 
export const archiveHighImpactSetting = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/HighImpactSettings/archive/SupplyPartner' : "/v2.0/supply/HighImpactSettings/archive" ,data);

//Supply : Stdb Settings

//get Stbb Settings
export const getStbrSetting = data => supplyPost(data.userRole === 'SupplyPartner' ? `/v2.0/supplyPartner/StandardBannerSettings/read/SupplyPartner?page=${data.pageNumber}&size=${data.pageSize}` :`/v2.0/supply/StandardBannerSettings/read?page=${data.pageNumber}&size=${data.pageSize}`,data, "StandardBannerSetttings");
//get stbr settings by adunit id
export const readStbrSetting = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/HighImpactSettings/read/SupplyPartner'+ data.standardBannerSettingID :'/v2.0/supply/StandardBannerSettings/readByID/', + data.standardBannerSettingID);
//Create StbrSettings
export const createStbrSetting = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/StandardBannerSettings/create/SupplyPartner ' : '/v2.0/supply/StandardBannerSettings/create', data);
//update StbrSettings
export const updateStbrSetting = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/StandardBannerSettings/update/SupplyPartner/'+ data.standardBannerSettingID : '/v2.0/supply/StandardBannerSettings/update/' + data.standardBannerSettingID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
//status stbrSettings
export const statusChangeStbrSetting = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/StandardBannerSettings/status/SupplyPartner' : '/v2.0/supply/StandardBannerSettings/status', data);
//get stbrSettings archive
export const archiveStbrSetting = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/StandardBannerSettings/archive/SupplyPartner' : '/v2.0/supply/StandardBannerSettings/archive', data);
//get stbrsettings by adunid id
export const getStbrSettingsByAdUnit = data => supplyPost(data.userRole === 'SupplyPartner' ?  '/v2.0/supplyPartner/StandardBannerSettings/adunit/SupplyPartner/' + data.adUnitID : '/v2.0/supply/StandardBannerSettings/adunit/' + data.adUnitID,data,"StandardBannerSettings")
//Supply : PlayerSetting

// get PlayerSetting
export const getPlayerSetting = data => supplyPost(data.userRole === 'SupplyPartner' ? `/v2.0/supplyPartner/PlayerSettings/read/SupplyPartner?page=${data.pageNumber}&size=${data.pageSize}` : `/v2.0/supply/PlayerSettings/read?page=${data.pageNumber}&size=${data.pageSize}`,data,"PlayerSettings");
// get PlayerSettings by AdUnit
export const getPlayerSettingsByAdUnit = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supply/Partner/PlayerSettings/getAdUnit' :"/v2.0/supply/PlayerSettings/readbyAdunit" + data,"PlayerSettings");
// Create PlayerSetting 
export const createPlayerSetting = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/PlayerSettings/create/SupplyPartner' :'/v2.0/supply/PlayerSettings/create', data);
// Update PlayerSetting 
export const updatePlayerSetting = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/PlayerSettings/update/SupplyPartner/' + data.playerSettingID : "/v2.0/supply/PlayerSettings/update/" + data.playerSettingID, data.userRole === 'Partner' ? {...data,createdByPartner:true} : data);
// Read By Id PlayerSetting 
export const readPlayerSetting = data => supplyPost("/v2.0/supply/PlayerSettings/read/" + data.playerSettingID);
// StatusChange of PlayerSetting 
export const statusChangePlayerSetting = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/PlayerSettings/status/SupplyPartner' : "/v2.0/supply/PlayerSettings/status" ,data);
// Archive PlayerSetting 
export const archivePlayerSetting = data => supplyPost(data.userRole === 'SupplyPartner' ? '/v2.0/supplyPartner/PlayerSettings/archive/SupplyPartner' : "/v2.0/supply/PlayerSettings/archive" , data);


//Common : Size

// get Sizes
export const getSizes = () => demandPost('/v2.0/demand/Creatives/size'); 

//Contextual Targeting / Global Settings

// get Brand Affinity
export const getBrandAffinity = () => contextualSettingsPost('/v2.0/operations/targeting/brandAffinity/read','','brandAffinity');
// Create Brand Affinity 
export const createBrandAffinity = data => contextualSettingsPost('/v2.0/operations/targeting/brandAffinity/create', data);
// Update Brand Affinity 
export const updateBrandAffinity = data => contextualSettingsPost("/v2.0/operations/targeting/brandAffinity/update/" + data.brandAffinityID, data);
// Read By Id Brand Affinity 
export const readBrandAffinity = data => contextualSettingsPost("/v2.0/operations/targeting/brandAffinity/read/" + data.brandAffinityID);
// StatusChange of Brand Affinity 
export const statusChangeBrandAffinity = data => contextualSettingsPost("/v2.0/operations/targeting/brandAffinity/updateStatus",data);
// Archive Brand Affinity 
export const archiveBrandAffinity = data => contextualSettingsPost("/v2.0/operations/targeting/brandAffinity/delete",data);

// get Website Categorization
export const getWebsiteCategorization = () => contextualSettingsPost('/v2.0/operations/targeting/websiteCatorization/read','','websiteCatorization');
// Create Website Categorization 
export const createWebsiteCategorization = data => contextualSettingsPost('/v2.0/operations/targeting/websiteCatorization/create', data);
// Update Website Categorization 
export const updateWebsiteCategorization = data => contextualSettingsPost("/v2.0/operations/targeting/websiteCatorization/update/" + data.websiteID, data);
// Read By Id Website Categorization 
export const readWebsiteCategorization = data => contextualSettingsPost("/v2.0/operations/targeting/websiteCatorization/read/" + data.websiteID);
// StatusChange of Website Categorization 
export const statusChangeWebsiteCategorization = data => contextualSettingsPost("/v2.0/operations/targeting/websiteCatorization/updateStatus",data);
// Archive Website Categorization 
export const archiveWebsiteCategorization = data => contextualSettingsPost("/v2.0/operations/targeting/websiteCatorization/delete",data);

// get Devices
export const getDevices = () => contextualSettingsPost('/v2.0/operations/targeting/all');

// get Operating Systems
export const getOSs = () => contextualSettingsPost('/v2.0/operations/NetworkBlock/read/all');

// get Browsers
export const getBrowsers = () => contextualSettingsPost('/v2.0/operations/targeting/webBrowser/read');

// get Domains
export const getDomains = () => contextualSettingsPost('/v2.0/operations/targeting/Domain/list');

// get PageUrls
export const getPageUrls = () => contextualSettingsPost('/v2.0/operations/targeting/PageURLGroups/read','','PageURLGroups');
// Create PageUrls 
export const createPageUrl = data => contextualSettingsPost('/v2.0/operations/targeting/PageURLGroups/create', data);
// Update PageUrls 
export const updatePageUrl = data => contextualSettingsPost("/v2.0/operations/targeting/PageURLGroups/update/" + data.pageURLGroupID, data);
// Read By Id PageUrls 
export const readPageUrl = data => contextualSettingsPost("/v2.0/operations/targeting/PageURLGroups/read/" + data.pageURLGroupID);
// StatusChange of PageUrls 
export const statusChangePageUrl = data => contextualSettingsPost("/v2.0/operations/targeting/PageURLGroups/updateStatus",data);
// Archive PageUrls 
export const archivePageUrl = data => contextualSettingsPost("/v2.0/operations/targeting/PageURLGroups/delete",data);

// get Keywords
export const getKeywords = () => contextualSettingsPost('/v2.0/operations/targeting/keyword/read','','keyword');
// Create Keyword 
export const createKeyword = data => contextualSettingsPost('/v2.0/operations/targeting/keyword/create', data);
// Update Keyword 
export const updateKeyword = data => contextualSettingsPost("/v2.0/operations/targeting/keyword/update/" + data.keywordID, data);
// Read By Id Keyword 
export const readKeyword = data => contextualSettingsPost("/v2.0/operations/targeting/keyword/read/" + data.keywordID);
// StatusChange of Keyword 
export const statusChangeKeyword = data => contextualSettingsPost("/v2.0/operations/targeting/keyword/updateStatus",data);
// Archive Keyword 
export const archiveKeyword = data => contextualSettingsPost("/v2.0/operations/targeting/keyword/delete",data);

// get IpMap
export const getIpMaps = () => contextualSettingsPost('/v2.0/operations/targeting/iPMapping/read','','keyword');
// Create IpMap 
export const createIpMap = data => contextualSettingsPost('/v2.0/operations/targeting/iPMapping/create', data);
// Update IpMap 
export const updateIpMap = data => contextualSettingsPost("/v2.0/operations/targeting/iPMapping/update/" + data.iPMappingID, data);
// Read By Id IpMap 
export const readIpMap = data => contextualSettingsPost("/v2.0/operations/targeting/iPMapping/read/" + data.iPMappingID);
// StatusChange of IpMap 
export const statusChangeIpMap = data => contextualSettingsPost("/v2.0/operations/targeting/iPMapping/updateStatus",data);
// Archive IpMap 
export const archiveIpMap = data => contextualSettingsPost("/v2.0/operations/targeting/iPMapping/delete",data);

// get Languages
export const getLanguages = () => contextualSettingsPost('http://127.0.0.1:2002/v1.0/Miscs/list');

// get Categories
export const getCategories = () => contextualSettingsPost('/v2.0/operations/targeting/category/read');

// get Brand Affinities
export const getBrandAffinities = () => contextualSettingsPost('/v2.0/operations/targeting/brandAffinity/read');

// get Interests
export const getInterests = () => contextualSettingsPost('/v2.0/operations/targeting/interest/read','','interest');
// Create Interest
export const createInterest = data => contextualSettingsPost('/v2.0/operations/targeting/interest/create', data);
// Update Interest 
export const updateInterest = data => contextualSettingsPost("/v2.0/operations/targeting/interest/update/" + data.interestID, data);
// Read By Id Interest 
export const readInterest = data => contextualSettingsPost("/v2.0/operations/targeting/interest/read/" + data.interestID);
// StatusChange of Interest 
export const statusChangeInterest = data => contextualSettingsPost("/v2.0/operations/targeting/interest/updateStatus",data);
// Archive Interest 
export const archiveInterest = data => contextualSettingsPost("/v2.0/operations/targeting/interest/delete",data);

// get Regions
export const getRegions = () => contextualSettingsPost('/v2.0/operations/regions/list');

// get Cities
export const getCities = () => contextualSettingsPost('/v2.0/operations/cities/list');

// get VideoContents
export const getVideoContents = data => toolsPost("/v2.0/vcs/VideoContent/read",data);
// Create VideoContents 
export const postVideoContent = data => toolsPost("/v2.0/vcs/VideoContent/create", data);
//delete videoContents
export const deleteVideoContent = data => toolsPost("/v2.0/vcs/VideoContent/delete/"+data.videoContentID, data);

// get VideoContents
export const getVideoContentsPartner = data => toolsPost("/v2.0/vcs/VideoContent/read",data);

// get Playlist
export const getPlaylist = data => contextualSettingsPost("/v2.0/vcs/PlayList/read",data);
// get Playlistbyid
export const getPlaylistById = data => contextualSettingsPost("/v2.0/vcs/PlayList/read/"+data.playlistID);
//create Playlist 
export const createPlaylist = data => contextualSettingsPost("/v2.0/vcs/PlayList/create",data);
//update Playlist
export const updatePlaylist = data => contextualSettingsPost("/v2.0/vcs/PlayList/update/"+data.playListID,data);
//status playlist
export const statusPlaylist = data => contextualSettingsPost("/v2.0/vcs/PlayList/status", data);
//archive playlist
export const archivePlaylist = data => contextualSettingsPost("/v2.0/vcs/PlayList/archive", data);

//market place
export const getMarketContents = data => toolsPost("/v2.0/vcs/VideoContent/read/readMarketPlace",data)
//move to vcs to pub
export const addMarketContent = data => toolsPost("/v2.0/vcs/VideoContentOther/create/MoveFromMarketPlace",data)
//remove from market place in admin
export const deleteMarketContent = data => toolsPost("/v2.0/vcs/VideoContent/update/ReMoveMarketPlace",data)



// get VideoContents
export const getPubVideoContents = data => toolsPost("/v2.0/vcs/VideoContentOther/read",data);
// Create VideoContents 
export const postPubVideoContent = data => toolsPost("/v2.0/vcs/VideoContentOther/create", data);
//delete videoContents
export const deletePubVideoContent = data => toolsPost("/v2.0/vcs/VideoContentOther/delete/"+data);


// get Playlist
export const getPubPlaylist = data => contextualSettingsPost("/v2.0/vcs/PlayListOther/read",data);
// get Playlistbyid
export const getPubPlaylistById = data => contextualSettingsPost("/v2.0/vcs/PlayListOther/read/"+data.playlistID);
//create Playlist 
export const createPubPlaylist = data => contextualSettingsPost("/v2.0/vcs/PlayListOther/create",data);
//update Playlist need url for pub
export const updatePubPlaylist = data => contextualSettingsPost("/v2.0/vcs/PlayList/update/"+data.playListID,data);
//status playlist
export const statusPubPlaylist = data => contextualSettingsPost("/v2.0/vcs/PlayListOther/archive", data);
//archive playlist
export const archivePubPlaylist = data => contextualSettingsPost("/v2.0/vcs/PlayList/archive", data);


//Notifications

// get Notifications
export const getPub_Notifications = data => demandPost(data.userRole === 'SupplyPartner' ? "/v2.0/Notification/read/forAdmin/SupplyPartner" : "/v2.0/Notification/read/forAdmin",data,"Notifications");
// Create Pub_Notification 
export const postPub_Notification = data => demandPost(data.userRole === 'SupplyPartner' ? "/v2.0/Notification/create/SupplyPartner" : "/v2.0/Notification/create", data);
// Update updatePub_Notification 
export const updatePub_Notification = data => demandPost(data.userRole === 'SupplyPartner' ? "/v2.0/Notification/update/" + data.notificationID +"/SupplyPartner" : "/v2.0/Notification/update/" + data.notificationID, data);
// Read By Id readPub_Notification 
export const readPub_Notification = data => demandPost(data.userRole === 'SupplyPartner' ? "/v2.0/Notification/read/" + data.notificationID +"/SupplyPartner" : "/v2.0/Notification/read/" + data.notificationID);
// StatusChange of status Change Pub_Notification 
export const statusChangePub_Notification = data => demandPost(data.userRole === 'SupplyPartner' ? "/v2.0/Notification/status/SupplyPartner" : "/v2.0/Notification/status",data);
// Archive archive Pub_Notification 
export const archivePub_Notification = data => demandPost(data.userRole === 'SupplyPartner' ? "/v2.0/Notification/archive/SupplyPartner" : "/v2.0/Notification/archive" , data);

// get Action Notifications
export const getAction_Notifications = data => demandPost(data.userRole === 'SupplyPartner' ? "/v2.0/Notification/read/partnerInteraction/SupplyPartner" : "/v2.0/Notification/read/partnerInteraction",data,"ActionNotifications");


//Custom Tracking : Category

// get Category
export const getCategory = data => houseAdsPost("/HouseAdsCategories/read",data,"HouseAdsCategories");
// Create Category 
export const postCategory = data => houseAdsPost("/HouseAdsCategories/create", data);
// Update Category 
export const updateCategory = data => houseAdsPost("/HouseAdsCategories/update/" + data.HouseAdsCategoryID, data);
// Read By Id Category 
export const readCategory = data => houseAdsPost("/HouseAdsCategories/readByID" + data.HouseAdsCategoryID);
// StatusChange of Category 
export const statusChangeCategory = data => houseAdsPost("/HouseAdsCategories/status",data);
// Archive Category 
export const archiveCategory = data => houseAdsPost("/HouseAdsCategories/archive" , data);
// get Category Types
export const getCategoryTypes = data => houseAdsPost("/HouseAdsCategoryTypes/list",data);
// Create CategoryType 
export const postCategoryType = data => houseAdsPost("/HouseAdsCategoryTypes/create", data);

//Custom Tracking : Creatives

// get Creatives
export const getCreatives = data => houseAdsPost(data.pageSize === 0 ? '/getActiveHouseAdsCreativesIdAndName/list':`/HouseAdsCreatives/read?page=${data.pageNumber}&size=${data.pageSize}`,data,"HouseAdsCreatives");
// get Creatives by Category
export const getCreativesByCategory = data => houseAdsPost("/HouseAdsCreatives/readOnCategory/" + data.categoryID);
// get Creatives by AdUnits
export const getCreativesByAdUnits = data => houseAdsPost( `/HouseAdsCreatives/getListAdunit?page=${data.pageNumber}&size=${data.pageSize}`, data);
// Create Creatives 
export const postCreatives = data => houseAdsPost("/HouseAdsCreatives/create", data);
// Update Creatives 
export const updateCreatives = data => houseAdsPost("/HouseAdsCreatives/update/" + data.houseAdsCreativeID, data);
// Read By Id Creatives 
export const readCreatives = data => houseAdsPost("/HouseAdsCreatives/readByID/" + data.houseAdsCreativeID);
// StatusChange of Creatives 
export const statusChangeCreatives = data => houseAdsPost("/HouseAdsCreatives/status",data);
// Archive Creatives 
export const archiveCreatives = data => houseAdsPost("/HouseAdsCreatives/archive" , data);

//Custom Tracking : AdUnits

// get AdUnits
export const getAdUnits = data => houseAdsPost( `/HouseAdsAdUnits/read?page=${data.pageNumber}&size=${data.pageSize}` ,data,"HouseAdsAdUnits");
// Create AdUnits 
export const postAdUnits = data => houseAdsPost("/HouseAdsAdUnits/create", data);
// Update AdUnits 
export const updateAdUnits = data => houseAdsPost("/HouseAdsAdUnits/update/" + data.houseAdsAdUnitID, data);
// Read By Id AdUnits 
export const readAdUnits = data => houseAdsPost("/HouseAdsAdUnits/readByID/" + data.houseAdsAdUnitID);
// StatusChange of AdUnits 
export const statusChangeAdUnits = data => houseAdsPost("/HouseAdsAdUnits/status",data);
// Archive AdUnits 
export const archiveAdUnits = data => houseAdsPost("/HouseAdsAdUnits/archive" , data);

//Custom Tracking : Publishers

// get Publishers
export const getAdPublisher = data => houseAdsPost(`/HouseAdsPublishers/read?page=${data.pageNumber}&size=${data.pageSize}`,data,"HouseAdsPublishers");
// Create Publishers 
export const postAdPublisher = data => houseAdsPost("/HouseAdsPublishers/create", data);
// Update Publishers 
export const updateAdPublisher = data => houseAdsPost("/HouseAdsPublishers/update/" + data.houseAdsPublisherID, data);
// Read By Id Publishers 
export const readAdPublisher = data => houseAdsPost("/HouseAdsPublishers/readByID/" + data.houseAdsPublisherID);
// StatusChange of Publishers 
export const statusChangeAdPublisher = data => houseAdsPost("/HouseAdsPublishers/status",data);
// Archive Publishers 
export const archiveAdPublisher = data => houseAdsPost("/HouseAdsPublishers/archive" , data);

//Proxy : AdUnits

// get ProxyAdUnits
export const getProxyAdUnits = data => houseAdsPost( `/ProxyAdUnits/read?page=${data.pageNumber}&size=${data.pageSize}`,data,"ProxyAdUnits");
// Create ProxyAdUnits 
export const postProxyAdUnits = data => houseAdsPost("/ProxyAdUnits/create", data);
// Update ProxyAdUnits 
export const updateProxyAdUnits = data => houseAdsPost("/ProxyAdUnits/update/" + data.proxyAdUnitID, data);
// Read By Id ProxyAdUnits 
export const readProxyAdUnits = data => houseAdsPost("/ProxyAdUnits/readByID/" + data.proxyAdUnitID);
// StatusChange of ProxyAdUnits 
export const statusChangeProxyAdUnits = data => houseAdsPost("/ProxyAdUnits/status",data);
// Archive ProxyAdUnits 
export const archiveProxyAdUnits = data => houseAdsPost("/ProxyAdUnits/archive" , data);


// get Proxy Reports
export const getProxyAdUnitsReports = data => demandPost("/v2.0/proxyReport/Ssrv/report",data);
// get HouseAd Reports
export const getHouseAdUnitsReports = data => demandPost("/v2.0/proxyReport/Fsrv/report",data);

// get Revenue Display Reports
export const getRevenueDisplayReports = data => demandPost(data.userRole === 'SupplyPartner' ? '/v2.0/reportingSupplyPartner/Query/Revenue/DisplayQuerys' : "/v2.0/reporting/Query/Revenue/DisplayQuerys",data);

// get Analytics Display Reports
export const getAdminAnalyticsReports = data => demandPost("/v2.0/reporting/Query/Analytic/ReportData",data);

// get YM Analytics Display Reports
export const getYMAnalyticsReports = data => demandPost("/v2.0/reporting/Query/api/analytics/ym_team",data);

// get AS Analytics Display Reports
export const getASAnalyticsReports = data => demandPost("/v2.0/reporting/Query/api/analytics/as_team",data);

// get MIS Analytics Display Reports
export const getMISAnalyticsReports = data => demandPost("/v2.0/reporting/Query/api/analytics/mis_team",data);

// get Page URL Display Reports
export const getPageUrlReports = data => demandPost("/v2.0/reporting/Query/Pageurl/ReportData",data);

// get Team Page URL Display Reports
export const getTeamPageUrlReports = data => demandPost("/v2.0/reporting/Query/api/page_url/as_and_ym",data);

// get Team History Display Reports
export const getTeamHistoryReports = data => demandPost("/v2.0/reporting/Query/ReportsHistory/read",data);

// get Publisher Revenue Display Reports
export const getPublisherRevenueDisplayReports = data => demandPost("/v2.0/reporting/Partner/Query/Revenue/DisplayQuerys",data);

export const getAgencyRevenueDisplayReports = data => demandPost("/v2.0/reporting/Query/Agency/DisplayQuerys",data);

export const getPAgencyRevenueDisplayReports = data => demandPost("/v2.0/reporting/Agency/Reports",data);

//Publisher Console : Schedule Report

// get ScheduleReports
export const getScheduleReports = data => demandPost("/v2.0/reporting/Partner/Query/Revenue/ScheduleReports/read",data);
// Create ScheduleReport 
export const postScheduleReport = data => demandPost("/v2.0/reporting/Partner/Query/Revenue/ScheduleReports/create", data);
// Update ScheduleReport 
export const updateScheduleReport = data => demandPost("/v2.0/reporting/Partner/Query/Revenue/ScheduleReports/update/" + data.scheduleID, data);
// Read By Id ScheduleReport 
export const readScheduleReport = data => demandPost("/v2.0/reporting/Partner/Query/Revenue/ScheduleReports/readByID/" + data.scheduleID);
// StatusChange of ScheduleReport 
export const statusChangeScheduleReport = data => demandPost("/v2.0/reporting/Partner/Query/Revenue/ScheduleReports/status",data);
// Archive ScheduleReport 
export const archiveScheduleReport = data => demandPost("/v2.0/reporting/Partner/Query/Revenue/ScheduleReports/archive" , data);

//Agency Console : Schedule Report

// get ScheduleReports
export const getAgencyScheduleReports = data => demandPost("/v2.0/reporting/AgencyScheduleReport/Read",data);
// Create ScheduleReport 
export const postAgencyScheduleReport = data => demandPost("/v2.0/reporting/Agency/ScheduleReport/Create", data);
// Update ScheduleReport 
export const updateAgencyScheduleReport = data => demandPost("/v2.0/reporting/AgencyScheduleReport/Update/" + data.scheduleID, data);
// Read By Id ScheduleReport 
export const readAgencyScheduleReport = data => demandPost("/v2.0/reporting/Query/Revenue/ScheduleReports/readByID/" + data.scheduleID);
// StatusChange of ScheduleReport 
export const statusAgencyChangeScheduleReport = data => demandPost("/v2.0/reporting/AgencyScheduleReport/Status",data);
// Archive ScheduleReport 
export const archiveAgencyScheduleReport = data => demandPost("/v2.0/reporting/AgencyScheduleReport/Archive" , data);

// get NotRead Notifications for Publishers
export const getNotRead_Notifications = data => contextualSettingsPost(data.userRole === 'SupplyPartner' ? '/v2.0/Notification/readByUserID/SupplyPartner' : "/v2.0/Notification/readByUserID",data);

// get Analytics Display Reports BSRV2021
export const getAnalyticsDisplayReports = data => reportPost("http://127.0.0.1:2006/bsrv/report",data);

// get Page URL BSRV2021 Reports
export const getPageUrl21Reports = data => reportPost("http://127.0.0.1:2006/bsrv/pageURLreport",data);

//Admin Support Ticket

// get Support Tickets
export const getSupportTicketforAdmin = data => contextualSettingsPost(`/v2.0/support/SupportTickets/read/forAdmin?page=${data.pageNumber}&size=${data.pageSize}` ,data,"SupportTicketsAdmin");
// Create Support Tickets 
export const changeStatusSupportTicket = data => contextualSettingsPost("/v2.0/support/SupportTickets/update/status", data);


// both Admin and Publisher Create Chat 
export const postChat = data => contextualSettingsPost("/v2.0/support/SupportTickets/create/chat", data);


//Publisher Support Ticket

// get Support Tickets
export const getSupportTicket = data => contextualSettingsPost( `/v2.0/support/SupportTickets/read/partner/${data.partnerID}?page=${data.pageNumber}&size=${data.pageSize}`);
// Create Support Tickets 
export const postSupportTicket = data => supportTicketPost("/v2.0/support/SupportTickets/create", data);
 

// get Bill History
export const getBillHistoryList = data => demandPost(data.userRole === 'SupplyPartner' ? `/v2.0/invoiceSupplyPartner/History/list?page=${data.pageNumber}&size=${data.pageSize}` : `/v2.0/invoice/History/list?page=${data.pageNumber}&size=${data.pageSize}`,data,"BillHistoryList");

// get Approved Bill History
export const getBillHistory = data => demandPost(data.userRole === 'SupplyPartner' ? `/v2.0/invoiceSupplyPartner/History/listApprove?page=${data.pageNumber}&size=${data.pageSize}` : `/v2.0/invoice/History/listApproved?page=${data.pageNumber}&size=${data.pageSize}`,data,"ApprovedBillHistoryList");

// get Bill History
export const getLogs = data => contextualSettingsPost("/logfe/partner/report",data,"PublisherLogs");

// get Approved Bill History
export const getLogsMaster = data => contextualSettingsPost("/logfe/master/report",data,"MasterLogs");

//Admin : Network Settings

//get Stbb Settings
export const getNetworkSetting = data => demandPost(`/v2.0/operations/NetworkSettings/read?page=${data.pageNumber}&size=${data.pageSize}`,data, "NetworkSettings");
//get Network settings by adunit id
export const readNetworkSetting = data => demandPost('/v2.0/operations/NetworkSettings/read/', + data.networkSettingID);
//Create Network Settings
export const createNetworkSetting = data => demandPost('/v2.0/operations/NetworkSettings/create', data);
//update Network Settings
export const updateNetworkSetting = data => demandPost('/v2.0/operations/NetworkSettings/update/' + data.networkSettingID, data);
//status Network Settings
export const statusChangeNetworkSetting = data => demandPost('/v2.0/operations/NetworkSettings/status', data);
//get Network Settings archive
export const archiveNetworkSetting = data => demandPost('/v2.0/operations/NetworkSettings/archive', data);


export const getNetBlocking = data => contextualSettingsPost('/v2.0/operations/NetworkBlock/read/' + data.type,data);
// Update NetBlocking 
export const updateNetBlocking = data => contextualSettingsPost("/v2.0/operations/NetworkBlock/update/" + data.deviceControlID, data);
// StatusChange of NetBlocking 
export const statusChangeNetBlocking = data => contextualSettingsPost("/v2.0/operations/NetworkBlock/status/" + data.type,data);
// Archive NetBlocking 
export const archiveNetBlocking = data => contextualSettingsPost("/v2.0/operations/NetworkBlock/archive/" + data.type, data);


export const getContextualData = data => contextualSettingsPost('/v2.0/operations/targeting/' + data.type + '/read' ,data);
// Update Advertiser 
export const updateContextualData = data => contextualSettingsPost("/v2.0/operations/targeting/update/" + data.contextualDataID, data);
// StatusChange of Advertiser 
export const statusChangeContextualData = data => contextualSettingsPost("/v2.0/operations/targeting/" + data.type +"/updateStatus" ,data);
// Archive Advertiser 
export const archiveContextualData = data => contextualSettingsPost("/v2.0/operations/targeting/" + data.type +"/delete" ,data);

export {  
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
  postLogin,
  postRegister,
  postForgetPwd,
};


