import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import axios from "axios";


import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Modal,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap"

import classnames from "classnames";

import DateRangePickers from 'helpers/DateRangePicker'
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone, PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import cellEditFactory , { Type } from 'react-bootstrap-table2-editor';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';
import excelFile from 'assets/files/AdsTxtFile.xlsx'
import PDFFile from 'assets/files/AdsTxtEntries.pdf'

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const P_Ads_Txt_EntrtiesUpdate = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [adsTxtEntries, setAdsTxtEntries] = useState([])
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [uploadModal, setUploadModal] = useState(false);
    const [customActiveTab, setcustomActiveTab] = useState("1");

    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            partnerName:userInfo.name,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
    }

    useEffect(() => {
        axios.post("/v2.0/partnerOP/ads.txtEntries/read/forPartner", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.response) {
                    res.data.response.map((item, index) => {
                        item.id = index + 1
                    })
                    setAdsTxtEntries([...res.data.response])
                }
            })
            .catch((err) => {
                console.log("e", err)
            });

    }, []);

    const changeDatePick = (event) => {
        console.log(event)
        console.log("start: ", event.startDate._d);
        console.log("end: ", event.endDate._d);
        setFromDate(event.startDate._d.toISOString());
        setToDate(event.endDate._d.toISOString());
    }

    const deleteAdsTxt = (event) => {
    }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const adsTxtEntriesListColumns = [
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            dataField: "sSPEntry",
            text: "Ads.txt Entries",
            sort: true,
        },

        {
            text: "Accounts",
            dataField: "sSPEntryAccount",
            sort: true,
        },

        {
            dataField: "sSPEntryInventoryType",
            text: "Inventory Type",
            sort: true,
            editor: {
                type: Type.SELECT,
                options: [{
                  value: 'dispaly',
                  label: 'Dispaly'
                }, {
                  value: 'both',
                  label: 'Both'
                }, {
                  value: 'video',
                  label: 'Video'
                }]
              }
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',
      
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, adsTxt) => (
              <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
                <Link className="text-danger" to="#" onClick={deleteAdsTxt(adsTxt)}>
                    <i
                      className="mdi mdi-delete font-size-18"
                      id="edittooltip"
                    ></i>
                </Link>
              </div>
            ),
          },
    ];

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} entries
      </span>
    );
  
  
    const pageOptions = {
      paginationSize: 4,
      pageStartIndex: 1,
      sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
    };

    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const [newEntryList, setNewEntryList] = useState([{ sSPEntryAccount: '', sSPEntry: '', sSPEntryInventoryType: '' }])

    const addNewEntry = () => {
        let entryList = [...newEntryList]
        entryList.push({ sSPEntryAccount: '', sSPEntry: '', sSPEntryInventoryType: '' })
        setNewEntryList(entryList)
    }

    const handleEntryChange = (index) => (e) =>{
       const  {name,value} = e.target
        let entryList = [...newEntryList]
        let entryData = {...entryList[index],[name]:value}
        entryList[index] = entryData
        setNewEntryList(entryList)
    }

    const removeEntry = (index) => (e) => {
        let entryList = [...newEntryList]
        entryList.splice(index, 1)
        setNewEntryList(entryList)
    }

    const saveEntries = (e) => {
        console.log(adsTxtEntries)
        debugger
        let bodyEntries = {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                sspEntries:adsTxtEntries,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
        }
        console.log(bodyEntries)
        axios.post("/v2.0/partnerOP/ads.txtEntries/update", bodyEntries,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.response) {
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }

    return (
        <React.Fragment >
                    <Container fluid={true}>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                                    <ToolkitProvider
                                                        keyField={keyField}
                                                        data={adsTxtEntries}
                                                        columns={adsTxtEntriesListColumns}
                                                        // bootstrap4
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <Row className="mb-2">
                                                                    <Col sm="3">
                                                                        <div className="search-box ms-2 mb-2 d-inline-block">
                                                                            <div className="position-relative">
                                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                                <i className="bx bx-search-alt search-icon" />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xl="12" className="table-responsive">
                                                                        <div >
                                                                            <BootstrapTable
                                                                                keyField={keyField}
                                                                                responsive
                                                                                bordered={false}
                                                                                striped={false}
                                                                                defaultSorted={defaultSorted}
                                                                                cellEdit={ cellEditFactory({
                                                                                    mode: 'click',
                                                                                    blurToSave: true
                                                                                  })}
                                                                                classes={
                                                                                    "table align-middle table-nowrap"
                                                                                }
                                                                                headerWrapperClasses={"thead-light"}
                                                                                {...toolkitProps.baseProps}
                                                                                pagination={paginationFactory(pageOptions)}
                                                                                noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                            // ref={node}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary "
                            data-dismiss="modal"
                            // eslint-disable-next-line react/prop-types
                            onClick={props.closeCanvas}
                        >
                            Close
                        </button>
                        <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                               onClick={saveEntries}
                        >
                            Update
                        </Button>
                </div>
                    </Container>






        </React.Fragment >
    )
}

P_Ads_Txt_EntrtiesUpdate.propTypes = {}

export default P_Ads_Txt_EntrtiesUpdate