import {
    LOAD_ACTIVITIES,
    NO_DATA_PUB_NOTIFICATIONS,
    GET_PUB_NOTIFICATIONS_SUCCESS,
    GET_PUB_NOTIFICATIONS_FAIL,
    ADD_PUB_NOTIFICATION_SUCCESS,
    ADD_PUB_NOTIFICATION_ERROR,
    UPDATE_PUB_NOTIFICATION_SUCCESS,
    UPDATE_PUB_NOTIFICATION_ERROR,
    READ_PUB_NOTIFICATION_SUCCESS,
    STATUS_PUB_NOTIFICATION_SUCCESS,
    ARCHIVE_PUB_NOTIFICATION_SUCCESS,

    GET_NOTREAD_NOTIFICATIONS_SUCCESS,
    GET_NOTREAD_NOTIFICATIONS_FAIL,

    GET_ACTION_NOTIFICATIONS_FAIL,
    GET_ACTION_NOTIFICATIONS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
    nots: [],
    notReadNotList:[],
    actionNotifications:[],
    loading:false,
    noData:false,
    error: {},
    successMsg: null,
    errorMsg: null,
    notification: null,
    response:null,
    archiveMsg:null,
}

const pub_notifications = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_ACTIVITIES:
            return {
                ...state,
                loading: true,
            }
            case NO_DATA_PUB_NOTIFICATIONS:
                return {
                    ...state,
                    noData: true,
                }
        case GET_PUB_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading:false,
                nots: action.payload.response
            }

        case GET_PUB_NOTIFICATIONS_FAIL:
            return {
                ...state,
                loading:false,
                error: action.payload,
            }
            case GET_ACTION_NOTIFICATIONS_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    actionNotifications: action.payload.response,
                }
    
            case GET_ACTION_NOTIFICATIONS_FAIL:
                return {
                    ...state,
                    loading:false,
                    error: action.payload,
                }
            case GET_NOTREAD_NOTIFICATIONS_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    notReadNotList: action.payload,
                }
    
            case GET_NOTREAD_NOTIFICATIONS_FAIL:
                return {
                    ...state,
                    loading:false,
                    error: action.payload,
                }
        case ADD_PUB_NOTIFICATION_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_PUB_NOTIFICATION_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_PUB_NOTIFICATIONS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_PUB_NOTIFICATION_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_PUB_NOTIFICATIONS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_PUB_NOTIFICATION_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_PUB_NOTIFICATIONS_SUCCESS' ? null : action.payload,
            }
        case READ_PUB_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notification: action.payload,
            }
        case STATUS_PUB_NOTIFICATION_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_PUB_NOTIFICATION_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default pub_notifications