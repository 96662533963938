/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, useCallback } from "react";
import {
    Card,
    CardBody,
    Col,
    Row, Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withRouter, Link } from "react-router-dom";

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import LoadingTable from "pages/LoaderPages/LoadingTable";

import {
    getContextualDatas as onGetContextualData,
    statusNetBlocking as onStatusNetBlocking,
    archiveNetBlocking as onArchiveNetBlocking,
    statusNetBlockingSuccess,
    archiveNetBlockingSuccess,
} from "store/Contextual_Settings/ContextualData/actions";

import UploadBlockings from "./UploadBlockings";
import LoadingTableContextual from "pages/LoaderPages/LoadingTableContextual";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var selectedLists = []

function Interests(props) {
    const dispatch = useDispatch();
    const [switchLoader, setSwitchLoader] = useState(true)
    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
    const [selected, setSelected] = useState([])
    const [isRight, setIsRight] = useState(false);

    const handleSwitch = () => {
        setSwitchLoader(!switchLoader)
    }

    console.log(props)

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 5000,
        extendedTimeOut: 1000
    };

    const selectRow = {
        mode: "checkbox",
        nonSelectable: [],
        selected: selected,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                setSelected([...selected, row.id])
                setSelectedRowsStatus(true)
                selectedIndex.push(row.interestID)
                selectedItem.push(row.interestID)
            } else {
                setSelected(selected.filter(x => x !== row.id))
                let index = selectedIndex.indexOf(row.interestID)
                selectedIndex.splice(index, 1)
                let index2 = selectedItem.indexOf(row.interestID)
                selectedItem.splice(index2, 1)
                if (selectedIndex.length === 0) {
                    setSelectedRowsStatus(false)
                }
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            const ids = rows.map(r => r.id);
            if (isSelect) {
                setSelected(ids)
                rows.map((item, index) => {
                    selectedIndex.push(item.interestID)
                    selectedItem.push(item.interestID)
                })
                setSelectedRowsStatus(true)
            } else {
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
                selectedItem = []
            }
        }
    };

    const handleClose = () => {
        setSelectedRowsStatus(false)
        selectedItem = []
        setSelected([])
        selectedIndex = []
    }

    const { networkBlocks } = useSelector(state => ({
        networkBlocks: state.contextualData.blocks,
    }));

    const { loading } = useSelector(state => ({
        loading: state.contextualData.loading,
    }));

    useEffect(() => {
        if (props.activeId === '2') {
            dispatch(onGetContextualData({ type: 'interest' }));
        }
    }, [props.activeId]);

    useEffect(() => {
        if (loading) {
            setSwitchLoader(true)
        }
    }, [loading]);

    const { netBlockingResponse } = useSelector(state => ({
        netBlockingResponse: state.contextualData.response,
    }));

    const { archiveNetBlockingsResponse } = useSelector(state => ({
        archiveNetBlockingsResponse: state.contextualData.archiveMsg,
    }));

    useEffect(() => {
        if (netBlockingResponse !== null && props.activeId === '2') {
            if (netBlockingResponse.success) {
                toastr.success('Interest Status Changed Successfully')
                dispatch(onGetContextualData({ type: 'interest' }));
                dispatch(statusNetBlockingSuccess(null));
                selectedItem = []
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []

            }
        }
    }, [dispatch, netBlockingResponse]);

    useEffect(() => {
        if (archiveNetBlockingsResponse !== null && props.activeId === '2') {
            if (archiveNetBlockingsResponse.success) {
                toastr.success('Interest Archived Successfully')
                dispatch(onGetContextualData({ type: 'interest' }));
                dispatch(archiveNetBlockingSuccess(null));
                selectedItem = []
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []

            }
        }
    }, [dispatch, archiveNetBlockingsResponse]);

    const toggleRightCanvas = () => {
        selectedData = ''
        selectedLists = []
        setIsRight(!isRight);
    };

    const toggleDrawer = (close, deviceControl) => (event) => {
        console.log(event)
        if (event === 'Create') {
            toastr.success('Interest Saved Successfully!...')
        }
        if (event === 'Update') {
            toastr.success('Interest Updated Successfully!...')
        }
        dispatch(onGetContextualData({ type: 'interest' }));
        selectedData = deviceControl
        let dfs = []
        if(deviceControl.interestList && deviceControl.interestList.length > 0){
            deviceControl.interestList.map((item, index) => {
                dfs.push({ name: item, id: index + 1 })
            })
        }
         selectedLists = dfs
        setIsRight(close);
    };

    const keyField = "id";

    const roleListColumns = [
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "id",
            sort: true,
            hidden: true,
        },
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "interestID",
            sort: true,
        },
        {
            text: "Name",
            dataField: "interestGroup",
            sort: true,
            // eslint-disable-next-line react/display-name
            // formatter: (cellContent, interest) => (
            //   <>
            //     <h5 className="font-size-14 mb-1">
            //       <Link to="#" className="text-dark">
            //         {interest.interestName}
            //       </Link>
            //     </h5>
            //   </>
            // ),
        },

        {
            dataField: "interestStatus",
            text: "Status",
            sort: true,
            formatter: (cellContent, interest) => (
                <>
                    {interest.interestStatus &&
                        <span className="active-state">Active</span>
                    }
                    {!interest.interestStatus &&
                        <span className="inactive-state">Inactive</span>
                    }
                </>
            ),
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',

            // eslint-disable-next-line react/display-name
            formatter: (cellContent, interest) => (
                <div className="d-flex gap-3">
                    <Link className="Email-state" to="#" onClick={toggleDrawer(true, interest)}>
                    Edit
                    </Link>
                </div>
            ),
        },
    ];

    const archiveNetBlocking = async () => {
        dispatch(onArchiveNetBlocking({ interestIds: selectedItem, type: 'interest' }));
    }

    const statusNetBlocking = (state) => (event) => {
        dispatch(onStatusNetBlocking({ interestIds: selectedItem, interestStatus: state, type: 'interest' }));
    }

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );


    const pageOptions = {
        paginationSize: 4,
        pageStartIndex: 1,
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "asc", // desc or asc
        },
    ];


    return (
        <Row>
            {switchLoader &&
                <LoadingTableContextual loading={loading} targetLoading={handleSwitch} />
            }
            {!switchLoader &&
                <Row>
            <Col lg="12">
                <Card>
                    <CardBody>
                        <ToolkitProvider
                            keyField={keyField}
                            data={networkBlocks}
                            columns={roleListColumns}
                            // bootstrap4
                            search
                        >
                            {toolkitProps => (
                                <React.Fragment>
                                    <Row className="mb-2">
                                        <Col sm="4">
                                            <div className="search-box ms-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                    <SearchBar {...toolkitProps.searchProps} />
                                                    <i className="bx bx-search-alt search-icon" />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="4"></Col>
                                        <Col sm="4" className="date-range ">
                                            <div className="text-sm-end">
                                                <Button
                                                    color="primary"
                                                    className="font-16 btn-block btn btn-primary"
                                                    onClick={toggleRightCanvas}
                                                >
                                                    <i className="mdi mdi-plus-circle-outline me-1" />
                                                    Add Interest
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl="12">
                                            {selectedRowsStatus && selectedIndex.length > 0 &&
                                                <div className="row m-0 React-Table-Selection">
                                                    <div style={{ padding: '0.5% 2%' }}>
                                                        <button color="primary" className="btn btn-primary " onClick={statusNetBlocking(true)}>
                                                            Activate
                                                        </button>
                                                        &nbsp;  &nbsp;
                                                        <button color="primary" className="btn btn-primary " onClick={statusNetBlocking(false)}>
                                                            DeActivate
                                                        </button>
                                                        &nbsp;  &nbsp;
                                                        <Button color="primary" className="btn btn-primary " onClick={archiveNetBlocking} >
                                                            Archive
                                                        </Button>
                                                        <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                                            className="mdi mdi-close-thick font-size-18"
                                                        ></i></Button>
                                                    </div>
                                                </div>
                                            }
                                        </Col>
                                        <Col xl="12" className="table-responsive">
                                            <div className="">
                                                <BootstrapTable
                                                    keyField={keyField}
                                                    responsive
                                                    bordered={false}
                                                    striped={false}
                                                    defaultSorted={defaultSorted}
                                                    selectRow={selectRow}
                                                    classes={
                                                        "table align-middle table-nowrap"
                                                    }
                                                    headerWrapperClasses={"thead-light"}
                                                    {...toolkitProps.baseProps}
                                                    pagination={paginationFactory(pageOptions)}
                                                    noDataIndication={<h5 className="text-center">No Data</h5>} />
                                            </div>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            )}
                        </ToolkitProvider>
                    </CardBody>
                </Card>
            </Col>
            <Offcanvas isOpen={isRight} direction='end'  >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {selectedData ? 'Edit Interest ' : 'New Interest'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <UploadBlockings networkBlockType="interest" closeCanvas={toggleDrawer(false, '')} selectedData={selectedData} selectedDataLists={selectedLists} />
                </OffcanvasBody>
            </Offcanvas>
            </Row>
            }
        </Row>

    )
}

Interests.propTypes = {}

export default Interests
