/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Spinner, NavItem, Collapse, Button,
  NavLink,
  TabContent,
  TabPane, InputGroup, Tooltip
} from "reactstrap";
import PropTypes from "prop-types";
import axios from "axios";
import Select, { StylesConfig } from 'react-select';
import classnames from "classnames"
import queryString from 'query-string';

import _ from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// actions
import {
  updateAdUnitBid as onUpdateHBBuyer,
  addHeaderBidSuccess,
  addHeaderBidFail,
  updateAdUnitBidSuccess,
  updateAdUnitBidFail,
} from "store/HeaderBidding/HeaderBid/actions";
import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";

const AssociateAdUnits = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loader, setLoader] = useState(false);
  const [itemOpen, setItemOpen] = useState('')
  const [videosList, setVideosList] = useState([])
  const parsed = queryString.parse(window.location.search);
  const [adUnitSettings, setAdUnitSettings] = useState([{
    settingsEnabled: true,
    settingConfigMode: 'SimpleMode',
    settingInfo: videosList
  }])
  const [buyerRequestsState, setBuyerRequestsState] = useState('default')
  const [adUnitSettingsInfo, setAdUnitSettingsInfo] = useState({
    size: '',
    timeout: 1500,
    buyerRequests: 2,
    floorPrice: {
      floorType: 'RON',
      floorValues: {
        'RON': {
          price: 0.01,
          enabled: true,
        }
      }
    },
    optimizationStrategy: {
      type: 'floor',
      price: 0.03
    }
  })

  const [adUnitData, setAdUnitData] = useState([])
  const [selectedAdUnit, setSelectedAdUnit] = useState([]);

  console.log(props)

  const [hbAdUnitInfoData, setHbBuyerInfoData] = useState("");

  const handleChangeInfo = (e) => {
    const { name, value } = e.target;
    setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, [name]: value }));
  }

  const handleChangeInfoNumber = (e) => {
    const { name, value } = e.target;
    setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, [name]: Number(value) }));
  }

  const handleChangeOnBlurRequests = (e) => {
    const { name, value } = e.target;
    if (Number(value) < 1) {
      setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, [name]: 1 }));
    } else {
      setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, [name]: Number(value) }));
    }
  }

  const [floorPriceValues, setFloorPriceValues] = useState({
    RON: 0.01,
    ROW: 0.01,
    GCC: 0.01,
    Tier1: 0.01,
    APAC: 0.01,
  })

  const changePostBidMeasurementType = (e) => {
    const { name, value } = e.target;
    setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, floorPrice: { ...adUnitSettingsInfo.floorPrice, [name]: value } }));
    if (e.target.value === 'RON') {
      setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, ROW: 0.01, GCC: 0.01, Tier1: 0.01, APAC: 0.01 }));
    } else if (e.target.value === 'GeoGroup') {
      setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, RON: 0.01 }));
    } else {
      setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, RON: 0.01, ROW: 0.01, GCC: 0.01, Tier1: 0.01, APAC: 0.01 }));
    }
  }

  const floorPriceValueChange = (e) => {
    const { name, value } = e.target;
    console.log(floorPriceValues)
    setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, [name]: value }));
  }

  const handleChangeStratergy = (e) => {
    setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, optimizationStrategy: { ...adUnitSettingsInfo.optimizationStrategy, price: 0.03 } }));
    setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, optimizationStrategy: { ...adUnitSettingsInfo.optimizationStrategy, type: e.target.name } }));
  }

  const handleChangeStratergyPrice = (e) => {
    setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, optimizationStrategy: { ...adUnitSettingsInfo.optimizationStrategy, price: e.target.value } }));
  }

  const openCollapsed = (panel) => (event, newExpanded) => {
    console.log(panel)
    setItemOpen(panel)
  };

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
    "url": '',
    "requestBody": {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name: userInfo.name,
      hB_BuyerID: props.selectedBuyerID
    }
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 1000,
    extendedTimeOut: 1000
  };

  const addNewAdUnitSettings = () => {
    console.log(adUnitSettings)
    const newVcGroup = {
      settingsEnabled: true,
      settingConfigMode: 'SimpleMode',
      settingInfo: videosList
    }
    setAdUnitSettings([...adUnitSettings, newVcGroup]);
    // props.selectedItems({ hB_AdUnitsAssociatedInfo: [...adUnitSettings, newVcGroup] })
  }

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.headerBids.errorMsg,
    successMsg: state.headerBids.successMsg,
  }));

  useEffect(() => {
    console.log(successMsg)
    if (successMsg !== null) {
      console.log(successMsg)
      if (successMsg.success) {
        dispatch(updateAdUnitBidSuccess(""));
        dispatch(updateAdUnitBidFail(null));
        setLoader(false)
        props.closeCanvas('Update')
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if (errorMsg.status && errorMsg.status === 500) {
      } else {
        dispatch(updateAdUnitBidSuccess(""));
        dispatch(updateAdUnitBidFail(null));
      }
    }
  }, [errorMsg]);


  console.log(errorMsg, successMsg)

  useEffect(() => {


    axios.post("/OP/HB_Buyers/read/getAllBidder", body.requestBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is headerBids ==>", res)
        if (res.data.data) {
          res.data.data.map((itemBid, index) => {
            if (props.selectedBidder === itemBid.BidderName) {
              if (itemBid.BidderJSON) {
                itemBid.BidderJSON.map((ele, index) => {
                  ele.value = ""
                })
                let OriginalBidder = itemBid.BidderJSON
                if (props.selectedHBAdUnit !== '') {
                  setIsEdit(true)
                  setVideosList([...itemBid.BidderJSON])
                  setSelectedAdUnit({ value: props.selectedHBAdUnit.adUnitID, label: props.selectedHBAdUnit.aUnitName })
                  const bodyforAdUnit = {
                    "url": (userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency') ? "http://127.0.0.1:3006/v1.0/Demand/HeaderBidding/HB_Buyers/getAssociateAdUnit/" + props.selectedBuyerID : "/OP/HB_Buyers/getAssociateAdUnit/" + props.selectedBuyerID,
                    "requestBody": {
                      userID: userInfo.userID,
                      userRole: userInfo.userRole,
                      companyID: userInfo.companyID,
                      name: userInfo.name,
                      adUnitID: props.selectedHBAdUnit.adUnitID
                    }
                  }
                  axios.post(bodyforAdUnit.url, bodyforAdUnit.requestBody,
                    {
                      headers: headers
                    })
                    .then((res) => {
                      console.log("result is notAssociated headerBids ==>", res)
                      if (res.data.response) {
                        console.log(res.data.response)
                        setAdUnitSettingsInfo(res.data.response)
                        if (res.data.response.buyerRequests && res.data.response.buyerRequests !== 2) {
                          setBuyerRequestsState('custom')
                        }
                        if (res.data.response.floorPrice.floorType === 'RON') {
                          setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, RON: res.data.response.floorPrice.floorValues.RON.price }));
                        }
                        else {
                          if (res.data.response.floorPrice.floorValues.ROW.enabled) {
                            setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, ROW: res.data.response.floorPrice.floorValues.ROW.price }));
                          }
                          if (res.data.response.floorPrice.floorValues.GCC.enabled) {
                            setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, GCC: res.data.response.floorPrice.floorValues.GCC.price }));
                          }
                          if (res.data.response.floorPrice.floorValues.Tier1.enabled) {
                            setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, Tier1: res.data.response.floorPrice.floorValues.Tier1.price }));
                          }
                          if (res.data.response.floorPrice.floorValues.APAC.enabled) {
                            setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, APAC: res.data.response.floorPrice.floorValues.APAC.price }));
                          }
                        }
                        if (res.data.response.HB_AdUnitsAssociatedInfo && res.data.response.HB_AdUnitsAssociatedInfo.length > 0) {
                          let resulData = res.data.response.HB_AdUnitsAssociatedInfo
                          res.data.response.HB_AdUnitsAssociatedInfo.map((item, i) => {
                            let setsObjecttoArray = []
                            if (item.settingInfo) {
                              // setsObjecttoArray.push(item.settingInfo)
                              let bidderJSONArray = OriginalBidder
                              setsObjecttoArray = Object.entries(item.settingInfo).reduce((acc, curr) => {
                                const [key, val] = curr;
                                acc.push({
                                  ParameterName: key,
                                  value: val,
                                  type: 'string',
                                  required: false,
                                });
                                return acc;
                              }, []);
                              console.log(setsObjecttoArray, OriginalBidder)
                              const output = OriginalBidder.map(a => ({
                                ...a,
                                value: setsObjecttoArray.find(x => x.ParameterName === a.ParameterName ? x.value : "")
                              }));
                              console.log(output)
                              let arr3 = setsObjecttoArray.concat(output.filter(({ ParameterName }) => !setsObjecttoArray.find(f => f.ParameterName == ParameterName)));
                              console.log(arr3)
                              setVideosList(([...arr3]))

                              console.log(setsObjecttoArray)
                              resulData[i].settingInfo = [...arr3]
                            }
                          })
                          setAdUnitSettings(resulData);
                        }
                      }
                    })
                    .catch((err) => {
                      console.log("e", err)
                    });
                } else {
                  console.log(itemBid.BidderJSON)
                  setVideosList([...itemBid.BidderJSON])
                  let videoContentList = [...adUnitSettings]
                  videoContentList[0] = { ...videoContentList[0], settingInfo: [...itemBid.BidderJSON] };
                  setAdUnitSettings([...videoContentList]);
                }
              }
            }
          })

          // if (props.selectedHBBuyer !== '') {
          //   creativeBodyInfo = []
          //   creativeBodyInfo.push(props.selectedHBBuyer.hB_BuyerInfo)
          //   res.data.data.map((item, index) => {
          //     if (props.selectedHBBuyer.hB_Bidder === item.BidderName) {
          //       setSelectedBidders({ value: item.BidderID, label: item.BidderName });
          //       if (item.BidderJSON) {
          //         item.BidderJSON.map((ele, index) => {
          //           let ParameterName = ele.ParameterName
          //           console.log(item, creativeBodyInfo[0], creativeBodyInfo[0][ParameterName])
          //           ele.value = creativeBodyInfo[0][ParameterName]
          //         })
          //         console.log(item.BidderJSON)
          //         setVideosList(([...item.BidderJSON]))
          //       }
          //     }
          //   })
          // }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });

  }, []);

  // useEffect(() => {
  //   if (props.selectedHBAdUnit !== '') {
  //     setIsEdit(true)
  //     setSelectedAdUnit({ value: props.selectedHBAdUnit.adUnitID, label: props.selectedHBAdUnit.aUnitName })
  //     const bodyforAdUnit = {
  //       "url": "http://127.0.0.1:2002/v1.0/HB_Buyers/getAssociateAdUnit/" + props.selectedBuyerID,
  //       "requestBody": {
  //         userID: userInfo.userID,
  //         userRole: userInfo.userRole,
  //         companyID: userInfo.companyID,
  //         name: userInfo.name,
  //         adUnitID: props.selectedHBAdUnit.adUnitID
  //       }
  //     }
  //     axios.post("/v1.0/connectOP", bodyforAdUnit,
  //       {
  //         headers: headers
  //       })
  //       .then((res) => {
  //         console.log("result is notAssociated headerBids ==>", res)
  //         if (res.data.response) {
  //           setAdUnitSettingsInfo(res.data.response)
  //           if (res.data.response.HB_AdUnitsAssociatedInfo && res.data.response.HB_AdUnitsAssociatedInfo.length > 0) {
  //             console.log(videosList)
  //             debugger
  //             res.data.response.HB_AdUnitsAssociatedInfo.map((item, i) => {
  //               let setsObjecttoArray = []
  //               if (item.settingInfo) {
  //                 setsObjecttoArray = Object.entries(item.settingInfo).reduce((acc, curr) => {
  //                   const [key, val] = curr;
  //                   acc.push({
  //                     ParameterName: key,
  //                     value: val
  //                   });
  //                   return acc;
  //                 }, []);
  //                 console.log(setsObjecttoArray)
  //                 res.data.response.HB_AdUnitsAssociatedInfo[i].settingInfo = setsObjecttoArray
  //               }
  //             })
  //             setAdUnitSettings(res.data.response.HB_AdUnitsAssociatedInfo);
  //           }
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("e", err)
  //       });
  //   }
  // }, []);

  useEffect(() => {

    body.url = (userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency') ? "http://127.0.0.1:3006/v1.0/Demand/HeaderBidding/HB_Buyers/read/Adunit" : "/OP/HB_Buyers/read/Adunit"
    if (userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency') {
      body.requestBody = { ...body.requestBody, partnerName: userInfo.name, partnerID: userInfo.userID }
    }
    axios.post(body.url, body.requestBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is notAssociated headerBids ==>", res)
        if (res.data.data) {
          setAdUnitData(objectModal(res.data.data))
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }, []);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.adUnitID
      obj.label = item.aUnitName
      dataList.push(obj)
    })
    return dataList
  }

  const removePriorities = (index) => (e) => {
    console.log(index)
    const uPriorities = adUnitSettings.filter((gp, ind) => {
      return ind !== index
    }).map((gpm, p) => {
      return {
        ...gpm,
      }
    })
    setAdUnitSettings(uPriorities)
    // props.selectedItems({ hB_AdUnitsAssociatedInfo: uPriorities })
  }


  useEffect(() => {
    // if (props.selectedHBAdUnit !== '') {
    //   setIsEdit(true)
    //   setHBAdUnit(props.selectedHBAdUnit)
    // }
  }, []);

  const handleChange = (vIndex, e) => {
    console.log("G", vIndex, e);
    const { name, value } = e.target;
    console.log("G", [name], value);
    const updatedVastTags = adUnitSettings.map((vcGroup, j) => {
      if (vIndex === j) {
        return {
          ...vcGroup, [name]: value
        }
      }
      return vcGroup;
    })
    setAdUnitSettings(updatedVastTags)
    // props.selectedItems({ hB_AdUnitsAssociatedInfo: updatedVastTags })
  }

  const handleChangeCheck = (vIndex, e) => {
    const { name, value } = e.target;
    const updatedVastTags = adUnitSettings.map((vcGroup, j) => {
      if (vIndex === j) {
        return {
          ...vcGroup, [name]: e.target.checked
        }
      }
      return vcGroup;
    })
    setAdUnitSettings(updatedVastTags)
    // props.selectedItems({ hB_AdUnitsAssociatedInfo: updatedVastTags })
  }

  const handleChangeMode = (index) => e => {
    const { name, value } = e.target;
    let videoContentList = [...adUnitSettings]
    console.log(videoContentList)
    videoContentList[index] = { ...videoContentList[index], settingConfigMode: name };
    console.log(videoContentList)
    setAdUnitSettings([...videoContentList]);
  }

  const addDirectVideoContent = (index) => e => {
    console.log(index, adUnitSettings[index].settingInfo)
    let videoContentList = [...adUnitSettings[index].settingInfo]
    videoContentList.push({
      id: videoContentList.length + 1,
      ParameterName: '',
      value: '',
      type: 'string',
      required: false,
    })
    let newList = [...adUnitSettings]
    newList[index].settingInfo = [...videoContentList]
    console.log(newList)
    setAdUnitSettings([...newList]);
    // props.selectedItems({ hB_AdUnitsAssociatedInfo: [...newList] })
  }

  const handleChangeUser = (index, topIndex) => e => {
    const { name, value } = e.target;
    let videoContentList = [...adUnitSettings[topIndex].settingInfo]


    if (videoContentList[index].type === "integer") {
      videoContentList[index] = { ...videoContentList[index], [name]: Number(value) };
    } else if (videoContentList[index].type === "boolean") {
      videoContentList[index] = { ...videoContentList[index], [name]: JSON.parse(value) };
    } else {
      videoContentList[index] = { ...videoContentList[index], [name]: value };
    }


    let newList = [...adUnitSettings]
    newList[topIndex].settingInfo = [...videoContentList]
    console.log(newList)
    setAdUnitSettings([...newList]);
  }

  const removeDirectContent = (i, topIndex) => e => {
    let videoContentList = [...adUnitSettings[topIndex].settingInfo]
    videoContentList.splice(i, 1)
    let newList = [...adUnitSettings]
    newList[topIndex].settingInfo = [...videoContentList]
    console.log(newList)
    setAdUnitSettings([...newList]);
    // props.selectedItems({ hB_AdUnitsAssociatedInfo: [...newList] })
  }

  var videoContentListforAdUnitSettings = [...adUnitSettings]

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    console.log(floorPriceValues, adUnitSettingsInfo)
    let partitionValidation = checkBidderData()
    if (partitionValidation && selectedAdUnit.value && adUnitSettingsInfo.size) {
      let floorPriceSets = {}
      let floorPriceSetValues = {}
      let GeoExistValues = {}
      let floorValueforFP = 0.01
      if (adUnitSettingsInfo.floorPrice.floorType === 'RON') {
        floorPriceSets = {
          floorType: adUnitSettingsInfo.floorPrice.floorType,
          floorValues: {
            "RON": { price: floorPriceValues.RON ? Number(floorPriceValues.RON) : 0.01, enabled: true }
          }
        }
        floorValueforFP = floorPriceValues.RON ? Number(floorPriceValues.RON) : 0.01
      }
      if (adUnitSettingsInfo.floorPrice.floorType === 'GeoGroup') {
        floorValueforFP = 0.01
        floorPriceSetValues = { ...floorPriceSetValues, 'ROW': { price: floorPriceValues.ROW ? Number(floorPriceValues.ROW) : 0.01, enabled: floorPriceValues.ROW && (Number(floorPriceValues.ROW) > 0) ? true : false } }
        floorPriceSets = { ...floorPriceSets, floorType: 'GeoGroup', floorValues: floorPriceSetValues }
        GeoExistValues = { ...GeoExistValues, ROW: floorPriceValues.ROW && (Number(floorPriceValues.ROW) > 0) ? true : false }
        floorPriceSetValues = { ...floorPriceSetValues, 'GCC': { price: floorPriceValues.GCC ? Number(floorPriceValues.GCC) : 0.01, enabled: floorPriceValues.GCC && (Number(floorPriceValues.GCC) > 0) ? true : false } }
        floorPriceSets = { ...floorPriceSets, floorType: 'GeoGroup', floorValues: floorPriceSetValues }
        GeoExistValues = { ...GeoExistValues, GCC: floorPriceValues.GCC && (Number(floorPriceValues.GCC) > 0) ? true : false }
        floorPriceSetValues = { ...floorPriceSetValues, 'Tier1': { price: floorPriceValues.Tier1 ? Number(floorPriceValues.Tier1) : 0.01, enabled: floorPriceValues.Tier1 && (Number(floorPriceValues.Tier1) > 0) ? true : false } }
        floorPriceSets = { ...floorPriceSets, floorType: 'GeoGroup', floorValues: floorPriceSetValues }
        GeoExistValues = { ...GeoExistValues, Tier1: floorPriceValues.Tier1 && (Number(floorPriceValues.Tier1) > 0) ? true : false }
        floorPriceSetValues = { ...floorPriceSetValues, 'APAC': { price: floorPriceValues.APAC ? Number(floorPriceValues.APAC) : 0.01, enabled: floorPriceValues.APAC && (Number(floorPriceValues.APAC) > 0) ? true : false } }
        floorPriceSets = { ...floorPriceSets, floorType: 'GeoGroup', floorValues: floorPriceSetValues }
        GeoExistValues = { ...GeoExistValues, APAC: floorPriceValues.APAC && (Number(floorPriceValues.APAC) > 0) ? true : false }
      }
      if (adUnitSettingsInfo.floorPrice.floorType === 'GeoGroup') {
        let notExistValue = Object.values(GeoExistValues).includes(false)
        if (!notExistValue) {
          let bodyAdUnitSettings = {
            adUnitID: selectedAdUnit.value,
            adUnitName: selectedAdUnit.label,
            floorPrice: floorPriceSets,
            size: adUnitSettingsInfo.size,
            buyerRequests: adUnitSettingsInfo.buyerRequests,
            timeout: Number(adUnitSettingsInfo.timeout),
            adUnitSettings: manipulateData(),
            optimizationStrategy: {
              type: adUnitSettingsInfo.optimizationStrategy.type,
              price: Number(adUnitSettingsInfo.optimizationStrategy.price)
            },
            fp: floorValueforFP,
            isUpdate: isEdit
          }
          console.log(bodyAdUnitSettings)
          setLoader(true)
          dispatch(onUpdateHBBuyer({
            ...bodyAdUnitSettings, hB_BuyerID: props.selectedBuyerID, userRole: userInfo.userRole, userID: userInfo.userID,structure:'old'
          }));
        } else {
          toastr.error('Please Enter Price for GeoGroup')
        }
      } else {
        if (floorPriceValues.RON && (Number(floorPriceValues.RON) > 0)) {
          let bodyAdUnitSettings = {
            adUnitID: selectedAdUnit.value,
            adUnitName: selectedAdUnit.label,
            floorPrice: floorPriceSets,
            size: adUnitSettingsInfo.size,
            buyerRequests: adUnitSettingsInfo.buyerRequests,
            timeout: Number(adUnitSettingsInfo.timeout),
            adUnitSettings: manipulateData(),
            optimizationStrategy: {
              type: adUnitSettingsInfo.optimizationStrategy.type,
              price: Number(adUnitSettingsInfo.optimizationStrategy.price)
            },
            fp: floorValueforFP,
            isUpdate: isEdit
          }
          console.log(bodyAdUnitSettings)
          setLoader(true)
          dispatch(onUpdateHBBuyer({
            ...bodyAdUnitSettings, hB_BuyerID: props.selectedBuyerID, userRole: userInfo.userRole, userID: userInfo.userID,structure:'old',
          }));
        } else {
          toastr.error('Please Enter Price for RON')
        }
      }

    }
  }

  const manipulateData = () => {
    let videoContentListforAdUnit = adUnitSettings
    videoContentListforAdUnit.map((item, i) => {
      let setsObject = {}
      if (item.settingInfo && item.settingInfo.length > 0) {
        item.settingInfo.map((elem, j) => {
          if (elem.value) {
            setsObject = { ...setsObject, [elem.ParameterName]: elem.value }
          }
        })
        videoContentListforAdUnit[i].settingInfo = setsObject
      }
    })
    console.log(videoContentListforAdUnit)
    return videoContentListforAdUnit
  }

  const handleSelectAdUnit = adUnit => {
    setSelectedAdUnit(adUnit);
  }

  function allowOnlyNumbers(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault(); }
  };

  const checkBidderData = () => {
    if (!selectedAdUnit.value) {
      toastr.error('Please Select AdUnit to Associate')
      return false
    }
    else if (adUnitSettingsInfo.timeout && (Number(adUnitSettingsInfo.timeout) < 1000)) {
      toastr.error('Please Enter Bidder Timeout greater than 1000 ms')
      return false
    }
    else if (!adUnitSettingsInfo.timeout) {
      toastr.error('Please Enter Bidder Timeout')
      return false
    }
    // else if (adUnitSettingsInfo.floorPrice && (Number(adUnitSettingsInfo.floorPrice) < 0.01)) {
    //   toastr.error('Please Enter Floor Price greater than 1 cent')
    //   return false
    // }
    // else if (!adUnitSettingsInfo.floorPrice) {
    //   toastr.error('Please Enter Floor Price')
    //   return false
    // } 
    else {
      return true
    }
  }



  return (
    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
        <Form onSubmit={handleSubmit}  >
          <Row className="formBodyScroll" style={{ padding: '1% 3%', height: '85vh', overflow: 'auto', width: '100%' }}>
            <Col xl={12} className="mb-4">
              <Row>
                <Col lg="12" className="p-2">
                  <div className="content clearfix ">
                    <h5>HB Buyer : {parsed.buyerName}</h5> &nbsp;&nbsp;
                    <div className="mb-3">
                      <Label className="form-label">Associate AdUnits </Label>
                      <Select
                        value={selectedAdUnit}
                        onChange={s => {
                          handleSelectAdUnit(s)
                        }}
                        options={adUnitData}
                        classNamePrefix="select2-selection "
                        className="col-6"
                        isDisabled={isEdit}
                      />
                    </div>
                    <div className="col-6">
                      <div className="mb-1">
                        <Label >Buyer Requests </Label>
                        <div className="row">
                          <Col md={6}>
                            <div className="form-check mt-2 mb-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="default"
                                id="default"
                                checked={buyerRequestsState === 'default'}
                                onChange={(e) => {
                                  setBuyerRequestsState('default')
                                  setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, buyerRequests: 2 }))
                                }}
                              />
                              <label className="form-check-label  m-2 mt-1" htmlFor="default"> Default  </label>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form-check mt-2 mb-2">
                              <input
                                className="form-check-input "
                                type="radio"
                                name="custom"
                                id="custom"
                                checked={buyerRequestsState === 'custom'}
                                onChange={(e) => setBuyerRequestsState('custom')}
                              />
                              <label className="form-check-label  m-2 mt-1" htmlFor="custom"> Custom</label>
                            </div>
                          </Col>
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">No of Buyer Requests</Label>
                        <Input
                          name="buyerRequests"
                          type="text"
                          onChange={handleChangeInfoNumber} onKeyPress={allowOnlyNumbers}
                          className={'form-control'}
                          value={adUnitSettingsInfo.buyerRequests}
                          onBlur={handleChangeOnBlurRequests}
                          readOnly={buyerRequestsState === 'default'}
                        />
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Floor Price Type</Label>
                        <Input type="select" name="floorType"
                          onChange={changePostBidMeasurementType} value={adUnitSettingsInfo.floorPrice.floorType}
                          className={'form-control form-select'} >
                          <option value='RON'> RON </option>
                          <option value='GeoGroup'> Geo Group </option>
                        </Input>
                      </div>
                      <div className="mb-3">
                        {adUnitSettingsInfo.floorPrice.floorType === "RON" &&
                          <div className="row mb-3 m-1">
                            <div className="col-5"> <Label className="mt-2">RON Price </Label></div>
                            <div className="col-7">
                              <InputGroup>
                                <div className="input-group-text">$</div>
                                <Input
                                  name="RON"
                                  type="text"
                                  onChange={floorPriceValueChange} onKeyPress={allowOnlyNumbers}
                                  className={'form-control'}
                                  value={floorPriceValues.RON}
                                />
                              </InputGroup>
                            </div>
                          </div>
                        }

                        {adUnitSettingsInfo.floorPrice.floorType === "GeoGroup" &&
                          <div>
                            <div className="row mb-3 m-1">
                              <div className="col-5" > <Label className="mt-2">ROW </Label></div>
                              <div className="col-7">
                                <InputGroup>
                                  <div className="input-group-text">$</div>
                                  <Input
                                    name="ROW"
                                    type="text" onKeyPress={allowOnlyNumbers}
                                    onChange={floorPriceValueChange}
                                    value={floorPriceValues.ROW}
                                  />
                                </InputGroup>
                              </div>
                            </div>

                            <div className="row mb-3 m-1">
                              <div className="col-5"> <Label className="mt-2">GCC </Label></div>
                              <div className="col-7">
                                <InputGroup>
                                  <div className="input-group-text">$</div>
                                  <Input
                                    name="GCC"
                                    type="text" onKeyPress={allowOnlyNumbers}
                                    onChange={floorPriceValueChange}
                                    value={floorPriceValues.GCC}
                                  />
                                </InputGroup>
                              </div>
                            </div>

                            <div className="row mb-3 m-1">
                              <div className="col-5"> <Label className="mt-2">Tier-1 </Label></div>
                              <div className="col-7">
                                <InputGroup>
                                  <div className="input-group-text">$</div>
                                  <Input
                                    name="Tier1"
                                    type="text" onKeyPress={allowOnlyNumbers}
                                    onChange={floorPriceValueChange}
                                    value={floorPriceValues.Tier1}
                                  />
                                </InputGroup>
                              </div>
                            </div>

                            <div className="row mb-3 m-1">
                              <div className="col-5"> <Label className="ml-2 mt-2">APAC </Label></div>
                              <div className="col-7">
                                <InputGroup>
                                  <div className="input-group-text">$</div>
                                  <Input
                                    name="APAC"
                                    type="text" onKeyPress={allowOnlyNumbers}
                                    onChange={floorPriceValueChange}
                                    value={floorPriceValues.APAC}
                                  />
                                </InputGroup>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                      <div className="mb-3">
                        <Label >Optimization Stratergy </Label>
                        <div className="row">
                          <Col md={6}>
                            <div className="form-check mt-2 mb-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="floor"
                                id="floor"
                                checked={adUnitSettingsInfo.optimizationStrategy.type === 'floor'}
                                onChange={handleChangeStratergy}
                              />
                              <label className="form-check-label  m-2 mt-1" htmlFor="floor"> Optimization for Floor  </label>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form-check mt-2 mb-2">
                              <input
                                className="form-check-input "
                                type="radio"
                                name="fill"
                                id="fill"
                                checked={adUnitSettingsInfo.optimizationStrategy.type === 'fill'}
                                onChange={handleChangeStratergy}
                              />
                              <label className="form-check-label  m-2 mt-1" htmlFor="fill"> Optimization for Fill</label>
                            </div>
                          </Col>
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Optimization CPM</Label>
                        <InputGroup>
                          <div className="input-group-text">$</div>
                          <Input
                            name="price"
                            type="text"
                            onChange={handleChangeStratergyPrice} onKeyPress={allowOnlyNumbers}
                            className={'form-control'}
                            value={adUnitSettingsInfo.optimizationStrategy.price}
                            readOnly={adUnitSettingsInfo.optimizationStrategy.type === 'fill'}
                          />
                        </InputGroup>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Size</Label>
                        <Input type="select" name="size"
                          onChange={handleChangeInfo} value={adUnitSettingsInfo.size}
                          className={'form-control form-select' + (submitted && !adUnitSettingsInfo.size ? ' is-invalid' : '')}
                        >
                          <option value=''> Select</option>
                          <option value="0x0">0x0 </option>
                          <option value="1x1">1x1 </option>
                          <option value="300x250">300x250 </option>
                          <option value="300x600">300x600 </option>
                          <option value="468x100">468x100 </option>
                          <option value="728x90">728x90 </option>
                          <option value="800x600">800x600 </option>
                          <option value="120x600">120x600 </option>
                          <option value="160x600">160x600 </option>
                          <option value="970x250">970x250 </option>
                          <option value="970x90">970x90</option>
                          <option value="320x50">320x50</option>
                          <option value="320x100">320x100 </option>
                          <option value="320x480">320x480 </option>
                          <option value="300x50">300x50 </option>
                          <option value="300x100">300x100 </option>
                          <option value="468x60">468x60 </option>
                          <option value="468x90">468x90</option>
                          <option value="336x280">336x280 </option>
                          <option value="800x485"> 800x485 </option>
                          <option value="320x442"> 320x442 </option>

                        </Input>
                        {submitted && !adUnitSettingsInfo.size ? (
                          <FormFeedback type="invalid">{'Please Select Size'}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Timeout</Label>
                        <InputGroup>
                          <Input
                            name="timeout"
                            type="text"
                            onChange={handleChangeInfo}
                            className={'form-control'} onKeyPress={allowOnlyNumbers}
                            value={adUnitSettingsInfo.timeout}
                          />
                          <div className="input-group-text">ms</div>
                        </InputGroup>
                      </div>
                    </div>
                    <div className="mt-4 mb-4">

                      {adUnitSettings && adUnitSettings.map((item, index) => {
                        console.log("group............Item", item, "adUnitSettings==========", adUnitSettings);
                        return (
                          <div className="adUnitSettings-settings" key={index}>
                            <div className="accordion-item ml-0 mr-0 mb-4 mt-4">
                              <h2 className="accordion-header row m-0" id="headingOne">
                                <button
                                  className={classnames(
                                    "accordion-button",
                                    "fw-medium", { collapsed: itemOpen !== ('panel' + (index)) }
                                  )}
                                  type="button"
                                  onClick={openCollapsed('panel' + (index))}
                                  style={{ cursor: "pointer" }}
                                >
                                  <h5 className="heading5 mt-2">Condition {index + 1}</h5>

                                  {
                                    adUnitSettings.length === 1 && <button className='btn  btn-danger disabled-rmg rem-grp-btn ' style={{ position: 'absolute', right: '3pc', margin: '1% 3% 1% auto', width: 'fit-content', background: '#dc3545' }} disabled>Remove Condition</button>
                                  }
                                  {
                                    adUnitSettings.length !== 1 && <button className='btn btn-danger rem-grp-btn' style={{ position: 'absolute', right: '3pc', margin: '1% 3% 1% auto', width: 'fit-content', background: '#dc3545' }} onClick={removePriorities(index)}
                                    >Remove Condition</button>
                                  }
                                </button>

                              </h2>
                              {/* isOpen={itemOpen === ('panel'+(index))} */}
                              <Collapse isOpen={true} className="accordion-collapse">
                                <div className="accordion-body">
                                  <div className="mb-1" style={{ position: 'absolute', right: '6.5pc' }}>
                                    <div className="form-check form-switch form-check-success form-switch-md  mt-1  " >
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="settingsEnabled"
                                        name="settingsEnabled"
                                        checked={item.settingsEnabled}
                                        onChange={(e) => handleChangeCheck(index, e)}
                                      />
                                      <label className="form-check-label  m-2 mt-1 " htmlFor="settingsEnabled">
                                        {item.settingsEnabled ? <span color='text-success' style={{ color: '#34c38f' }}>Conditions Enabled </span> : <span style={{ color: '#a3a3a3' }}>Settings Disabled</span>}
                                      </label>
                                    </div>
                                  </div>

                                  <div className="mb-3">
                                    <Label >Configuration </Label>
                                    <div className="row">
                                      <Col md={4}>
                                        <div className="form-check mt-2 mb-2">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="SimpleMode"
                                            id="SimpleMode"
                                            checked={item.settingConfigMode === 'SimpleMode'}
                                            onChange={handleChangeMode(index)}
                                          />
                                          <label className="form-check-label  m-2 mt-1" htmlFor="SimpleMode"> Simple mode  </label>
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="form-check mt-2 mb-2">
                                          <input
                                            className="form-check-input "
                                            type="radio"
                                            name="RawMode"
                                            id="RawMode"
                                            checked={item.settingConfigMode === 'RawMode'}
                                            onChange={handleChangeMode(index)}
                                          />
                                          <label className="form-check-label  m-2 mt-1" htmlFor="RawMode"> Raw mode</label>
                                        </div>
                                      </Col>
                                    </div>
                                  </div>
                                  {item.settingConfigMode === 'SimpleMode' &&
                                    <div className=" mb-3">
                                      {(Array.isArray(item.settingInfo)) && item.settingInfo.map((superSubItem, n) => {
                                        return (
                                          <div key={n}>
                                            <div className="contentSettings">
                                              {/* {i === 0 ? <h5 className='mt-3 mb-4'>Current User</h5> : <h5 className='mt-3 mb-4'>User {i}</h5>} */}
                                              <div className="row mt-1">
                                                <div className="col-4">
                                                  <div className="mb-3">
                                                    <Input
                                                      name="ParameterName"
                                                      type="text"
                                                      onChange={handleChangeUser(n, index)}
                                                      value={superSubItem.ParameterName}
                                                      className={'form-control'}
                                                      placeholder="Enter Key"
                                                    />
                                                  </div>
                                                  <br />
                                                </div>
                                                <div className="col-7">
                                                  <div className="mb-3">
                                                    {superSubItem.type === 'integer' ?
                                                      <Input
                                                        name="value"
                                                        type="text"
                                                        onChange={handleChangeUser(n, index)} onKeyPress={allowOnlyNumbers}
                                                        value={superSubItem.value}
                                                        className={'form-control'}
                                                        readOnly={props.selectedArchived}
                                                        placeholder="Enter Value"
                                                      /> : superSubItem.type === 'boolean' ?
                                                        <Input
                                                          name="value"
                                                          type="select"
                                                          onChange={handleChangeUser(n, index)}
                                                          value={superSubItem.value}
                                                          readOnly={props.selectedArchived}
                                                          className={'form-control form-select'}
                                                        >
                                                          <option value="">Select  </option>
                                                          <option value={true}>True</option>
                                                          <option value={false}>False</option>
                                                        </Input> : <Input
                                                          name="value"
                                                          type="text"
                                                          onChange={handleChangeUser(n, index)}
                                                          value={superSubItem.value}
                                                          readOnly={props.selectedArchived}
                                                          className={'form-control'}
                                                          placeholder="Enter Value"
                                                        />
                                                    }
                                                  </div>
                                                  <br />
                                                </div>
                                                <div className="col-1 p-0">
                                                  <button type='button' className="text-danger waves-effect btn btn-link" onClick={removeDirectContent(n, index)} style={{ position: 'absolute', right: '50%' }} >
                                                    <i
                                                      className="bx bx-trash font-size-18"
                                                      id="edittooltip"
                                                    ></i>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      })
                                      }
                                      {/* {createContentUI()} */}
                                      <div className='col-12'>
                                        <div className='d-flex'>
                                          <button type='button' className='mt-4 mb-4 btn btn-primary ml-auto' style={{ marginRight: '1rem  2.5rem 1rem auto' }} onClick={addDirectVideoContent(index)} >Add Parameter &nbsp;&nbsp; <i className="fa fa-plus"></i></button>

                                        </div>
                                      </div>
                                    </div>
                                  }
                                  {item.settingConfigMode === 'RawMode' &&
                                    <div className="mb-3">
                                      <Label className="form-label">JSON : </Label>
                                      <Input
                                        name="settingInfo"
                                        type="textarea"
                                        rows="7"
                                        onChange={(e) => handleChange(index, e)}
                                        className={'form-control'}
                                        value={JSON.stringify(item.settingInfo)}
                                        readOnly
                                      />
                                    </div>
                                  }
                                </div>
                              </Collapse>
                            </div>
                          </div>
                        )
                      }
                      )}
                      <div className='row'>
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          style={{ margin: '1% 3.0rem 1% auto', width: 'auto' }}
                          onClick={addNewAdUnitSettings}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Add New Condition
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">


                {isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }

                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>

              </div>
            </Col>
          </Row>
        </Form>
        {/* {
          errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
          <Toaster status="error" msg={errorMsg.message} />
        } */}
      </div >
      {/* {
        errorMsg !== null && errorMsg !== '' && errorMsg.status && errorMsg.status === 500 &&
        <div className="p-0 justify-content-center d-flex">
          <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')} />
        </div>
      } */}
    </React.Fragment >
  )
}

AssociateAdUnits.propTypes = {}

export default AssociateAdUnits

