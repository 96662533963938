import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from 'prop-types'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Modal,
    Input,
    Spinner,
    UncontrolledPopover,
    Popover,
    PopoverHeader,
    PopoverBody,
    Label
} from "reactstrap"

import moment from "moment";

import queryString from 'query-string';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import New_Supply_Tag from "./New_Supply_Tag";

import {
    loadActivitiesAds as onLoadAdUnits,
    getHouseAdUnits as onGetAdUnits,
    statusHouseAdUnit as onStatusAdUnit,
    archiveHouseAdUnit as onArchiveAdUnit,
    statusHouseAdUnitSuccess,
    archiveHouseAdUnitSuccess,
    addHouseAdUnitFail,
    updateHouseAdUnitFail,
} from "store/customTracking/AdUnits/actions";
import LoadingTable from "pages/LoaderPages/LoadingTable";

var selectedItem = []
var selectedData = ''
var selectedIndex = []

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import ProductDataTableLI from "Product_Helpers/ProductDataTableLI";

var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 2 : 1

var fromDate = ''
var toDate = ''
var filterCount = 0

let pageNumber = 1
let pageSize = 10
let totalRecords = 0
let searchText = ''

const Supply_Tag = props => {
    const dispatch = useDispatch();
    const [isRight, setIsRight] = useState(false);
    const [supplyTag, setSupplyTag] = useState([])
    const [isArchived, setIsArchived] = useState(false)
    const [selected, setSelected] = useState([])
    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)

    const [jsTagModalState, setJsTagModalState] = useState(false);
    const [popoverdismiss, setpopoverdismiss] = useState(false);
    const [readUnitData, setReadUnitData] = useState('')
    const inputRef = useRef();
    const [requestTotal, setRequestTotal] = useState(0)
    const [clicksTotal, setClicksTotal] = useState(0)
    const [statusSearch, setStatusSearch] = useState('Active')
    const parsed = queryString.parse(window.location.search);
    const closeRef = useRef();
    const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
    const userInfo = JSON.parse(localStorage.getItem('authUser'));

    const [state, setState] = useState({
        start: moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days'),
        end: moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
    });

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            setIsRight(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    const { start, end } = state;

    const handleEvent = (startDate, endDate, label) => {
        console.log(startDate, endDate)
        setState({ start: startDate, end: endDate, });
        fromDate = startDate._d.toISOString();
        toDate = endDate._d.toISOString();
        filterCount = filterCount + 1
        dispatch(onGetAdUnits({
            pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, parentID: userInfo.parentID, isForSupplyPartner: userInfo.isForSupplyPartner, filter: statusSearch, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '',
            "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : ''
        }));
    };

    const labelDate = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');


    const [switchLoader, setSwitchLoader] = useState(true)

    const handleSwitch = () => {
        setSwitchLoader(!switchLoader)
    }

    const [filterState, setFilterState] = useState(false)

    const statusChange = (e) => {
        if (e.target.value === 'Active') {
            filterCount = filterCount > 0 ? filterCount - 1 : 0
        }
        if (statusSearch === 'Active') {
            filterCount = filterCount + 1
        }
        setStatusSearch(e.target.value)
    }

    const applyFilter = (e) => {
        dispatch(onGetAdUnits({
            pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, parentID: userInfo.parentID, isForSupplyPartner: userInfo.isForSupplyPartner, filter: statusSearch, "startDate": fromDate ? fromDate ? moment(fromDate).format("YYYY-MM-DD") : '' : '', "endDate": toDate ? toDate ? moment(toDate).format("YYYY-MM-DD") : '' : '',
        }));
        if (filterCount > 0) {
            setFilterState(true)
        } else {
            setFilterState(false)
        }
    }

    const [isMenu, setisMenu] = useState(false)
    const toggleMenu = () => {
        setisMenu(!isMenu)
    }

    const clearAllFilter = (e) => {
        setStatusSearch('Active')
        filterCount = 0
        setFilterState(false)
        fromDate = ''
        toDate = ''
        setState({
            start: moment().subtract(0, 'days'),
            end: moment().subtract(0, 'days'),
        })
        dispatch(onGetAdUnits({
            pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, parentID: userInfo.parentID, isForSupplyPartner: userInfo.isForSupplyPartner, filter: 'Active', "startDate": '', "endDate": '',
        }));
    }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    useEffect(() => {
        dispatch(onGetAdUnits(null));
        totalRecords = 0
        pageNumber = 1
        pageSize = 10
        searchText = ''
        if (parsed.status === 'AdUnitCreateByPublisher') {
            setIsRight(true);
        }
    }, []);

    const selectRow = {
        mode: "checkbox",
        nonSelectable: [],
        selected: selected,
        onSelect: (row, isSelect, rowIndex, e) => {
            console.log(row, isSelect, rowIndex)
            if (isSelect) {
                setSelected([...selected, row.id])
                setSelectedRowsStatus(true)
                selectedIndex.push(row.houseAdsAdUnitID)
                selectedItem.push(row.houseAdsAdUnitID)
                console.log(selectedIndex)
            } else {
                setSelected(selected.filter(x => x !== row.id))
                let index = selectedIndex.indexOf(row.houseAdsAdUnitID)
                selectedIndex.splice(index, 1)
                let index2 = selectedItem.indexOf(row.houseAdsAdUnitID)
                selectedItem.splice(index2, 1)
                if (selectedIndex.length === 0) {
                    setSelectedRowsStatus(false)
                }
                console.log(selectedIndex)
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            const ids = rows.map(r => r.id);
            if (isSelect) {
                setSelected(ids)
                rows.map((item, index) => {
                    selectedIndex.push(item.houseAdsAdUnitID)
                    selectedItem.push(item.houseAdsAdUnitID)
                })
                setSelectedRowsStatus(true)
            } else {
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
                selectedItem = []
            }

        }
    };

    const handleClose = () => {
        setSelectedRowsStatus(false)
        selectedItem = []
        setSelected([])
        selectedIndex = []
    }

    const { adUnits, paginationData } = useSelector(state => ({
        adUnits: state.adUnit.adUs,
        paginationData: state.adUnit.pagination
    }));

    totalRecords = paginationData ? paginationData.totalRecords ? paginationData.totalRecords : 0 : 0

    const { loading } = useSelector(state => ({
        loading: state.adUnit.loading,
    }));

    const { noData } = useSelector(state => ({
        noData: state.adUnit.noData,
    }));

    const { adUnitsResponse } = useSelector(state => ({
        adUnitsResponse: state.adUnit.response,
    }));

    const { archiveAdUnitsResponse } = useSelector(state => ({
        archiveAdUnitsResponse: state.adUnit.archiveMsg,
    }));

    // const { errorMsg, successMsg } = useSelector(state => ({
    //   errorMsg: state.adUnits.errorMsg,
    //   successMsg: state.adUnits.successMsg,
    // }));

    useEffect(() => {
        console.log(loading)
        if (loading) {
            setSwitchLoader(true)
        }
    }, [loading]);

    console.log(adUnitsResponse)
    useEffect(() => {
        if (adUnitsResponse !== null) {
            if (adUnitsResponse.statusCode === 200) {
                toastr.success('AdUnit Status Changed Successfully')
                dispatch(onGetAdUnits({
                    pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, parentID: userInfo.parentID, isForSupplyPartner: userInfo.isForSupplyPartner, filter: statusSearch, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '',
                    "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : ''
                }));
                selectedItem = []
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
                dispatch(statusHouseAdUnitSuccess(null));
            }
        }
    }, [dispatch, adUnitsResponse]);

    useEffect(() => {
        if (archiveAdUnitsResponse !== null) {
            if (archiveAdUnitsResponse.statusCode === 200) {
                toastr.success('AdUnit Archived Successfully')
                dispatch(onGetAdUnits({
                    pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, parentID: userInfo.parentID, isForSupplyPartner: userInfo.isForSupplyPartner, filter: statusSearch, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '',
                    "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : ''
                }));
                selectedItem = []
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
                dispatch(archiveHouseAdUnitSuccess(null));
            }
        }
    }, [dispatch, archiveAdUnitsResponse]);

    const toggleRightCanvas = () => {
        closeRef.current.click()
        selectedData = ''
        dispatch(onGetAdUnits({
            pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, parentID: userInfo.parentID, isForSupplyPartner: userInfo.isForSupplyPartner, filter: statusSearch, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '',
            "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : ''
        }));
        dispatch(addHouseAdUnitFail(""));
        dispatch(updateHouseAdUnitFail(""));
        setIsRight(!isRight);
    };

    const toggleEnableScroll = () => {
        setIsEnableScroll(!isEnableScroll);
    };

    const toggleDrawer = (close, adUnit) => (event) => {
        closeRef.current.click()
        console.log(event)
        if (event === 'Create') {
            toastr.success('AdUnit Saved Successfully!...')
        }
        if (event === 'Update') {
            toastr.success('AdUnit Updated Successfully!...')
        }
        selectedData = adUnit
        setIsArchived(adUnit.isHouseAdsCategoryArchived)
        dispatch(onGetAdUnits({
            pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, parentID: userInfo.parentID, isForSupplyPartner: userInfo.isForSupplyPartner, filter: statusSearch, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '',
            "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : ''
        }));
        dispatch(addHouseAdUnitFail(""));
        dispatch(updateHouseAdUnitFail(""));
        setIsRight(close);
    };


    useEffect(() => {
        if (adUnits !== null) {
            dispatch(onGetAdUnits({
                pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, parentID: userInfo.parentID, isForSupplyPartner: userInfo.isForSupplyPartner, filter: statusSearch, "startDate": '',
                "endDate": ''
            }));
        }
    }, []);
    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const supplyTagListColumns = [
        {
            text: "id",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            dataField: "houseAdsAdUnitID",
            text: "ID",
            sort: true,
            footer: 'Total Requests'
        },
        {
            dataField: "houseAdsAdUnitPublisher",
            text: "Publisher Name",
            sort: true,
            footer: '',
            style: { wordBreak: 'break-word' }
        },

        {
            dataField: "houseAdsAdUnitName",
            text: "Ad Unit Name",
            sort: true,
            footer: '',
            style: { wordBreak: 'break-word' }
        },
        {
            dataField: "houseAdsAdUnitSize",
            text: "Size",
            sort: true,
            footer: ''
        },
        {
            dataField: "houseAdsCategoryStatus",
            text: "Status",
            sort: true,
            formatter: (cellContent, adUnit) => (
                <>
                    {adUnit.houseAdsCategoryStatus && !adUnit.isHouseAdsCategoryArchived &&
                        <span className="active-state">Active</span>
                    }
                    {!adUnit.houseAdsCategoryStatus && !adUnit.isHouseAdsCategoryArchived &&
                        <span className="inactive-state">Inactive</span>
                    }
                    {adUnit.isHouseAdsCategoryArchived &&
                        <span className="archived-state">Archived</span>
                    }
                </>
            ),
            footer: ''
        },
        {
            dataField: "requests",
            text: "Requests",
            sort: true,
            footer: `${requestTotal}`
        },
        {
            dataField: "clicks",
            text: "Clicks",
            sort: true,
            footer: `${clicksTotal}`
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',
            hidden: (UserRolePrivileges.HouseAds && UserRolePrivileges.HouseAds.HouseAdsAdUnits) && (!UserRolePrivileges.HouseAds.HouseAdsAdUnits.update),


            // eslint-disable-next-line react/display-name
            formatter: (cellContent, adUnit) => (
                <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
                    <Link className="Email-state" to="#" onClick={toggleDrawer(true, adUnit)}>
                        {adUnit.isHouseAdsCategoryArchived &&
                            'View'
                        }
                        {!adUnit.isHouseAdsCategoryArchived &&
                            'Edit'
                        }

                    </Link>
                    {!adUnit.isHouseAdsCategoryArchived &&
                        <span className="archived-state" onClick={() => { tog_standard(adUnit); }} style={{ cursor: 'pointer', width: 'max-content' }}>
                            View Tag
                        </span>
                    }

                </div>
            ),
        },
    ];

    useEffect(() => {
        console.log(adUnits)
        if (adUnits !== null && adUnits.length > 0) {
            let totalRequest = 0
            let totalClicks = 0
            adUnits.map((item, index) => {
                totalRequest = totalRequest + item.requests
                totalClicks = totalClicks + item.clicks
            })
            setRequestTotal(totalRequest)
            setClicksTotal(totalClicks)
        }

    }, [adUnits]);

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );


    const pageOptions = {
        paginationSize: 4,
        pageStartIndex: 1,
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };


    const defaultSorted = [
        {
            dataField: "requests", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
        {
            dataField: "clicks", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        }
    ];


    const archiveAdUnit = async () => {
        dispatch(onArchiveAdUnit({ idList: selectedItem }));
    }

    const statusAdUnit = (state) => (event) => {
        console.log(state)
        dispatch(onStatusAdUnit({ idList: selectedItem, houseAdsAdUnitStatus: state }));
    }

    if (adUnits.length > 0) {
        if (UserRolePrivileges.HouseAds && UserRolePrivileges.HouseAds.HouseAdsAdUnits) {
            if (!UserRolePrivileges.HouseAds.HouseAdsAdUnits.archive && !UserRolePrivileges.HouseAds.HouseAdsAdUnits.status) {
                adUnits.map((item, index) => {
                    selectRow.nonSelectable.push(index + 1)
                })
            } else {
                adUnits.map((item, index) => {
                    if (item.isHouseAdsCategoryArchived) {
                        selectRow.nonSelectable.push(index + 1)
                    }
                })
            }
        }
    }

    function tog_standard(adUnit) {
        console.log(adUnit)
        if (adUnit !== '') {
            setJsTagModalState(!jsTagModalState);
            setReadUnitData(adUnit)
        } else {
            setJsTagModalState(!jsTagModalState);
        }
    }

    const downloadTxtFile = () => {
        const element = document.createElement("a");
        const file = new Blob([readUnitData.houseAdUnitTag], {
            type: "text/plain"
        });
        element.href = URL.createObjectURL(file);
        element.download = (readUnitData.houseAdsAdUnitName + ".txt");
        document.body.appendChild(element);
        element.click();
    };

    const copyTag = (e) => {
        setpopoverdismiss(!popoverdismiss)
        navigator.clipboard.writeText(readUnitData.houseAdUnitTag)
    }

    const paginationChange = async (selectedPages) => {
        console.log(selectedPages)
        pageSize = selectedPages.pageSize
        pageNumber = selectedPages.pageNumber
        dispatch(onGetAdUnits({
            pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText, parentID: userInfo.parentID, isForSupplyPartner: userInfo.isForSupplyPartner, filter: statusSearch, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '',
            "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : ''
        }));
    }

    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
            <div className="page-content">
                <Helmet>
                    <title>Ad Units | Bidsxchange</title>
                </Helmet>
                <Container fluid={true}>
                    <div className=" d-sm-flex  justify-content-between">
                        <Breadcrumbs title="Custom Tracking" breadcrumbItem="Ad Units" />
                        {UserRolePrivileges.HouseAds && UserRolePrivileges.HouseAds.HouseAdsAdUnits && UserRolePrivileges.HouseAds.HouseAdsAdUnits.create &&
                            <div className=" text-sm-end">
                                <div className="btn-group">
                                    <Dropdown
                                        direction="left"
                                        isOpen={isMenu}
                                        toggle={toggleMenu}
                                    >
                                        <DropdownToggle
                                            tag="button"
                                            className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                                        >
                                            <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                                            {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                                                Filter Applied
                                            </span> : <span className="d-none font-16 d-sm-inline-block">
                                                Filter
                                            </span>
                                            }
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                                            <div className="dropdown-item-text">
                                                <div className="row">
                                                    <h5 className="mb-0">Filters</h5>
                                                </div>
                                            </div>

                                            <DropdownItem divider />
                                            <Card>
                                                <CardBody aria-hidden="true">
                                                    <Row >
                                                        <Col sm="12" className="mt-2">
                                                            <Label className="form-label">Status</Label>
                                                            <div className=" text-sm-end">
                                                                <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                                                    <option value=''> All</option>
                                                                    <option value='Active' > Active</option>
                                                                    <option value='Inactive'> InActive</option>
                                                                    <option value='Archived'> Archived</option>
                                                                </Input>
                                                            </div>
                                                        </Col>
                                                        <Col sm="12" className="date-range mt-2">
                                                            <Label className="form-label">Filter by Modified Dates</Label>
                                                            <div className="">
                                                                <DateRangePicker
                                                                    initialSettings={{
                                                                        opens: 'left',
                                                                        startDate: start.toDate(),
                                                                        endDate: end.toDate(),
                                                                    }}
                                                                    onCallback={handleEvent}
                                                                >
                                                                    <button className='datePicker w-100' type="button">
                                                                        <i className="bx bx-calendar font-size-18 " id="edittooltip" style={{ margin: '3% 3% 2%' }} ></i>{labelDate}
                                                                    </button>
                                                                </DateRangePicker>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>

                                            <DropdownItem divider />
                                            <Row>
                                                <Col sm="6">
                                                    <DropdownItem
                                                        className="text-primary text-center"
                                                        onClick={clearAllFilter}
                                                    >
                                                        Clear ALL
                                                    </DropdownItem>
                                                </Col>
                                                <Col sm="6">
                                                    <DropdownItem
                                                        className="text-primary text-center"
                                                        onClick={applyFilter}
                                                    >
                                                        Apply Filter
                                                    </DropdownItem>
                                                </Col>
                                            </Row>

                                        </DropdownMenu>
                                    </Dropdown>
                                </div> &nbsp;&nbsp;
                                <Button
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                    onClick={toggleRightCanvas}
                                >
                                    <i className="mdi mdi-plus-circle-outline me-1" />
                                    Add Ad Unit
                                </Button>
                            </div>
                        }
                    </div>
                    <ProductDataTableLI statusAccess={(UserRolePrivileges.HouseAds && UserRolePrivileges.HouseAds.HouseAdsAdUnits && UserRolePrivileges.HouseAds.HouseAdsAdUnits.status) ? true : false}
                        archiveAccess={(UserRolePrivileges.HouseAds && UserRolePrivileges.HouseAds.HouseAdsAdUnits && UserRolePrivileges.HouseAds.HouseAdsAdUnits.archive) ? true : false} loading={loading} data={adUnits} columns={supplyTagListColumns} statusChange={statusAdUnit} archiveLineItem={archiveAdUnit} rowSelection={selectRow} handleClose={handleClose} selectedIndex={selectedIndex} paginationSelection={paginationChange} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} />

                </Container>
            </div>
            {/* </div> */}
            <Offcanvas isOpen={isRight} direction='end' className="CanvasLarge" >
                <OffcanvasHeader toggle={toggleRightCanvas} >
                    {'Add Ad Unit'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <New_Supply_Tag closeCanvas={toggleDrawer(false, '')} selectedArchived={isArchived} selectedAdUnit={selectedData} selectedPublisher={(parsed.status === 'AdUnitCreateByPublisher' || parsed.status === 'AdUnitCreateByParent') ? parsed : ''} />
                </OffcanvasBody>
            </Offcanvas>

            {readUnitData &&
                <Modal
                    size="lg"
                    isOpen={jsTagModalState}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Ad Unit : {readUnitData.houseAdsAdUnitName}
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setJsTagModalState(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h5>JS Tag</h5>
                        <br />
                        <Input
                            ref={inputRef}
                            type="textarea"
                            id="textarea"
                            rows="8"
                            readOnly
                            name="jsTag" value={readUnitData.houseAdUnitTag}
                        />
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                setJsTagModalState(false);
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                        <Button
                            color="primary"
                            id="Popoverdismiss"
                            className="font-16 btn-block btn btn-primary"
                            onClick={copyTag}
                        >
                            <i className="bx bx-copy me-1" />
                            Copy Tag
                        </Button>
                        <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            onClick={downloadTxtFile}
                        >
                            <i className="bx bx-download me-1" />
                            Download Tag
                        </Button>
                    </div>
                </Modal>
            }

            <Link ref={closeRef} to={`/AdUnits?${''}`}  > </Link>

        </React.Fragment >
    )
}

Supply_Tag.propTypes = {}

export default Supply_Tag